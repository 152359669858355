import React from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Paper,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: '8px',
  },
}));

export default function HistoriaMedica({
  mTratamentoMedico,
  setMTratamentoMedico,
  mPqTratamentoMedico,
  setMPqTratamentoMedico,
  mUltimoTratamento,
  setMUltimoTratamento,
  mPqUltTratamento,
  setPqUltTratamento,
  mAlergia,
  setMAlergia,
  mAlergiaMedicamento,
  setMAlergiaMedicamento,
  mMedicamentoUsual,
  setMMedicamentoUsual,
  mNomeMedicamentoUsual,
  setMNomeMedicamentoUsual,
  mPqMedicamentoUsual,
  setMPqMedicamentoUsual,
  mAntibiotico,
  setMAntibiotico,
  mNomeAntibiotico,
  setMNomeAntibiotico,
  mPqAntibiotico,
  setMPqAntibiotico,
  mAntinflamatorio,
  setMAntinflamatorio,
  mNomeAntinflamatorio,
  setMNomeAntinflamatorio,
  mAnalgesico,
  setMAnalgesico,
  mNomeAnalgesico,
  setMNomeAnalgesico,
  mDoencaAlteracao,
  setMDoencaAlteracao,
  mNomeDoencaAlteracao,
  setMNomeDoencaAlteracao,
  mHospitalizadoCirurgia,
  setMHospitalizadoCirurgia,
  mQuandoHospitalizado,
  setMQuandoHospitalizado,
  mPqHospitalizado,
  setMPqHospitalizado,
  mRecTransfSangue,
  setMRecTransfSangue,
  mQuandoTransfSangue,
  setMQuandoTransfSangue,
  mPqTransfSangue,
  setMPqTransfSangue,
  mCardiopatia,
  setMCardiopatia,
  mBronquite,
  setMBronquite,
  mAnemia,
  setMAnemia,
  mDiabetes,
  setMDiabetes,
  mRinite,
  setMRinite,
  mSinusite,
  setMSinusite,
  mHepatite,
  setMHepatite,
  mColesterol,
  setMColesterol,
  mFebreReumatica,
  setMFebreReumatica,
  mAsma,
  setMAsma,
  mCansadoFrequencia,
  setMCansadoFrequencia,
  mAlteracaoRinsBexiga,
  setMAlteracaoRinsBexiga,
  mCorteSangraMuito,
  setMCorteSangraMuito,
  mPressao,
  setMPressao,
  mGravida,
  setMGravida,
  mGravidaQtdMeses,
  setMGravidaQtdMeses,
  mCicatrizacao,
  setMCicatrizacao,
  mHemorragia,
  setMHemorragia,
  mEpilepsia,
  setMEpilepsia,
  mSistemica,
  setMSistemica,
  mCancer,
  setMCancer,
  mSifilis,
  setMSifilis,
  mHiv,
  setMHiv,
  mBebidaAlcoolica,
  setMBebidaAlcoolica,
  mDrogas,
  setMDrogas,
}) {
  const classes = useStyles();

  return (
    <>
      {/* pergunta 1 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              checked={mTratamentoMedico}
              onChange={() => setMTratamentoMedico(!mTratamentoMedico)}
              label="Faz tratamento médico?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Se sim, por quê?"
              fullWidth
              value={mPqTratamentoMedico}
              onChange={e => setMPqTratamentoMedico(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 2 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Quando fez seu último tratamento médico?"
              fullWidth
              value={mUltimoTratamento}
              onChange={e => setMUltimoTratamento(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Por quê?"
              fullWidth
              value={mPqUltTratamento}
              onChange={e => setPqUltTratamento(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 3 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              select
              size="small"
              label="Tem alergia ou sensibilidade a algum medicamento?"
              fullWidth
              value={mAlergia}
              onChange={e => setMAlergia(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="1">Sim</MenuItem>
              <MenuItem value="0">Não</MenuItem>
              <MenuItem value="2">Não sei</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Se sim, qual o nome do medicamento?"
              fullWidth
              value={mAlergiaMedicamento}
              onChange={e => setMAlergiaMedicamento(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 4 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={mMedicamentoUsual}
              control={<Checkbox color="primary" />}
              onChange={() => setMMedicamentoUsual(!mMedicamentoUsual)}
              label="Atualmente está tomando algum medicamento?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Se sim, qual o nome da medicação?"
              fullWidth
              value={mNomeMedicamentoUsual}
              onChange={e => setMNomeMedicamentoUsual(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Por quê?"
              fullWidth
              value={mPqMedicamentoUsual}
              onChange={e => setMPqMedicamentoUsual(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 5 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={mAntibiotico}
              control={<Checkbox color="primary" />}
              onChange={() => setMAntibiotico(!mAntibiotico)}
              label="Já tomou antibiótico?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Se sim, qual?"
              fullWidth
              value={mNomeAntibiotico}
              onChange={e => setMNomeAntibiotico(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Por quê?"
              fullWidth
              value={mPqAntibiotico}
              onChange={e => setMPqAntibiotico(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 6 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={mAntinflamatorio}
              control={<Checkbox color="primary" />}
              onChange={() => setMAntinflamatorio(!mAntinflamatorio)}
              label="Já tomou anti-inflamatório?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Se sim, qual?"
              fullWidth
              value={mNomeAntinflamatorio}
              onChange={e => setMNomeAntinflamatorio(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 7 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={mAnalgesico}
              control={<Checkbox color="primary" />}
              onChange={() => setMAnalgesico(!mAnalgesico)}
              label="Já tomou analgésico?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Se sim, qual?"
              fullWidth
              value={mNomeAnalgesico}
              onChange={e => setMNomeAnalgesico(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 8 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={mDoencaAlteracao}
              control={<Checkbox color="primary" />}
              onChange={() => setMDoencaAlteracao(!mDoencaAlteracao)}
              label="Possui alguma doença ou alteração clínica?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Se sim, qual?"
              fullWidth
              value={mNomeDoencaAlteracao}
              onChange={e => setMNomeDoencaAlteracao(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 9 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={mHospitalizadoCirurgia}
              control={<Checkbox color="primary" />}
              onChange={() =>
                setMHospitalizadoCirurgia(!mHospitalizadoCirurgia)
              }
              label="Foi hospitalizado ou submeteu-se à(s) cirurgia(s)?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Se sim, quando?"
              fullWidth
              value={mQuandoHospitalizado}
              onChange={e => setMQuandoHospitalizado(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Por quê?"
              fullWidth
              value={mPqHospitalizado}
              onChange={e => setMPqHospitalizado(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 10 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={mRecTransfSangue}
              control={<Checkbox color="primary" />}
              onChange={() => setMRecTransfSangue(!mRecTransfSangue)}
              label="Recebeu transfusão de sangue?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Se sim, quando?"
              fullWidth
              value={mQuandoTransfSangue}
              onChange={e => setMQuandoTransfSangue(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Por quê?"
              fullWidth
              value={mPqTransfSangue}
              onChange={e => setMPqTransfSangue(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 11 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControlLabel
              checked={mGravida}
              control={<Checkbox color="primary" />}
              onChange={() => setMGravida(!mGravida)}
              label="Está Grávida ?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Se sim, de quantos meses?"
              fullWidth
              value={mGravidaQtdMeses}
              onChange={e => setMGravidaQtdMeses(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={mBebidaAlcoolica}
              control={<Checkbox color="primary" />}
              onChange={() => setMBebidaAlcoolica(!mBebidaAlcoolica)}
              label="Consome bebida alcoólica?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={mDrogas}
              control={<Checkbox color="primary" />}
              onChange={() => setMDrogas(!mDrogas)}
              label="Usa drogas ?"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 12 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControlLabel
              checked={mHemorragia}
              control={<Checkbox color="primary" />}
              onChange={() => setMHemorragia(!mHemorragia)}
              label="Já teve hemorragia?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Cicatrização?"
              fullWidth
              value={mCicatrizacao}
              onChange={e => setMCicatrizacao(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} className={classes.paper}>
        <b className={classes.titulos}>Tem ou teve algumas dessas doenças?</b>
        <Grid container spacing={1}>
          <FormControlLabel
            checked={mCardiopatia}
            control={<Checkbox color="primary" />}
            onChange={() => setMCardiopatia(!mCardiopatia)}
            label="Cardiopatia"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mBronquite}
            control={<Checkbox color="primary" />}
            onChange={() => setMBronquite(!mBronquite)}
            label="Bronquite"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mAnemia}
            control={<Checkbox color="primary" />}
            onChange={() => setMAnemia(!mAnemia)}
            label="Anemia"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mDiabetes}
            control={<Checkbox color="primary" />}
            onChange={() => setMDiabetes(!mDiabetes)}
            label="Diabetes"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mRinite}
            control={<Checkbox color="primary" />}
            onChange={() => setMRinite(!mRinite)}
            label="Rinite"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mSinusite}
            control={<Checkbox color="primary" />}
            onChange={() => setMSinusite(!mSinusite)}
            label="Sinusite"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mHepatite}
            control={<Checkbox color="primary" />}
            onChange={() => setMHepatite(!mHepatite)}
            label="Hepatite"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mColesterol}
            control={<Checkbox color="primary" />}
            onChange={() => setMColesterol(!mColesterol)}
            label="Colesterol"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mFebreReumatica}
            control={<Checkbox color="primary" />}
            onChange={() => setMFebreReumatica(!mFebreReumatica)}
            label="Febre reumática"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mAsma}
            control={<Checkbox color="primary" />}
            onChange={() => setMAsma(!mAsma)}
            label="Asma"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mEpilepsia}
            control={<Checkbox color="primary" />}
            onChange={() => setMEpilepsia(!mEpilepsia)}
            label="Epilepsia"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mSistemica}
            control={<Checkbox color="primary" />}
            onChange={() => setMSistemica(!mSistemica)}
            label="Sistêmica"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mCancer}
            control={<Checkbox color="primary" />}
            onChange={() => setMCancer(!mCancer)}
            label="Cancêr"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mSifilis}
            control={<Checkbox color="primary" />}
            onChange={() => setMSifilis(!mSifilis)}
            label="Sífilis"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mHiv}
            control={<Checkbox color="primary" />}
            onChange={() => setMHiv(!mHiv)}
            label="HIV"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mCansadoFrequencia}
            control={<Checkbox color="primary" />}
            onChange={() => setMCansadoFrequencia(!mCansadoFrequencia)}
            label="Sente-se cansado com frequência?"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mAlteracaoRinsBexiga}
            control={<Checkbox color="primary" />}
            onChange={() => setMAlteracaoRinsBexiga(!mAlteracaoRinsBexiga)}
            label="Alterações nos rins ou na bexiga?"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mCorteSangraMuito}
            control={<Checkbox color="primary" />}
            onChange={() => setMCorteSangraMuito(!mCorteSangraMuito)}
            label="Quando se corta, sangra muito?"
            labelPlacement="end"
          />
          <FormControlLabel
            checked={mPressao}
            control={<Checkbox color="primary" />}
            onChange={() => setMPressao(!mPressao)}
            label="Alteração da pressão?"
            labelPlacement="end"
          />
        </Grid>
      </Paper>
    </>
  );
}
