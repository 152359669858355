import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { formatReal } from '../../../utils/funcoes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  total: {
    fontSize: '20px',
    padding: 0,
  },
}));

export default function TabelaProcedimentos({
  idOrcamento,
  listaProcedimentos,
  setProcedimentos,
  planoTratamento,
  setPlanoTratamento,
  listaProdutos,
  abrirAviso,
  tipoDesc,
  setTipoDesc,
  total,
  totalFinal,
  setTotalFinal,
  desconto,
  setDesconto,
  ratear,
  setRatear,
  empresa,
}) {
  const [areaTratada, setAreaTratada] = useState(null);
  const [desabilitarButton, setDesabilitarButton] = useState(false);
  const [procedimento, setProcedimento] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [quantidade, setQuantidade] = useState(0);
  const [descPlano, setDescPlano] = useState(0);
  const [valor, setValor] = useState(0);
  const [idPlanoEstetico, setIdPlanoEstetico] = useState(null);
  const [modalEditar, setModalEditar] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  useEffect(() => {
    confirmarDesconto();
  }, [desconto, tipoDesc]);

  useEffect(() => {
    let valor = 0;
    listaProcedimentos.map(proc => {
      if (proc.id === procedimento) {
        valor = parseFloat(proc.valor);
      }
    });
    setValor(valor);
  }, [procedimento]);

  const descricaoProcedimento = id => {
    let descricao = '';
    listaProcedimentos.map(procedimento => {
      if (procedimento.id === id) {
        descricao = procedimento.descricao;
      }
    });
    return descricao;
  };

  const descricaoProduto = id => {
    let descricao = '';
    listaProdutos.map(produto => {
      if (produto.id === id) {
        descricao = produto.nome;
      }
    });
    return descricao;
  };

  const cancelarProcedimento = id => {
    if (idOrcamento !== null) {
      api
        .post('PlanoEstetico/deletar.php', {
          pdo: getClinica(),
          id: id,
        })
        .then(() => {
          abrirAviso('success', 'procedimento removido.');
          setPlanoTratamento(planoTratamento =>
            planoTratamento.filter(e => e.id !== id),
          );
        });
    } else {
      planoTratamento.splice(id, 1);
      setDesconto(0);
      setPlanoTratamento([...planoTratamento]);
      abrirAviso('success', 'procedimento removido.');
    }
  };

  const confirmarDesconto = () => {
    let porcentagem = 0;
    let valor = 0;
    let totalDesc = 0;
    if (tipoDesc === '0') {
      if (desconto > 0) {
        porcentagem = parseFloat(desconto);
        let valorDesc = parseFloat(total * (porcentagem / 100));
        totalDesc = total - valorDesc;
        setTotalFinal(totalDesc);
        setRatear(valorDesc);
      } else {
        setTotalFinal(total);
        setRatear(0);
      }
    } else if (tipoDesc === '1') {
      valor = parseFloat(desconto);
      totalDesc = total - valor;
      setTotalFinal(totalDesc);
      setRatear(valor);
    } else {
      abrirAviso('warning', 'Informe o tipo do desconto.');
    }
  };

  const alterarProcedimento = (obj, index) => {
    setModalEditar(true);
    if (idOrcamento !== null) {
      setIdPlanoEstetico(obj.id);
      setIdProduto(obj.produto);
      setAreaTratada(obj.areaTratada);
      setProcedimento(obj.tipo);
      setQuantidade(obj.quantidade);
      setValor(obj.valor);
      setDescPlano(obj.desconto);
    } else {
      setIdPlanoEstetico(index);
      setValor(obj.valor);
      setIdProduto(obj.produto);
      setProcedimento(obj.idProcedimento);
      setQuantidade(obj.quantidade);
      setAreaTratada(obj.area);
    }
  };

  const confirAltProcedimento = () => {
    setDesabilitarButton(true);
    if (idOrcamento !== null) {
      if (
        procedimento !== null &&
        areaTratada !== null &&
        valor > 0 &&
        idProduto !== null
      ) {
        api
          .post('PlanoEstetico/updateData.php', {
            pdo: getClinica(),
            areaTratada: areaTratada,
            procedimento: procedimento,
            produto: idProduto,
            quantidade: quantidade,
            desconto: descPlano,
            valor: valor,
            idPlanoEstetico: idPlanoEstetico,
          })
          .then(res => {
            abrirAviso('success', 'procedimento alterado.');
            setPlanoTratamento(state =>
              state.map(e => {
                if (e.id !== res.data.id) {
                  return e;
                }
                e = res.data;
                return e;
              }),
            );
          });
      } else {
        abrirAviso('warning', 'Preencha os campos obrigatórios.');
      }
    } else {
      if (valor > 0) {
        planoTratamento[idPlanoEstetico].valor = valor;
        planoTratamento[idPlanoEstetico].produto = idProduto;
        planoTratamento[idPlanoEstetico].idProcedimento = procedimento;
        planoTratamento[idPlanoEstetico].quantidade = quantidade;
        planoTratamento[idPlanoEstetico].area = areaTratada;
        setPlanoTratamento([...planoTratamento]);
        abrirAviso('success', 'valor alterado.');
      } else {
        abrirAviso('warning', 'Preencha o campo obrigatório.');
      }
    }
    setDesabilitarButton(false);
    setModalEditar(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Área Tratada</b>
              </TableCell>
              <TableCell align="center">
                <b>Produto</b>
              </TableCell>
              {empresa?.insumosTerceiros === '1' && (
                <TableCell align="center">
                  <b>R$</b>
                </TableCell>
              )}
              <TableCell align="center">
                <b>Qtd.</b>
              </TableCell>
              <TableCell align="center">
                <b>Procedimento</b>
              </TableCell>
              <TableCell align="center">
                <b>Valor</b>
              </TableCell>
              <TableCell align="center">
                <b>Opção</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planoTratamento.map((pl, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {idOrcamento !== null ? pl.areaTratada : pl.area}
                </TableCell>
                <TableCell align="center">
                  {idOrcamento !== null
                    ? pl.nomeProduto
                    : descricaoProduto(pl.produto)}
                </TableCell>
                {empresa?.insumosTerceiros === '1' && (
                  <TableCell align="center">
                    {formatReal(pl.valorProduto)}
                  </TableCell>
                )}
                <TableCell align="center">{pl.quantidade}</TableCell>
                {idOrcamento !== null ? (
                  <TableCell align="center">
                    {pl.descricaoProcedimento}
                  </TableCell>
                ) : (
                  <TableCell align="center">
                    {descricaoProcedimento(pl.idProcedimento)}
                  </TableCell>
                )}
                <TableCell align="center">{formatReal(pl.valor)}</TableCell>
                <TableCell align="center">
                  <Button
                    className={classes.button}
                    color="primary"
                    title="Editar"
                    onClick={() => alterarProcedimento(pl, index)}
                    variant="contained"
                  >
                    <EditIcon />
                  </Button>
                  {idOrcamento !== null ? (
                    <Button
                      color="secondary"
                      onClick={() => cancelarProcedimento(pl.id)}
                      title="Excluir"
                      variant="contained"
                    >
                      <DeleteIcon />
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      onClick={() => cancelarProcedimento(index)}
                      title="Excluir"
                      variant="contained"
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5}>
                <b className={classes.total}>SubTotal</b>
              </TableCell>
              <TableCell>
                <b className={classes.total}>{formatReal(total)}</b>
              </TableCell>
            </TableRow>
            {idOrcamento === null && (
              <TableRow>
                <TableCell>
                  <b className={classes.total}>Desconto</b>
                </TableCell>
                <TableCell align="right" colSpan={3}>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={tipoDesc}
                    onChange={e => setTipoDesc(e.target.value)}
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label="Porcentagem"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Valor"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    value={desconto}
                    onChange={e => {
                      e.target.value >= 0
                        ? setDesconto(e.target.value)
                        : setDesconto(0);
                    }}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <b className={classes.total}>{formatReal(ratear)}</b>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={5}>
                <b className={classes.total}>Total</b>
              </TableCell>
              <TableCell>
                <b className={classes.total}>{formatReal(totalFinal)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        fullScreen={fullScreen}
        open={modalEditar}
        onClose={() => setModalEditar(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Plano de tratamento'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                select
                label="Produto"
                value={idProduto}
                onChange={e => setIdProduto(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione
                </MenuItem>
                {listaProdutos.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                label="Quantidade"
                value={quantidade}
                variant="outlined"
                onChange={e => setQuantidade(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Área tratada"
                value={areaTratada}
                onChange={e => setAreaTratada(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Procedimento"
                value={procedimento}
                onChange={e => setProcedimento(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione
                </MenuItem>
                {listaProcedimentos.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Valor"
                value={valor}
                onChange={e => setValor(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirAltProcedimento}
            variant="contained"
            color="primary"
            disabled={desabilitarButton}
          >
            Alterar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModalEditar(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
