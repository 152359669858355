import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getToken, getClinica } from '../../services/auth';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Notificacao from '../../utils/notificacao';

export default function Configuracoes() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [ConfirmSenha, setConfirmSenha] = useState('');
  const [modal, setModal] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    api
      .get('Usuario/find.php?id=' + getToken() + '&pdo=' + getClinica())
      .then(res => {
        setEmail(res.data.login);
        setSenha(res.data.senha);
      });
  };

  const alterarSenha = async () => {
    if (novaSenha === ConfirmSenha) {
      api
        .post('Usuario/updateSenha.php', {
          pdo: getClinica(),
          senha: novaSenha,
          senhaAntiga: senhaAntiga,
          id: getToken(),
        })
        .then(res => {
          if (res.data) {
            abrirAviso('success', 'Senha alterada.');
            cancelarAlteracao();
          } else {
            abrirAviso('error', 'Senha atual incorreta.');
          }
        })
        .catch(error => console.log(error));
    } else {
      abrirAviso('warning', 'As senhas não coincidem.');
    }
  };

  const cancelarAlteracao = () => {
    setModal(false);
    setSenhaAntiga('');
    setNovaSenha('');
    setConfirmSenha('');
  };

  const useStyles = makeStyles(theme => ({
    textField: {
      marginTop: theme.spacing(1),
    },
    grid: {
      marginTop: '80px',
    },
  }));

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Menu titulo="Configurações" />
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={6}>
          <TextField
            label="Endereço de e-mail"
            fullWidth
            value={email}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Senha"
            type="password"
            fullWidth
            value={senha}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModal(true)}
          >
            Alterar
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Alteração de Senha'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {' '}
            Informe a senha antiga para realizar a alteração.
          </DialogContentText>
          <Grid item xs={12}>
            <TextField
              label="Senha antiga"
              type="password"
              className={classes.textField}
              fullWidth
              value={senhaAntiga}
              onChange={e => setSenhaAntiga(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nova senha"
              type="password"
              fullWidth
              margin="normal"
              value={novaSenha}
              onChange={e => setNovaSenha(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirme a nova senha"
              type="password"
              fullWidth
              margin="normal"
              value={ConfirmSenha}
              onChange={e => setConfirmSenha(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={alterarSenha}>
            Alterar
          </Button>
          <Button
            onClick={cancelarAlteracao}
            variant="contained"
            color="default"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
