import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatReal } from '../../utils/funcoes';
import Menu from '../Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import '../Orcamentos/Odontologico/style.css';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import HistoryIcon from '@material-ui/icons/History';
import Odontograma from '../../utils/odontograma';
import HistoricoOdontologico from './historico';
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CachedIcon from '@material-ui/icons/Cached';
import Zoom from '@material-ui/core/Zoom';
import Notificacao from '../../utils/notificacao';
import { useConfig } from '../../hooks/config';
import InserirObservacaoOdontologico from './inserirObservacao';
import CancelIcon from '@material-ui/icons/Cancel';
import CancelarTratamento from '../../utils/cancelarTratamento';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  container: {
    maxHeight: 500,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  subtotal: {
    margin: '5px 0px',
  },
  desconto: {
    margin: '5px 0px',
  },
  total: {
    margin: '5px 0px',
  },
  aguardando: {
    color: '#FFC107',
  },
  finalizado: {
    color: '#4caf50',
  },
  buttonStatus: {
    margin: theme.spacing(1),
  },
  inputObs: {
    width: '400px',
  },
}));

export default function TratamentoOdontologico() {
  const dados = JSON.parse(atob(sessionStorage.getItem('dados')));
  const Paciente = dados.paciente;
  const Tratamento = dados.tratamento;
  const [modalObs, setModalObs] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [procedimentos, setProcedimentos] = useState([]);
  const { configuracoes } = useConfig();
  const [modalHistorico, setModalHistorico] = useState(false);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [idPlanoTratamento, setIdPlanoTratamento] = useState(null);
  const classes = useStyles();

  const atualizar = async () => {
    if (dados) {
      await api
        .post(
          'PlanoTratamento/list.php?id=' +
            Tratamento.id +
            '&pdo=' +
            getClinica(),
        )
        .then(res => setProcedimentos(res.data));
      await api.post('Tratamento/updateProgress.php', {
        pdo: getClinica(),
        id: Tratamento.id,
      });
      setLoading(false);
    }
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const alterarProgresso = (id, status) => {
    setIdPlanoTratamento(id);
    setStatus(status);
    setModalObs(true);
  };

  const atualizarProgress = () => {};

  const soma = tipo => {
    let total = 0,
      desconto = 0;
    procedimentos.map(pro => {
      total += parseFloat(pro.valor);
      desconto += parseFloat(pro.desconto);
    });
    if (tipo === 'total') {
      return formatReal(total - desconto);
    } else if (tipo === 'desconto') {
      return formatReal(desconto);
    } else {
      return formatReal(total);
    }
  };

  const statusProcedimento = e => {
    if (e === '0') {
      return (
        <Tooltip title="Aguardando" TransitionComponent={Zoom}>
          <PlayCircleFilledIcon className={classes.aguardando} />
        </Tooltip>
      );
    } else if (e === '1') {
      return (
        <Tooltip title="Em andamento" TransitionComponent={Zoom}>
          <CachedIcon color="primary" />
        </Tooltip>
      );
    } else if (e === '2') {
      return (
        <Tooltip title="Finalizado" TransitionComponent={Zoom}>
          <CheckCircleIcon className={classes.finalizado} />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <>
      <Menu
        titulo={`Pacientes ► ${Paciente.nome} ► Tratamentos Odontológicos ► Nº ${Tratamento.id}`}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => {
            window.history.back();
            sessionStorage.removeItem('dados');
          }}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setModalHistorico(true)}
          startIcon={<HistoryIcon />}
        >
          Histórico
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => setModalCancel(true)}
          startIcon={<CancelIcon />}
        >
          Cancelar
        </Button>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <>
          <Odontograma procedimentos={procedimentos} />
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Id</b>
                    </TableCell>
                    <TableCell>
                      <b>Nº dente</b>
                    </TableCell>
                    <TableCell>
                      <b>Face</b>
                    </TableCell>
                    <TableCell>
                      <b>Estado atual</b>
                    </TableCell>
                    <TableCell>
                      <b>Procedimento</b>
                    </TableCell>
                    <TableCell>
                      <b>Valor</b>
                    </TableCell>
                    <TableCell>
                      <b>Desconto</b>
                    </TableCell>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                    <TableCell>
                      <b>Opções</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {procedimentos.map(procedimento => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={procedimento.id}
                      >
                        <TableCell>{procedimento.id}</TableCell>
                        <TableCell>{procedimento.numDente}</TableCell>
                        <TableCell>{procedimento.face}</TableCell>
                        <TableCell>{procedimento.estadoAtual}</TableCell>
                        <TableCell>
                          {procedimento.descricaoProcedimento}
                        </TableCell>
                        <TableCell>{formatReal(procedimento.valor)}</TableCell>
                        <TableCell>
                          {formatReal(procedimento.desconto)}
                        </TableCell>
                        <TableCell>
                          {statusProcedimento(procedimento.status)}
                        </TableCell>
                        <TableCell>
                          <div>
                            <Tooltip
                              title="Inicializar pricedimento"
                              TransitionComponent={Zoom}
                            >
                              <Button
                                variant="contained"
                                className={classes.buttonStatus}
                                onClick={() =>
                                  alterarProgresso(procedimento.id, 1)
                                }
                                color="primary"
                                size="small"
                              >
                                <CachedIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              title="Finalizar procedimento"
                              TransitionComponent={Zoom}
                            >
                              <Button
                                variant="contained"
                                className={classes.buttonStatus}
                                onClick={() =>
                                  alterarProgresso(procedimento.id, 2)
                                }
                                color="primary"
                                size="small"
                              >
                                <CheckCircleIcon />
                              </Button>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell align="right" colSpan={2}>
                      <h2 className={classes.subtotal}>
                        SubTotal: {soma('subtotal')}
                      </h2>
                    </TableCell>
                    <TableCell align="right" colSpan={2}>
                      <h2 className={classes.desconto}>
                        Desconto: {soma('desconto')}
                      </h2>
                    </TableCell>
                    <TableCell align="right" colSpan={6}>
                      <h1 className={classes.total}>Total: {soma('total')}</h1>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
      {modalHistorico && (
        <HistoricoOdontologico
          Tratamento={Tratamento}
          setModal={setModalHistorico}
          modal={modalHistorico}
          procedimentos={procedimentos}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />

      {modalObs && (
        <InserirObservacaoOdontologico
          modalObs={modalObs}
          setModalObs={setModalObs}
          configuracoes={configuracoes}
          getClinica={getClinica}
          Tratamento={Tratamento}
          idPlanoTratamento={idPlanoTratamento}
          status={status}
          classes={classes}
          atualizar={atualizar}
          abrirAviso={abrirAviso}
        />
      )}

      {modalCancel && (
        <CancelarTratamento
          tipo={'Tratamento'}
          modalCancel={modalCancel}
          setModalCancel={setModalCancel}
          configuracoes={configuracoes}
          getClinica={getClinica}
          Tratamento={Tratamento}
          abrirAviso={abrirAviso}
        />
      )}
    </>
  );
}
