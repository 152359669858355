import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatReal } from '../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Notificacao from '../../utils/notificacao';
import { useConfig } from '../../hooks/config';
import CadastrarEditarTaxa from './CadastrarEditar';
import { listaFormasPagamento } from '../../utils/funcoes';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
}));

export default function Taxas() {
  const { configuracoes } = useConfig();
  const [loading, setLoading] = useState(true);
  const [taxa, setTaxa] = useState(null);
  const [taxas, setTaxas] = useState([]);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [buscar, setBuscar] = useState('');
  const [status, setStatus] = useState('AND status=1');
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const atualizar = async () => {
    let consulta = ` ${status} ORDER BY descricao ASC`;
    listarTaxas(consulta);
  };

  const listarTaxas = consulta => {
    setTaxas([]);
    setPage(0);
    api
      .post('Taxa/search.php', {
        pdo: getClinica(),
        consulta: consulta,
      })
      .then(res => {
        if (res.data.length > 0) {
          setTaxas(res.data);
        }
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filtrar = () => {
    setLoading(true);
    let filtroBusca = '';
    if (status !== '3') {
      filtroBusca += ` ${status}`;
    }

    filtroBusca += ' ORDER BY descricao';
    console.log(filtroBusca);
    listarTaxas(filtroBusca);
    setLoading(false);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const filtro = taxas?.filter(t => {
    return (
      t.descricao
        .toLowerCase()
        .toUpperCase()
        .indexOf(buscar.toLowerCase().toUpperCase()) !== -1 ||
      t.id
        .toLowerCase()
        .toUpperCase()
        .indexOf(buscar.toLowerCase().toUpperCase()) !== -1
    );
  });

  return (
    <div>
      <Menu titulo="Taxas" />
      <Box
        display="flex"
        className={classes.opcoes}
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setTaxa(null);
              setModal(true);
            }}
            startIcon={<AddCircleIcon />}
          >
            Novo
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
          >
            Atualizar
          </Button>
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="3">Todos</MenuItem>
            <MenuItem value="AND status=1">Ativo</MenuItem>
            <MenuItem value="AND status=0">Inativo</MenuItem>
          </TextField>
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Forma Pagamento</b>
                  </TableCell>
                  <TableCell>
                    <b>Descrição</b>
                  </TableCell>
                  <TableCell>
                    <b>Taxa</b>
                  </TableCell>
                  <TableCell>
                    <b>Parcela</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opção</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(taxa => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={taxa.id}
                      >
                        <TableCell>{taxa.id}</TableCell>
                        <TableCell>
                          {
                            listaFormasPagamento.find(
                              x => x.value === taxa.formaPagId,
                            )?.label
                          }
                        </TableCell>
                        <TableCell>{taxa.descricao}</TableCell>
                        <TableCell>
                          {+taxa.tipo === 0
                            ? `${taxa.valor}%`
                            : formatReal(taxa.valor)}
                        </TableCell>
                        <TableCell>{`Parcelado ${taxa.parcelaInicio}-${taxa.parcelaFim}x`}</TableCell>
                        <TableCell>
                          {taxa.status === '1' ? (
                            <Avatar className={classes.ativo} title="Ativo">
                              <CheckCircleIcon />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.inativo} title="Inativo">
                              <CancelIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            startIcon={<OpenInNewIcon />}
                            onClick={() => {
                              setTaxa(taxa);
                              setModal(true);
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Abrir
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={taxas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
      {modal && (
        <CadastrarEditarTaxa
          modal={modal}
          fecharModal={() => setModal(false)}
          configuracoes={configuracoes}
          fullScreen={fullScreen}
          classes={classes}
          setTaxas={setTaxas}
          taxa={taxa}
          abrirAviso={abrirAviso}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
