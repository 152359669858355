import React from 'react';
import {
  Grid,
  TextField,
  Paper,
  makeStyles,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: '8px',
  },
  titulo: {
    verticalAlign: 'sub',
  },
}));
export default function HabitosAlimentares({
  hAMamadeiraAcucar,
  setHAMamadeiraAcucar,
  hAMamadeiraSemAcucar,
  setHAMamadeiraSemAcucar,
  hAMamadeiraDiurna,
  setHAMamadeiraDiurna,
  hAMamadeiraNoturna,
  setHAMamadeiraNoturna,
  hAAlimentacao,
  setHAAlimentacao,
  hANumRefeicaoDia,
  setHANumRefeicaoDia,
  hACostumaBeliscar,
  setHACostumaBeliscar,
  hAFormAcucarIngerido,
  setHAFormAcucarIngerido,
}) {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            1. Mamadeira
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              checked={hAMamadeiraAcucar}
              control={<Checkbox color="primary" />}
              onChange={() => setHAMamadeiraAcucar(!hAMamadeiraAcucar)}
              label="Com açucar"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hAMamadeiraSemAcucar}
              control={<Checkbox color="primary" />}
              onChange={() => setHAMamadeiraSemAcucar(!hAMamadeiraSemAcucar)}
              label="Sem açucar"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              checked={hAMamadeiraDiurna}
              control={<Checkbox color="primary" />}
              onChange={() => setHAMamadeiraDiurna(!hAMamadeiraDiurna)}
              label="Diurna"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hAMamadeiraNoturna}
              control={<Checkbox color="primary" />}
              onChange={() => setHAMamadeiraNoturna(!hAMamadeiraNoturna)}
              label="Noturna"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              select
              size="small"
              label="2. Alimentação predominantemente ..."
              fullWidth
              value={hAAlimentacao}
              onChange={e => setHAAlimentacao(e.target.value)}
              variant="outlined"
            >
              <MenuItem selected>Selecione</MenuItem>
              <MenuItem value="1">Sólida</MenuItem>
              <MenuItem value="2">Pastosa</MenuItem>
              <MenuItem value="3">Líquida</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              type="number"
              label="3. Número de refeições diárias"
              fullWidth
              value={hANumRefeicaoDia}
              onChange={e => setHANumRefeicaoDia(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hACostumaBeliscar}
              control={<Checkbox color="primary" />}
              onChange={() => setHACostumaBeliscar(!hACostumaBeliscar)}
              label="4. Costuma beliscar?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="5. Forma principal de açúcar ingerido"
              fullWidth
              value={hAFormAcucarIngerido}
              onChange={e => setHAFormAcucarIngerido(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
