import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { usePermissoes } from '../../hooks/permissoes';
import { useEmpresa } from '../../hooks/empresa';
import MenuOpcoes from '../Pacientes/menuOpcoes';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SaveIcon from '@material-ui/icons/Save';
import Queixa from './queixa';
import HistoriaMedica from './historiaMedica';
import HistoriaOdontologica from './historiaOdontologica';
import HigieneOral from './higieneOral';
import HabitosDeleterios from './habitosDeleterios';
import HabitosAlimentares from './habitosAlimentares';
import PerfilPsicologico from './perfilPsicologico';
import Notificacao from '../../utils/notificacao';
import { FaTooth } from 'react-icons/fa';
import ObservacoesFicha from './observacoes';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  titulos: {
    margin: '8px 0px',
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  console.log(index);
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function Prontuario() {
  const { empresa } = useEmpresa();
  const { id } = useParams();
  const { permissoes } = usePermissoes();
  const Paciente = JSON.parse(atob(id));
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [idFicha, setIdFicha] = useState(null);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [loading, setLoading] = useState(true);
  //queixa
  const [queixa, setQueixa] = useState(null);
  //historia medica
  const [mTratamentoMedico, setMTratamentoMedico] = useState(false);
  const [mPqTratamentoMedico, setMPqTratamentoMedico] = useState(null);
  const [mUltimoTratamento, setMUltimoTratamento] = useState(null);
  const [mPqUltTratamento, setPqUltTratamento] = useState(null);
  const [mAlergia, setMAlergia] = useState(false);
  const [mAlergiaMedicamento, setMAlergiaMedicamento] = useState(null);
  const [mMedicamentoUsual, setMMedicamentoUsual] = useState(false);
  const [mNomeMedicamentoUsual, setMNomeMedicamentoUsual] = useState(null);
  const [mPqMedicamentoUsual, setMPqMedicamentoUsual] = useState(null);
  const [mAntibiotico, setMAntibiotico] = useState(false);
  const [mNomeAntibiotico, setMNomeAntibiotico] = useState(null);
  const [mPqAntibiotico, setMPqAntibiotico] = useState(null);
  const [mAntinflamatorio, setMAntinflamatorio] = useState(false);
  const [mNomeAntinflamatorio, setMNomeAntinflamatorio] = useState(null);
  const [mAnalgesico, setMAnalgesico] = useState(false);
  const [mNomeAnalgesico, setMNomeAnalgesico] = useState(null);
  const [mDoencaAlteracao, setMDoencaAlteracao] = useState(false);
  const [mNomeDoencaAlteracao, setMNomeDoencaAlteracao] = useState(null);
  const [mHospitalizadoCirurgia, setMHospitalizadoCirurgia] = useState(false);
  const [mQuandoHospitalizado, setMQuandoHospitalizado] = useState(null);
  const [mPqHospitalizado, setMPqHospitalizado] = useState(null);
  const [mRecTransfSangue, setMRecTransfSangue] = useState(false);
  const [mQuandoTransfSangue, setMQuandoTransfSangue] = useState(null);
  const [mPqTransfSangue, setMPqTransfSangue] = useState(null);
  const [mCardiopatia, setMCardiopatia] = useState(false);
  const [mBronquite, setMBronquite] = useState(false);
  const [mAnemia, setMAnemia] = useState(false);
  const [mDiabetes, setMDiabetes] = useState(false);
  const [mRinite, setMRinite] = useState(false);
  const [mSinusite, setMSinusite] = useState(false);
  const [mHepatite, setMHepatite] = useState(false);
  const [mColesterol, setMColesterol] = useState(false);
  const [mFebreReumatica, setMFebreReumatica] = useState(false);
  const [mAsma, setMAsma] = useState(false);
  const [mCansadoFrequencia, setMCansadoFrequencia] = useState(false);
  const [mAlteracaoRinsBexiga, setMAlteracaoRinsBexiga] = useState(false);
  const [mCorteSangraMuito, setMCorteSangraMuito] = useState(false);
  const [mPressao, setMPressao] = useState(false);
  const [mGravida, setMGravida] = useState(false);
  const [mGravidaQtdMeses, setMGravidaQtdMeses] = useState(null);
  const [mBebidaAlcoolica, setMBebidaAlcoolica] = useState(false);
  const [mDrogas, setMDrogas] = useState(false);
  const [mCicatrizacao, setMCicatrizacao] = useState(null);
  const [mHemorragia, setMHemorragia] = useState(false);
  const [mEpilepsia, setMEpilepsia] = useState(false);
  const [mSistemica, setMSistemica] = useState(false);
  const [mCancer, setMCancer] = useState(false);
  const [mSifilis, setMSifilis] = useState(false);
  const [mHiv, setMHiv] = useState(false);
  // historia odontologica
  const [oPrimeiraVez, setOPrimeiraVez] = useState(false);
  const [oUltTratamento, setOUltTratamento] = useState(null);
  const [oComportCrianca, setOComportCrianca] = useState(null);
  const [oProblemBocaFilho, setOProblemBocaFilho] = useState(null);
  const [oAbandonoOdonto, setOAbandonoOdonto] = useState(false);
  const [oPqAbandonoOdonto, setOPqAbandonoOdonto] = useState(null);
  const [oAnestesiaFilho, setOAnestesiaFilho] = useState(false);
  const [oVisitRegularDent, setOVisitRegularDent] = useState(false);
  const [oTempoRegVisita, setOTempoRegVisita] = useState(null);
  const [oUltVisitDentista, setOUltVisitDentista] = useState(null);
  const [oPrimeiroDente, setOPrimeiroDente] = useState(null);
  const [oSensibilidadeDentes, setOSensibilidadeDentes] = useState(false);
  const [oSangramentoGengival, setOSangramentoGengival] = useState(false);
  //habitos de higiene oral
  const [hOFreqEscovacao, setHOFreqEscovacao] = useState(null);
  const [hOCremeDental, setHOCremeDental] = useState(null);
  const [hOFioDental, setHOFioDental] = useState(false);
  const [hOBochecho, setHOBochecho] = useState(false);
  const [hOQualBochecho, setHOQualBochecho] = useState(null);
  const [hOEscovaLingua, setHOEscovaLingua] = useState(false);
  const [hOQuemHigMenor, setHOQuemHigMenor] = useState(null);
  const [hOInicioEscovacao, setHOInicioEscovacao] = useState(null);
  //habitos deleterios
  const [hDDormeBocaAberta, setHDDormeBocaAberta] = useState(false);
  const [hDRonca, setHDRonca] = useState(false);
  const [hDBabaTravesseiro, setHDBabaTravesseiro] = useState(false);
  const [hDMordeObjetos, setHDMordeObjetos] = useState(false);
  const [hDMordeLabios, setHDMordeLabios] = useState(false);
  const [hDRangeDentes, setHDRangeDentes] = useState(false);
  const [hDRoerUnhas, setHDRoerUnhas] = useState(false);
  const [hDSuccaoDedos, setHDSuccaoDedos] = useState(false);
  const [hDQtAnosAbandSuccDedos, setHDQtAnosAbandSuccDedos] = useState(null);
  const [hDChupeta, setHDChupeta] = useState(false);
  const [hDQtAnosAbandChupeta, setHDQtAnosAbandChupeta] = useState(null);
  const [hDMamadeira, setHDMamadeira] = useState(false);
  const [hDQtAnosAbandMamadeira, setHDQtAnosAbandMamadeira] = useState(null);
  //habitos alimentares
  const [hAMamadeiraAcucar, setHAMamadeiraAcucar] = useState(false);
  const [hAMamadeiraSemAcucar, setHAMamadeiraSemAcucar] = useState(false);
  const [hAMamadeiraDiurna, setHAMamadeiraDiurna] = useState(false);
  const [hAMamadeiraNoturna, setHAMamadeiraNoturna] = useState(false);
  const [hAAlimentacao, setHAAlimentacao] = useState(null);
  const [hANumRefeicaoDia, setHANumRefeicaoDia] = useState(null);
  const [hACostumaBeliscar, setHACostumaBeliscar] = useState(false);
  const [hAFormAcucarIngerido, setHAFormAcucarIngerido] = useState(null);
  //perfil psicologico
  const [pPCalma, setPPCalma] = useState(false);
  const [pPAgitada, setPPAgitada] = useState(false);
  const [pPExtrovertida, setPPExtrovertida] = useState(false);
  const [pPIntrovertida, setPPIntrovertida] = useState(false);
  const [pPComunicativa, setPPComunicativa] = useState(false);
  const [pPReservada, setPPReservada] = useState(false);
  const [pPObs, setPPObs] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const salvar = e => {
    e.preventDefault();
    if (permissoes.pacAlt !== '0' && permissoes !== false) {
      if (idFicha !== false) {
        api
          .post('Ficha/update.php', {
            pdo: getClinica(),
            queixa: queixa,
            hM_tratamentoMedico: Number(mTratamentoMedico),
            hM_pqTrataMedico: mPqTratamentoMedico,
            hM_ultimoTratamento: mUltimoTratamento,
            hM_pqUltiTratamento: mPqUltTratamento,
            hM_alergia: Number(mAlergia),
            hM_alergiaMedicamento: mAlergiaMedicamento,
            hM_medicamentoUsual: Number(mMedicamentoUsual),
            hM_nomeMedicamentUsual: mNomeMedicamentoUsual,
            hM_pqMedicamentUsual: mPqMedicamentoUsual,
            hM_antibiotico: Number(mAntibiotico),
            hM_nomeAntibiotico: mNomeAntibiotico,
            hM_pqAntibiotico: mPqAntibiotico,
            hM_antinflamatorio: Number(mAntinflamatorio),
            hM_nomeAntinflamatorio: mNomeAntinflamatorio,
            hM_analgesico: Number(mAnalgesico),
            hM_nomeAnalgesico: mNomeAnalgesico,
            hM_doencaOuAlteracao: Number(mDoencaAlteracao),
            hM_nomeDoencaAlteracao: mNomeDoencaAlteracao,
            hM_hospitalizadoCirurgia: Number(mHospitalizadoCirurgia),
            hM_quandoHospitalizado: mQuandoHospitalizado,
            hM_pqHospitalizado: mPqHospitalizado,
            hM_recTrasnfSangue: Number(mRecTransfSangue),
            hM_quandTransfSangue: mQuandoTransfSangue,
            hM_pqTransfSangue: mPqTransfSangue,
            hM_cardiopatia: Number(mCardiopatia),
            hM_bronquite: Number(mBronquite),
            hM_anemia: Number(mAnemia),
            hM_diabetes: Number(mDiabetes),
            hM_rinite: Number(mRinite),
            hM_sinusite: Number(mSinusite),
            hM_hepatite: Number(mHepatite),
            hM_colesterol: Number(mColesterol),
            hM_febreReumatica: Number(mFebreReumatica),
            hM_asma: Number(mAsma),
            hM_cansadoFrenquencia: Number(mCansadoFrequencia),
            hM_alteracaoRinsBexiga: Number(mAlteracaoRinsBexiga),
            hM_corteSangraMuito: Number(mCorteSangraMuito),
            hM_pressao: Number(mPressao),
            hM_gravida: Number(mGravida),
            hM_gravidaQtdmeses: mGravidaQtdMeses,
            hM_bebidaAlcolica: Number(mBebidaAlcoolica),
            hM_drogas: Number(mDrogas),
            hM_cicatrizacao: mCicatrizacao,
            hM_hemorragia: Number(mHemorragia),
            hM_pilepsia: Number(mEpilepsia),
            hM_sistemica: Number(mSistemica),
            hM_cancer: Number(mCancer),
            hM_sifilis: Number(mSifilis),
            hM_hiv: Number(mHiv),
            hO_primeiraVez: Number(oPrimeiraVez),
            hO_ultimTratamento: oUltTratamento,
            hO_comportCrianca: oComportCrianca,
            hO_problemBocaFilho: oProblemBocaFilho,
            hO_abandonoOdonto: Number(oAbandonoOdonto),
            hO_pqAbandonoOdonto: oPqAbandonoOdonto,
            hO_anestesiaFilho: Number(oAnestesiaFilho),
            hO_visitRegularDent: Number(oVisitRegularDent),
            hO_tempoRegVisita: oTempoRegVisita,
            hO_ultVistiDentista: oUltVisitDentista,
            hO_primeiroDente: oPrimeiroDente,
            hO_sensibilidadeDentes: Number(oSensibilidadeDentes),
            hO_sangramento: Number(oSangramentoGengival),
            higO_freqEscovacao: hOFreqEscovacao,
            higO_cremeDental: hOCremeDental,
            higO_fioDental: Number(hOFioDental),
            higO_bochecho: Number(hOBochecho),
            higO_qualBochecho: hOQualBochecho,
            higO_escovaLingua: Number(hOEscovaLingua),
            higO_quemHigMenor: hOQuemHigMenor,
            higO_inicioEscovacao: hOInicioEscovacao,
            hD_dormeBocaAberta: Number(hDDormeBocaAberta),
            hD_ronca: Number(hDRonca),
            hD_babaTravesseiro: Number(hDBabaTravesseiro),
            hD_mordeObjetos: Number(hDMordeObjetos),
            hD_mordeLabios: Number(hDMordeLabios),
            hD_rangeDentes: Number(hDRangeDentes),
            hD_roerUnhas: Number(hDRoerUnhas),
            hD_succaoDedos: Number(hDSuccaoDedos),
            hD_qtAnosAbandSucDedos: hDQtAnosAbandSuccDedos,
            hD_chupeta: Number(hDChupeta),
            hD_qtAnosAbandChupeta: hDQtAnosAbandChupeta,
            hD_mamadeira: Number(hDMamadeira),
            hD_qtAnosAbndMamadeira: hDQtAnosAbandMamadeira,
            hA_mamadeiraAcucar: Number(hAMamadeiraAcucar),
            hA_mamadeiraSemAcucar: Number(hAMamadeiraSemAcucar),
            hA_mamadeiraDiurna: Number(hAMamadeiraDiurna),
            hA_mamadeiraNoturna: Number(hAMamadeiraNoturna),
            hA_alimentacao: hAAlimentacao,
            hA_numRefeicaoDia: hANumRefeicaoDia,
            hA_costumaBeliscar: Number(hACostumaBeliscar),
            hA_formAcucarIngerido: hAFormAcucarIngerido,
            pP_calma: Number(pPCalma),
            pP_agitada: Number(pPAgitada),
            pP_extrovertida: Number(pPExtrovertida),
            pP_introvertida: Number(pPIntrovertida),
            pP_comunicativa: Number(pPComunicativa),
            pP_reservada: Number(pPReservada),
            pP_obs: pPObs,
            idCliente: Paciente.id,
          })
          .then(res => {
            console.log(res.data);
            abrirAviso('success', 'Dados salvos');
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        api
          .post('Ficha/save.php', {
            pdo: getClinica(),
            idCliente: Paciente.id,
            queixa: queixa,
            hM_tratamentoMedico: Number(mTratamentoMedico),
            hM_pqTrataMedico: mPqTratamentoMedico,
            hM_ultimoTratamento: mUltimoTratamento,
            hM_pqUltiTratamento: mPqUltTratamento,
            hM_alergia: Number(mAlergia),
            hM_alergiaMedicamento: mAlergiaMedicamento,
            hM_medicamentoUsual: Number(mMedicamentoUsual),
            hM_nomeMedicamentUsual: mNomeMedicamentoUsual,
            hM_pqMedicamentUsual: mPqMedicamentoUsual,
            hM_antibiotico: Number(mAntibiotico),
            hM_nomeAntibiotico: mNomeAntibiotico,
            hM_pqAntibiotico: mPqAntibiotico,
            hM_antinflamatorio: Number(mAntinflamatorio),
            hM_nomeAntinflamatorio: mNomeAntinflamatorio,
            hM_analgesico: Number(mAnalgesico),
            hM_nomeAnalgesico: mNomeAnalgesico,
            hM_doencaOuAlteracao: Number(mDoencaAlteracao),
            hM_nomeDoencaAlteracao: mNomeDoencaAlteracao,
            hM_hospitalizadoCirurgia: Number(mHospitalizadoCirurgia),
            hM_quandoHospitalizado: mQuandoHospitalizado,
            hM_pqHospitalizado: mPqHospitalizado,
            hM_recTrasnfSangue: Number(mRecTransfSangue),
            hM_quandTransfSangue: mQuandoTransfSangue,
            hM_pqTransfSangue: mPqTransfSangue,
            hM_cardiopatia: Number(mCardiopatia),
            hM_bronquite: Number(mBronquite),
            hM_anemia: Number(mAnemia),
            hM_diabetes: Number(mDiabetes),
            hM_rinite: Number(mRinite),
            hM_sinusite: Number(mSinusite),
            hM_hepatite: Number(mHepatite),
            hM_colesterol: Number(mColesterol),
            hM_febreReumatica: Number(mFebreReumatica),
            hM_asma: Number(mAsma),
            hM_cansadoFrenquencia: Number(mCansadoFrequencia),
            hM_alteracaoRinsBexiga: Number(mAlteracaoRinsBexiga),
            hM_corteSangraMuito: Number(mCorteSangraMuito),
            hM_pressao: Number(mPressao),
            hM_gravida: Number(mGravida),
            hM_gravidaQtdmeses: mGravidaQtdMeses,
            hM_bebidaAlcolica: Number(mBebidaAlcoolica),
            hM_drogas: Number(mDrogas),
            hM_cicatrizacao: mCicatrizacao,
            hM_hemorragia: Number(mHemorragia),
            hM_pilepsia: Number(mEpilepsia),
            hM_sistemica: Number(mSistemica),
            hM_cancer: Number(mCancer),
            hM_sifilis: Number(mSifilis),
            hM_hiv: Number(mHiv),
            hO_primeiraVez: Number(oPrimeiraVez),
            hO_ultimTratamento: oUltTratamento,
            hO_comportCrianca: oComportCrianca,
            hO_problemBocaFilho: oProblemBocaFilho,
            hO_abandonoOdonto: Number(oAbandonoOdonto),
            hO_pqAbandonoOdonto: oPqAbandonoOdonto,
            hO_anestesiaFilho: Number(oAnestesiaFilho),
            hO_visitRegularDent: Number(oVisitRegularDent),
            hO_tempoRegVisita: oTempoRegVisita,
            hO_ultVistiDentista: oUltVisitDentista,
            hO_primeiroDente: oPrimeiroDente,
            higO_freqEscovacao: hOFreqEscovacao,
            higO_cremeDental: hOCremeDental,
            higO_fioDental: Number(hOFioDental),
            higO_bochecho: Number(hOBochecho),
            higO_qualBochecho: hOQualBochecho,
            higO_escovaLingua: Number(hOEscovaLingua),
            higO_quemHigMenor: hOQuemHigMenor,
            higO_inicioEscovacao: hOInicioEscovacao,
            hD_dormeBocaAberta: Number(hDDormeBocaAberta),
            hD_ronca: Number(hDRonca),
            hD_babaTravesseiro: Number(hDBabaTravesseiro),
            hD_mordeObjetos: Number(hDMordeObjetos),
            hD_mordeLabios: Number(hDMordeLabios),
            hD_rangeDentes: Number(hDRangeDentes),
            hD_roerUnhas: Number(hDRoerUnhas),
            hD_succaoDedos: Number(hDSuccaoDedos),
            hD_qtAnosAbandSucDedos: hDQtAnosAbandSuccDedos,
            hD_chupeta: Number(hDChupeta),
            hD_qtAnosAbandChupeta: hDQtAnosAbandChupeta,
            hD_mamadeira: Number(hDMamadeira),
            hD_qtAnosAbndMamadeira: hDQtAnosAbandMamadeira,
            hA_mamadeiraAcucar: Number(hAMamadeiraAcucar),
            hA_mamadeiraSemAcucar: Number(hAMamadeiraSemAcucar),
            hA_mamadeiraDiurna: Number(hAMamadeiraDiurna),
            hA_mamadeiraNoturna: Number(hAMamadeiraNoturna),
            hA_alimentacao: hAAlimentacao,
            hA_numRefeicaoDia: hANumRefeicaoDia,
            hA_costumaBeliscar: Number(hACostumaBeliscar),
            hA_formAcucarIngerido: hAFormAcucarIngerido,
            pP_calma: Number(pPCalma),
            pP_agitada: Number(pPAgitada),
            pP_extrovertida: Number(pPExtrovertida),
            pP_introvertida: Number(pPIntrovertida),
            pP_comunicativa: Number(pPComunicativa),
            pP_reservada: Number(pPReservada),
            pP_obs: pPObs,
          })
          .then(res => {
            abrirAviso('success', 'Dados salvos');
          })
          .catch(error => {
            console.log(error);
          });
      }
    } else {
      abrirAviso('error', 'Você não tem permissão para realizar essa ação');
    }
  };

  const consultar = async id => {
    setLoading(true);
    await api
      .get('Ficha/find.php?idCliente=' + id + '&pdo=' + getClinica())
      .then(res => {
        if (res.data !== false) {
          setIdFicha(res.data.id);
          setQueixa(res.data.queixa);
          setMTratamentoMedico(Boolean(parseInt(res.data.hM_tratamentoMedico)));
          setMPqTratamentoMedico(res.data.hM_pqTrataMedico);
          setMUltimoTratamento(res.data.hM_ultimoTratamento);
          setPqUltTratamento(res.data.hM_pqUltiTratamento);
          setMAlergia(res.data.hM_alergia);
          setMAlergiaMedicamento(res.data.hM_alergiaMedicamento);
          setMMedicamentoUsual(Boolean(parseInt(res.data.hM_medicamentoUsual)));
          setMNomeMedicamentoUsual(res.data.hM_nomeMedicamentUsual);
          setMPqMedicamentoUsual(res.data.hM_pqMedicamentUsual);
          setMAntibiotico(Boolean(parseInt(res.data.hM_antibiotico)));
          setMNomeAntibiotico(res.data.hM_nomeAntibiotico);
          setMPqAntibiotico(res.data.hM_pqAntibiotico);
          setMAntinflamatorio(Boolean(parseInt(res.data.hM_antinflamatorio)));
          setMNomeAntinflamatorio(res.data.hM_nomeAntinflamatorio);
          setMAnalgesico(Boolean(parseInt(res.data.hM_analgesico)));
          setMNomeAnalgesico(res.data.hM_nomeAnalgesico);
          setMDoencaAlteracao(Boolean(parseInt(res.data.hM_doencaOuAlteracao)));
          setMNomeDoencaAlteracao(res.data.hM_nomeDoencaAlteracao);
          setMHospitalizadoCirurgia(
            Boolean(parseInt(res.data.hM_hospitalizadoCirurgia)),
          );
          setMQuandoHospitalizado(res.data.hM_quandoHospitalizado);
          setMPqHospitalizado(res.data.hM_pqHospitalizado);
          setMRecTransfSangue(Boolean(parseInt(res.data.hM_recTrasnfSangue)));
          setMQuandoTransfSangue(res.data.hM_quandTransfSangue);
          setMPqTransfSangue(res.data.hM_pqTransfSangue);
          setMCardiopatia(Boolean(parseInt(res.data.hM_cardiopatia)));
          setMBronquite(Boolean(parseInt(res.data.hM_bronquite)));
          setMAnemia(Boolean(parseInt(res.data.hM_anemia)));
          setMDiabetes(Boolean(parseInt(res.data.hM_diabetes)));
          setMRinite(Boolean(parseInt(res.data.hM_rinite)));
          setMSinusite(Boolean(parseInt(res.data.hM_sinusite)));
          setMHepatite(Boolean(parseInt(res.data.hM_hepatite)));
          setMColesterol(Boolean(parseInt(res.data.hM_colesterol)));
          setMFebreReumatica(Boolean(parseInt(res.data.hM_febreReumatica)));
          setMAsma(Boolean(parseInt(res.data.hM_asma)));
          setMCansadoFrequencia(
            Boolean(parseInt(res.data.hM_cansadoFrenquencia)),
          );
          setMAlteracaoRinsBexiga(
            Boolean(parseInt(res.data.hM_alteracaoRinsBexiga)),
          );
          setMCorteSangraMuito(Boolean(parseInt(res.data.hM_corteSangraMuito)));
          setMPressao(Boolean(parseInt(res.data.hM_pressao)));
          setMGravida(Boolean(parseInt(res.data.hM_gravida)));
          setMGravidaQtdMeses(res.data.hM_gravidaQtdmeses);
          setMBebidaAlcoolica(Boolean(parseInt(res.data.hM_bebidaAlcolica)));
          setMDrogas(Boolean(parseInt(res.data.hM_drogas)));
          setMCicatrizacao(res.data.hM_cicatrizacao);
          setMHemorragia(Boolean(parseInt(res.data.hM_hemorragia)));
          setMEpilepsia(Boolean(parseInt(res.data.hM_pilepsia)));
          setMSistemica(Boolean(parseInt(res.data.hM_sistemica)));
          setMCancer(Boolean(parseInt(res.data.hM_cancer)));
          setMSifilis(Boolean(parseInt(res.data.hM_sifilis)));
          setMHiv(Boolean(parseInt(res.data.hM_hiv)));
          setOPrimeiraVez(Boolean(parseInt(res.data.hO_primeiraVez)));
          setOUltTratamento(res.data.hO_ultimTratamento);
          setOComportCrianca(res.data.hO_comportCrianca);
          setOProblemBocaFilho(res.data.hO_problemBocaFilho);
          setOAbandonoOdonto(Boolean(parseInt(res.data.hO_abandonoOdonto)));
          setOPqAbandonoOdonto(res.data.hO_pqAbandonoOdonto);
          setOAnestesiaFilho(Boolean(parseInt(res.data.hO_anestesiaFilho)));
          setOVisitRegularDent(Boolean(parseInt(res.data.hO_visitRegularDent)));
          setOTempoRegVisita(res.data.hO_tempoRegVisita);
          setOUltVisitDentista(res.data.hO_ultVistiDentista);
          setOPrimeiroDente(res.data.hO_primeiroDente);
          setOSensibilidadeDentes(
            Boolean(parseInt(res.data.hO_sensibilidadeDentes)),
          );
          setOSangramentoGengival(Boolean(parseInt(res.data.hO_sangramento)));
          setHOFreqEscovacao(res.data.higO_freqEscovacao);
          setHOCremeDental(res.data.higO_cremeDental);
          setHOFioDental(Boolean(parseInt(res.data.higO_fioDental)));
          setHOBochecho(Boolean(parseInt(res.data.higO_bochecho)));
          setHOQualBochecho(res.data.higO_qualBochecho);
          setHOEscovaLingua(Boolean(parseInt(res.data.higO_escovaLingua)));
          setHOQuemHigMenor(res.data.higO_quemHigMenor);
          setHOInicioEscovacao(res.data.higO_inicioEscovacao);
          setHDDormeBocaAberta(Boolean(parseInt(res.data.hD_dormeBocaAberta)));
          setHDRonca(Boolean(parseInt(res.data.hD_ronca)));
          setHDBabaTravesseiro(Boolean(parseInt(res.data.hD_babaTravesseiro)));
          setHDMordeObjetos(Boolean(parseInt(res.data.hD_mordeObjetos)));
          setHDMordeLabios(Boolean(parseInt(res.data.hD_mordeLabios)));
          setHDRangeDentes(Boolean(parseInt(res.data.hD_rangeDentes)));
          setHDRoerUnhas(Boolean(parseInt(res.data.hD_roerUnhas)));
          setHDSuccaoDedos(Boolean(parseInt(res.data.hD_succaoDedos)));
          setHDQtAnosAbandSuccDedos(res.data.hD_qtAnosAbandSucDedos);
          setHDChupeta(Boolean(parseInt(res.data.hD_chupeta)));
          setHDQtAnosAbandChupeta(res.data.hD_qtAnosAbandChupeta);
          setHDMamadeira(Boolean(parseInt(res.data.hD_mamadeira)));
          setHDQtAnosAbandMamadeira(res.data.hD_qtAnosAbndMamadeira);
          setHAMamadeiraAcucar(Boolean(parseInt(res.data.hA_mamadeiraAcucar)));
          setHAMamadeiraSemAcucar(
            Boolean(parseInt(res.data.hA_mamadeiraSemAcucar)),
          );
          setHAMamadeiraDiurna(Boolean(parseInt(res.data.hA_mamadeiraDiurna)));
          setHAMamadeiraNoturna(
            Boolean(parseInt(res.data.hA_mamadeiraNoturna)),
          );
          setHAAlimentacao(res.data.hA_alimentacao);
          setHANumRefeicaoDia(res.data.hA_numRefeicaoDia);
          setHACostumaBeliscar(Boolean(parseInt(res.data.hA_costumaBeliscar)));
          setHAFormAcucarIngerido(res.data.hA_formAcucarIngerido);
          setPPCalma(Boolean(parseInt(res.data.pP_calma)));
          setPPAgitada(Boolean(parseInt(res.data.pP_agitada)));
          setPPExtrovertida(Boolean(parseInt(res.data.pP_extrovertida)));
          setPPIntrovertida(Boolean(parseInt(res.data.pP_introvertida)));
          setPPComunicativa(Boolean(parseInt(res.data.pP_comunicativa)));
          setPPReservada(Boolean(parseInt(res.data.pP_reservada)));
          setPPObs(res.data.pP_obs);
        } else {
          setIdFicha(false);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    consultar(Paciente.id);
  }, []);

  return (
    <>
      <Menu titulo={`Pacientes ► ${Paciente.nome} ► Prontuário`} />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={salvar}
          startIcon={<SaveIcon />}
        >
          Salvar
        </Button>
        <div className={classes.menuOpcoes}>
          <MenuOpcoes paciente={Paciente} />
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab
                label="Queixa"
                icon={<RecordVoiceOverIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="História Médica"
                icon={<LocalHospitalIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label="Observações"
                icon={<DescriptionIcon />}
                {...a11yProps(2)}
              />

              {!!!+empresa?.prontuarioMedico && (
                <Tab
                  label="História Odontológica"
                  icon={<FaTooth size={20} />}
                  {...a11yProps(3)}
                />
              )}
              {!!!+empresa?.prontuarioMedico && (
                <Tab
                  label="Higiene Oral"
                  icon={<InsertEmoticonIcon />}
                  {...a11yProps(4)}
                />
              )}
              {!!!+empresa?.prontuarioMedico && (
                <Tab
                  label="Hábitos Deletérios"
                  icon={<FavoriteBorderIcon />}
                  {...a11yProps(5)}
                />
              )}
              {!!!+empresa?.prontuarioMedico && (
                <Tab
                  label="Hábitos Alimentares"
                  icon={<FastfoodIcon />}
                  {...a11yProps(6)}
                />
              )}
              {!!!+empresa?.prontuarioMedico && (
                <Tab
                  label="Perfil Psicológico"
                  icon={<AssignmentIndIcon />}
                  {...a11yProps(7)}
                />
              )}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Queixa queixa={queixa} setQueixa={setQueixa} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <HistoriaMedica
              mTratamentoMedico={mTratamentoMedico}
              setMTratamentoMedico={setMTratamentoMedico}
              mPqTratamentoMedico={mPqTratamentoMedico}
              setMPqTratamentoMedico={setMPqTratamentoMedico}
              mUltimoTratamento={mUltimoTratamento}
              setMUltimoTratamento={setMUltimoTratamento}
              mPqUltTratamento={mPqUltTratamento}
              setPqUltTratamento={setPqUltTratamento}
              mAlergia={mAlergia}
              setMAlergia={setMAlergia}
              mAlergiaMedicamento={mAlergiaMedicamento}
              setMAlergiaMedicamento={setMAlergiaMedicamento}
              mMedicamentoUsual={mMedicamentoUsual}
              setMMedicamentoUsual={setMMedicamentoUsual}
              mNomeMedicamentoUsual={mNomeMedicamentoUsual}
              setMNomeMedicamentoUsual={setMNomeMedicamentoUsual}
              mPqMedicamentoUsual={mPqMedicamentoUsual}
              setMPqMedicamentoUsual={setMPqMedicamentoUsual}
              mAntibiotico={mAntibiotico}
              setMAntibiotico={setMAntibiotico}
              mNomeAntibiotico={mNomeAntibiotico}
              setMNomeAntibiotico={setMNomeAntibiotico}
              mPqAntibiotico={mPqAntibiotico}
              setMPqAntibiotico={setMPqAntibiotico}
              mAntinflamatorio={mAntinflamatorio}
              setMAntinflamatorio={setMAntinflamatorio}
              mNomeAntinflamatorio={mNomeAntinflamatorio}
              setMNomeAntinflamatorio={setMNomeAntinflamatorio}
              mAnalgesico={mAnalgesico}
              setMAnalgesico={setMAnalgesico}
              mNomeAnalgesico={mNomeAnalgesico}
              setMNomeAnalgesico={setMNomeAnalgesico}
              mDoencaAlteracao={mDoencaAlteracao}
              setMDoencaAlteracao={setMDoencaAlteracao}
              mNomeDoencaAlteracao={mNomeDoencaAlteracao}
              setMNomeDoencaAlteracao={setMNomeDoencaAlteracao}
              mHospitalizadoCirurgia={mHospitalizadoCirurgia}
              setMHospitalizadoCirurgia={setMHospitalizadoCirurgia}
              mQuandoHospitalizado={mQuandoHospitalizado}
              setMQuandoHospitalizado={setMQuandoHospitalizado}
              mPqHospitalizado={mPqHospitalizado}
              setMPqHospitalizado={setMPqHospitalizado}
              mRecTransfSangue={mRecTransfSangue}
              setMRecTransfSangue={setMRecTransfSangue}
              mQuandoTransfSangue={mQuandoTransfSangue}
              setMQuandoTransfSangue={setMQuandoTransfSangue}
              mPqTransfSangue={mPqTransfSangue}
              setMPqTransfSangue={setMPqTransfSangue}
              mCardiopatia={mCardiopatia}
              setMCardiopatia={setMCardiopatia}
              mBronquite={mBronquite}
              setMBronquite={setMBronquite}
              mAnemia={mAnemia}
              setMAnemia={setMAnemia}
              mDiabetes={mDiabetes}
              setMDiabetes={setMDiabetes}
              mRinite={mRinite}
              setMRinite={setMRinite}
              mSinusite={mSinusite}
              setMSinusite={setMSinusite}
              mHepatite={mHepatite}
              setMHepatite={setMHepatite}
              mColesterol={mColesterol}
              setMColesterol={setMColesterol}
              mFebreReumatica={mFebreReumatica}
              setMFebreReumatica={setMFebreReumatica}
              mAsma={mAsma}
              setMAsma={setMAsma}
              mCansadoFrequencia={mCansadoFrequencia}
              setMCansadoFrequencia={setMCansadoFrequencia}
              mAlteracaoRinsBexiga={mAlteracaoRinsBexiga}
              setMAlteracaoRinsBexiga={setMAlteracaoRinsBexiga}
              mCorteSangraMuito={mCorteSangraMuito}
              setMCorteSangraMuito={setMCorteSangraMuito}
              mPressao={mPressao}
              setMPressao={setMPressao}
              mGravida={mGravida}
              setMGravida={setMGravida}
              mGravidaQtdMeses={mGravidaQtdMeses}
              setMGravidaQtdMeses={setMGravidaQtdMeses}
              mCicatrizacao={mCicatrizacao}
              setMCicatrizacao={setMCicatrizacao}
              mHemorragia={mHemorragia}
              setMHemorragia={setMHemorragia}
              mEpilepsia={mEpilepsia}
              setMEpilepsia={setMEpilepsia}
              mSistemica={mSistemica}
              setMSistemica={setMSistemica}
              mCancer={mCancer}
              setMCancer={setMCancer}
              mSifilis={mSifilis}
              setMSifilis={setMSifilis}
              mHiv={mHiv}
              setMHiv={setMHiv}
              mBebidaAlcoolica={mBebidaAlcoolica}
              setMBebidaAlcoolica={setMBebidaAlcoolica}
              mDrogas={mDrogas}
              setMDrogas={setMDrogas}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ObservacoesFicha idFicha={idFicha} Paciente={Paciente} />
          </TabPanel>
          {!!!+empresa?.prontuarioMedico && (
            <>
              <TabPanel value={value} index={3}>
                <HistoriaOdontologica
                  oPrimeiraVez={oPrimeiraVez}
                  setOPrimeiraVez={setOPrimeiraVez}
                  oUltTratamento={oUltTratamento}
                  setOUltTratamento={setOUltTratamento}
                  oComportCrianca={oComportCrianca}
                  setOComportCrianca={setOComportCrianca}
                  oProblemBocaFilho={oProblemBocaFilho}
                  setOProblemBocaFilho={setOProblemBocaFilho}
                  oAbandonoOdonto={oAbandonoOdonto}
                  setOAbandonoOdonto={setOAbandonoOdonto}
                  oPqAbandonoOdonto={oPqAbandonoOdonto}
                  setOPqAbandonoOdonto={setOPqAbandonoOdonto}
                  oAnestesiaFilho={oAnestesiaFilho}
                  setOAnestesiaFilho={setOAnestesiaFilho}
                  oVisitRegularDent={oVisitRegularDent}
                  setOVisitRegularDent={setOVisitRegularDent}
                  oTempoRegVisita={oTempoRegVisita}
                  setOTempoRegVisita={setOTempoRegVisita}
                  oUltVisitDentista={oUltVisitDentista}
                  setOUltVisitDentista={setOUltVisitDentista}
                  oPrimeiroDente={oPrimeiroDente}
                  setOPrimeiroDente={setOPrimeiroDente}
                  oSensibilidadeDentes={oSensibilidadeDentes}
                  setOSensibilidadeDentes={setOSensibilidadeDentes}
                  oSangramentoGengival={oSangramentoGengival}
                  setOSangramentoGengival={setOSangramentoGengival}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <HigieneOral
                  hOFreqEscovacao={hOFreqEscovacao}
                  setHOFreqEscovacao={setHOFreqEscovacao}
                  hOCremeDental={hOCremeDental}
                  setHOCremeDental={setHOCremeDental}
                  hOFioDental={hOFioDental}
                  setHOFioDental={setHOFioDental}
                  hOBochecho={hOBochecho}
                  setHOBochecho={setHOBochecho}
                  hOQualBochecho={hOQualBochecho}
                  setHOQualBochecho={setHOQualBochecho}
                  hOEscovaLingua={hOEscovaLingua}
                  setHOEscovaLingua={setHOEscovaLingua}
                  hOQuemHigMenor={hOQuemHigMenor}
                  setHOQuemHigMenor={setHOQuemHigMenor}
                  hOInicioEscovacao={hOInicioEscovacao}
                  setHOInicioEscovacao={setHOInicioEscovacao}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <HabitosDeleterios
                  hDDormeBocaAberta={hDDormeBocaAberta}
                  setHDDormeBocaAberta={setHDDormeBocaAberta}
                  hDRonca={hDRonca}
                  setHDRonca={setHDRonca}
                  hDBabaTravesseiro={hDBabaTravesseiro}
                  setHDBabaTravesseiro={setHDBabaTravesseiro}
                  hDMordeObjetos={hDMordeObjetos}
                  setHDMordeObjetos={setHDMordeObjetos}
                  hDMordeLabios={hDMordeLabios}
                  setHDMordeLabios={setHDMordeLabios}
                  hDRangeDentes={hDRangeDentes}
                  setHDRangeDentes={setHDRangeDentes}
                  hDRoerUnhas={hDRoerUnhas}
                  setHDRoerUnhas={setHDRoerUnhas}
                  hDSuccaoDedos={hDSuccaoDedos}
                  setHDSuccaoDedos={setHDSuccaoDedos}
                  hDQtAnosAbandSuccDedos={hDQtAnosAbandSuccDedos}
                  setHDQtAnosAbandSuccDedos={setHDQtAnosAbandSuccDedos}
                  hDChupeta={hDChupeta}
                  setHDChupeta={setHDChupeta}
                  hDQtAnosAbandChupeta={hDQtAnosAbandChupeta}
                  setHDQtAnosAbandChupeta={setHDQtAnosAbandChupeta}
                  hDMamadeira={hDMamadeira}
                  setHDMamadeira={setHDMamadeira}
                  hDQtAnosAbandMamadeira={hDQtAnosAbandMamadeira}
                  setHDQtAnosAbandMamadeira={setHDQtAnosAbandMamadeira}
                />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <HabitosAlimentares
                  hAMamadeiraAcucar={hAMamadeiraAcucar}
                  setHAMamadeiraAcucar={setHAMamadeiraAcucar}
                  hAMamadeiraSemAcucar={hAMamadeiraSemAcucar}
                  setHAMamadeiraSemAcucar={setHAMamadeiraSemAcucar}
                  hAMamadeiraDiurna={hAMamadeiraDiurna}
                  setHAMamadeiraDiurna={setHAMamadeiraDiurna}
                  hAMamadeiraNoturna={hAMamadeiraNoturna}
                  setHAMamadeiraNoturna={setHAMamadeiraNoturna}
                  hAAlimentacao={hAAlimentacao}
                  setHAAlimentacao={setHAAlimentacao}
                  hANumRefeicaoDia={hANumRefeicaoDia}
                  setHANumRefeicaoDia={setHANumRefeicaoDia}
                  hACostumaBeliscar={hACostumaBeliscar}
                  setHACostumaBeliscar={setHACostumaBeliscar}
                  hAFormAcucarIngerido={hAFormAcucarIngerido}
                  setHAFormAcucarIngerido={setHAFormAcucarIngerido}
                />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <PerfilPsicologico
                  pPCalma={pPCalma}
                  setPPCalma={setPPCalma}
                  pPAgitada={pPAgitada}
                  setPPAgitada={setPPAgitada}
                  pPExtrovertida={pPExtrovertida}
                  setPPExtrovertida={setPPExtrovertida}
                  pPIntrovertida={pPIntrovertida}
                  setPPIntrovertida={setPPIntrovertida}
                  pPComunicativa={pPComunicativa}
                  setPPComunicativa={setPPComunicativa}
                  pPReservada={pPReservada}
                  setPPReservada={setPPReservada}
                  pPObs={pPObs}
                  setPPObs={setPPObs}
                />
              </TabPanel>
            </>
          )}
        </div>
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
