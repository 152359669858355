import React, { useState, useEffect } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { formatData, formatReal } from '../../../utils/funcoes';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Notificacao from '../../../utils/notificacao';
import CadastrarEditarConta from './cadastrarEditarConta';
import { useEmpresa } from '../../../hooks/empresa';
import { usePermissoes } from '../../../hooks/permissoes';
import { useConfig } from '../../../hooks/config';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  saldo: {
    margin: '10px 0px',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
    marginTop: '60px',
    width: '100%',
  },
  buttonOpcoes: {
    marginRight: '8px',
  },
  textPesquisa: {
    margin: theme.spacing(1),
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  amarelo: {
    backgroundColor: '#FFC107',
  },
  verde: {
    backgroundColor: '#4caf50',
  },
  vermelho: {
    backgroundColor: '#FF0000',
    margin: theme.spacing(1),
  },
  contaVencida: {
    backgroundColor: '#f8beb7',
  },
}));

export default function ContasReceber() {
  const { empresa } = useEmpresa();
  const { permissoes } = usePermissoes();
  const { configuracoes } = useConfig();
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [buscar, setBuscar] = useState('');
  const [contas, setContas] = useState([]);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [status, setStatus] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [filtro, setFiltro] = useState('');
  const [filtroCentro, setFiltroCentro] = useState('');
  const [totalReceber, setTotalReceber] = useState(0);
  const [totalRecebido, setTotalRecebido] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [conta, setConta] = useState(null);
  const [modalCadastrarEditar, setModalCadastrarEditar] = useState(false);

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const atualizar = async () => {
    listarContas('AND status=0 ORDER BY vencimento ASC');
    setLoading(false);
    await api
      .get('Usuario/list_select.php?pdo=' + getClinica())
      .then(res => {
        setClientes(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .post('CentroCusto/search.php', {
        pdo: getClinica(),
        consulta: 'AND ativo=1',
      })
      .then(res => {
        res.data ? setCentrosCusto(res.data) : setCentrosCusto([]);
      })
      .catch(error => console.log(error));
  };

  const listarContas = consulta => {
    setPage(0);
    api
      .post('Receber/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        res.data.length > 0 ? setContas(res.data) : setContas([]);
      })
      .catch(error => console.log(error));
  };

  const totalAReceber = contas => {
    let totalRecebido = contas
      .filter(c => c.status === '1')
      .reduce((sum, li) => sum + Number(li.valor), 0);
    let totalReceber = contas
      .filter(c => c.status === '0')
      .reduce((sum, li) => sum + Number(li.valor), 0);
    setTotalReceber(totalReceber);
    setTotalRecebido(totalRecebido);
  };

  const emitirPromissoria = conta => {
    let cod = btoa(
      JSON.stringify({
        conta: conta,
        empresa: {
          cnpj: empresa.cnpj,
          razao: empresa.razaoSocial,
          fantasia: empresa.nomeFantasia,
          endereco: empresa.logradouro,
          bairro: empresa.bairro,
          numero: empresa.numero,
          cidade: empresa.cidade,
          estado: empresa.estado,
          logo: empresa.logo,
        },
      }),
    );
    sessionStorage.setItem('dataPromiss', cod);
    window.open(
      '/imprimir_promissoria/',
      'Promissoria',
      'height=500,width=703',
    );
    sessionStorage.removeItem('dataPromiss');
  };

  const ehContaVencida = (situacao, e) => {
    let vencimento = e.split('-');
    let data = new Date(vencimento[0], vencimento[1] - 1, vencimento[2]);
    let hoje = new Date();
    if (data < hoje && situacao === '0') {
      return classes.contaVencida;
    }
  };

  const filtrar = () => {
    setLoading(true);
    let filtroBusca = '';
    if (status !== '' && status !== ' ') {
      filtroBusca += ` AND ${status}`;
    }

    if (filtroCentro !== '') {
      filtroBusca += ` AND centroCusto=${filtroCentro}`;
    }

    if (filtro !== '') {
      filtroBusca += ` AND ${filtro}`;
    } else if (filtro === '') {
      filtroBusca += ' AND emissao';
    }

    if (dataInicio !== '' && dataFim !== '') {
      filtroBusca += ` BETWEEN '${dataInicio}' AND '${dataFim}'`;
    } else {
      filtroBusca +=
        filtro !== '' ? ` ORDER BY ${filtro} ASC` : ` ORDER BY vencimento ASC`;
    }
    listarContas(filtroBusca);
    setLoading(false);
  };

  const situacaoConta = valor => {
    if (valor === '0') {
      return (
        <Tooltip title="CONTA PENDENTE" TransitionComponent={Zoom}>
          <Avatar className={classes.amarelo}>PE</Avatar>
        </Tooltip>
      );
    }
    if (valor === '1') {
      return (
        <Tooltip title="CONTA PAGA" TransitionComponent={Zoom}>
          <Avatar className={classes.verde}>PG</Avatar>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="CONTA CANCELADA" TransitionComponent={Zoom}>
          <Avatar className={classes.vermelho}>CA</Avatar>
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    totalAReceber([...contas]);
  }, [contas]);

  const filtradoPorNome = contas.filter(conta => {
    return (
      conta.nomeCli.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      conta.id.toLowerCase().indexOf(buscar.toLowerCase()) !== -1
    );
  });

  const renderizarTotal = () => {
    if (permissoes.finDesTotais && !+permissoes.finDesTotais) {
      return (
        <>
          <Box p={2} width="100%">
            <b>Total a receber:{formatReal(totalReceber)}</b>
          </Box>
          <Box p={2} width="100%">
            <b>Total recebido:{formatReal(totalRecebido)}</b>
          </Box>
        </>
      );
    }
    return <Box p={2} width="100%" />;
  };

  return (
    <div>
      <Menu titulo="Financeiro ► Contas a Receber" />
      <div className={classes.opcoes}>
        <Box display="flex" p={1} bgcolor="background.paper">
          {renderizarTotal()}
          <Box p={1} flexShrink={0}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonOpcoes}
              onClick={() => {
                setConta(null);
                setModalCadastrarEditar(true);
              }}
              startIcon={<AddCircleIcon />}
            >
              Novo
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => window.location.reload()}
              startIcon={<RefreshIcon />}
            >
              Atualizar
            </Button>
          </Box>
        </Box>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value=" ">Todas</MenuItem>
            <MenuItem value="status=0">Aberto</MenuItem>
            <MenuItem value="status=1">Pago</MenuItem>
            <MenuItem value="status=-1">Cancelado</MenuItem>
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Centro de Custo"
            value={filtroCentro}
            onChange={e => setFiltroCentro(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="" selected>
              Selecione...
            </MenuItem>
            {centrosCusto.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.descricao}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Filtro por"
            value={filtro}
            onChange={e => setFiltro(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">Selecione...</MenuItem>
            <MenuItem value="emissao">Emissão</MenuItem>
            <MenuItem value="vencimento">Vencimento</MenuItem>
            <MenuItem value="pagoDia">Pagamento</MenuItem>
          </TextField>
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Inicial"
            value={dataInicio}
            onChange={e => setDataInicio(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Final"
            value={dataFim}
            onChange={e => setDataFim(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Cliente</b>
                  </TableCell>
                  <TableCell>
                    <b>Emissao</b>
                  </TableCell>
                  <TableCell>
                    <b>Vencimento</b>
                  </TableCell>
                  <TableCell>
                    <b>Valor</b>
                  </TableCell>
                  <TableCell>
                    <b>Nº Doc.</b>
                  </TableCell>
                  <TableCell>
                    <b>Nº Trat.</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtradoPorNome
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(conta => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={conta.id}
                        className={ehContaVencida(
                          conta.status,
                          conta.vencimento,
                        )}
                      >
                        <TableCell>{conta.id}</TableCell>
                        <TableCell>{conta.nomeCli}</TableCell>
                        <TableCell>{formatData(conta.emissao)}</TableCell>
                        <TableCell>{formatData(conta.vencimento)}</TableCell>
                        <TableCell>{formatReal(+conta.valor)}</TableCell>
                        <TableCell>{conta.nDoc}</TableCell>
                        <TableCell>{conta.idTratamento}</TableCell>
                        <TableCell>{situacaoConta(conta.status)}</TableCell>
                        <TableCell>
                          <Tooltip
                            title="ABRIR CONTA"
                            TransitionComponent={Zoom}
                          >
                            <Button
                              onClick={() => {
                                setConta(conta);
                                setModalCadastrarEditar(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              <OpenInNewIcon />
                            </Button>
                          </Tooltip>
                          {conta.status === '0' && conta.formaPag === 'crta' && (
                            <Tooltip
                              title="PROMISSÓRIA"
                              TransitionComponent={Zoom}
                            >
                              <Button
                                onClick={() => emitirPromissoria(conta)}
                                variant="contained"
                                color="default"
                                style={{ marginLeft: 5 }}
                              >
                                <ReceiptIcon />
                              </Button>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={contas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}

      {/* cadatrar/editar conta */}
      {modalCadastrarEditar && (
        <CadastrarEditarConta
          modal={modalCadastrarEditar}
          fecharModal={() => setModalCadastrarEditar(false)}
          clientes={clientes}
          centrosCusto={centrosCusto}
          conta={conta}
          empresa={empresa}
          contas={contas}
          setContas={setContas}
          abrirAviso={abrirAviso}
          configuracoes={configuracoes}
          permissoes={permissoes}
        />
      )}

      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
