import React, { useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { listaFormasPagamento } from '../../utils/funcoes';

export default function CadastrarEditarTaxa({
  modal,
  fecharModal,
  configuracoes,
  fullScreen,
  classes,
  setTaxas,
  taxa,
  abrirAviso,
}) {
  const [formaPagId, setFormaPagId] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tipo, setTipo] = useState('0');
  const [parcelaInicio, setParcelaInicio] = useState(1);
  const [parcelaFim, setParcelaFim] = useState(1);
  const [valor, setValor] = useState(0);
  const [status, setStatus] = useState(1);
  const [desabilitar, setDesabilitar] = useState(false);

  const validarCampos = () => {
    return descricao && +valor > 0;
  };

  const cadastrarTaxa = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (validarCampos()) {
        await api
          .post('Taxa/save.php', {
            pdo: getClinica(),
            formaPagId,
            descricao,
            tipo,
            parcelaInicio,
            parcelaFim,
            valor,
            status,
          })
          .then(res => {
            setTaxas(state => [...state, res.data]);
            abrirAviso('success', 'Taxa cadastrada.');
            setDesabilitar(false);
            fecharModal();
          })
          .catch(error => {
            setDesabilitar(false);
            fecharModal();
            abrirAviso(
              'error',
              `Erro ao cadastrar taxa: ${error.response.data}`,
            );
          });
      } else {
        abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const alterarTaxa = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (validarCampos()) {
        api
          .post('Taxa/update.php', {
            pdo: getClinica(),
            formaPagId,
            descricao,
            tipo,
            parcelaInicio,
            parcelaFim,
            valor,
            status,
            id: taxa.id,
          })
          .then(res => {
            setTaxas(state =>
              state.map(e => {
                if (e.id !== res.data.id) {
                  return e;
                }
                e = res.data;
                return e;
              }),
            );
            abrirAviso('success', 'Taxa alterada.');
            setDesabilitar(false);
            fecharModal();
          })
          .catch(error => {
            abrirAviso('error', error.response.data);
            setDesabilitar(false);
          });
      } else {
        abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  useEffect(() => {
    if (taxa) {
      setFormaPagId(taxa.formaPagId);
      setDescricao(taxa.descricao);
      setTipo(taxa.tipo);
      setParcelaInicio(taxa.parcelaInicio);
      setParcelaFim(taxa.parcelaFim);
      setValor(taxa.valor);
      setStatus(taxa.status);
    }
  }, [modal]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal}
      onClose={fecharModal}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {`${!taxa ? 'Cadastrar' : 'Editar'} Taxa`}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição do taxa"
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Forma de Pagamento"
                value={formaPagId}
                onChange={e => setFormaPagId(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                {listaFormasPagamento.map(pg => (
                  <MenuItem value={pg.value}>{pg.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="De Parcela"
                type="number"
                value={parcelaInicio}
                onChange={e => {
                  if (+e.target.value >= 1) setParcelaInicio(+e.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Até Parcela"
                type="number"
                value={parcelaFim}
                onChange={e => {
                  if (+e.target.value >= 1) setParcelaFim(+e.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={tipo}
                onChange={e => setTipo(e.target.value)}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label="Porcentagem"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Valor"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Valor"
                value={valor}
                onChange={e => setValor(e.target.value)}
                variant="outlined"
              />
            </Grid>
            {taxa && (
              <Grid item xs={6}>
                <TextField
                  select
                  fullWidth
                  label="Status"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="1">Ativo</MenuItem>
                  <MenuItem value="0">Inativo</MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => (taxa ? alterarTaxa() : cadastrarTaxa())}
          variant="contained"
          color="primary"
          disabled={desabilitar}
        >
          Salvar
        </Button>
        <Button variant="contained" color="default" onClick={fecharModal}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
