import React from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Paper,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: '8px',
  },
}));

export default function HistoriaOdontologica({
  oPrimeiraVez,
  setOPrimeiraVez,
  oUltTratamento,
  setOUltTratamento,
  oComportCrianca,
  setOComportCrianca,
  oProblemBocaFilho,
  setOProblemBocaFilho,
  oAbandonoOdonto,
  setOAbandonoOdonto,
  oPqAbandonoOdonto,
  setOPqAbandonoOdonto,
  oAnestesiaFilho,
  setOAnestesiaFilho,
  oVisitRegularDent,
  setOVisitRegularDent,
  oTempoRegVisita,
  setOTempoRegVisita,
  oUltVisitDentista,
  setOUltVisitDentista,
  oPrimeiroDente,
  setOPrimeiroDente,
  oSensibilidadeDentes,
  setOSensibilidadeDentes,
  oSangramentoGengival,
  setOSangramentoGengival,
}) {
  const classes = useStyles();
  return (
    <>
      {/* pergunta 1 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={oPrimeiraVez}
              control={<Checkbox color="primary" />}
              onChange={() => setOPrimeiraVez(!oPrimeiraVez)}
              label="Primeira visita ao dentista?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Se não, quando foi o ultimo tratamento?"
              fullWidth
              value={oUltTratamento}
              onChange={e => setOUltTratamento(e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 2 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Qual foi o comportamento da criança durante o atendimento?"
              fullWidth
              value={oComportCrianca}
              onChange={e => setOComportCrianca(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              size="small"
              label="Como você definiria os problemas da boca de seu filho(a)?"
              fullWidth
              value={oProblemBocaFilho}
              onChange={e => setOProblemBocaFilho(e.target.value)}
              variant="outlined"
            >
              <MenuItem selected>Selecione</MenuItem>
              <MenuItem value="1">Simples</MenuItem>
              <MenuItem value="2">Médio</MenuItem>
              <MenuItem value="3">Grave</MenuItem>
              <MenuItem value="4">Muito Grave</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 3 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={oAbandonoOdonto}
              control={<Checkbox color="primary" />}
              onChange={() => setOAbandonoOdonto(!oAbandonoOdonto)}
              label="Já abandonou algum tratamento odontológico?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Se sim, por quê?"
              fullWidth
              value={oPqAbandonoOdonto}
              onChange={e => setOPqAbandonoOdonto(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 4 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={oAnestesiaFilho}
              control={<Checkbox color="primary" />}
              onChange={() => setOAnestesiaFilho(!oAnestesiaFilho)}
              label="Seu filho (a) já recebeu anestesia no tratamento odontológico?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              checked={oVisitRegularDent}
              control={<Checkbox color="primary" />}
              onChange={() => setOVisitRegularDent(!oVisitRegularDent)}
              label="Visita regulamente o cirurgião dentista?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="De quanto em quanto tempo é a visita?"
              fullWidth
              value={oTempoRegVisita}
              onChange={e => setOTempoRegVisita(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      {/* pergunta 5 */}
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Quando foi a última visita ao cirurgião dentista?"
              fullWidth
              value={oUltVisitDentista}
              onChange={e => setOUltVisitDentista(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Com quantos meses nasceu o primeiro dente?"
              fullWidth
              value={oPrimeiroDente}
              onChange={e => setOPrimeiroDente(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControlLabel
              checked={oSensibilidadeDentes}
              control={<Checkbox color="primary" />}
              onChange={() => setOSensibilidadeDentes(!oSensibilidadeDentes)}
              label="Sensibilidade nos dentes?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={oSangramentoGengival}
              control={<Checkbox color="primary" />}
              onChange={() => setOSangramentoGengival(!oSangramentoGengival)}
              label="Sangramento Gengival?"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
