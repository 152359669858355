import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import Cabecalho from '../../utils/cabecalho';
import Rodape from '../../utils/rodape';
import { makeStyles } from '@material-ui/core/styles';
import { pegarDataCidade } from '../../utils/funcoes';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import ReactQuill from 'react-quill';
import './styleReceituario.css';

const useStyles = makeStyles(theme => ({
  containerReceituario: {
    padding: '5px 25px',
  },
  containerReceituarioTimbrado: {
    marginTop: '160px',
    padding: '5px 25px',
  },
  titulo: {
    marginTop: '15px',
    textAlign: 'center',
  },
  nomePaciente: {
    marginTop: '-10px',
    marginLeft: '18px',
  },
  assinaturaText: {
    marginTop: '80px',
    textAlign: 'center',
  },
  dentistaNome: {
    marginTop: '-10px',
    fontSize: '18px',
  },
  dentistaProfissao: {
    marginTop: '-25px',
    fontSize: '18px',
  },
  dentistaCro: {
    marginTop: '-25px',
    fontSize: '18px',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(1.5),
  },
  dataCidade: {
    marginTop: '20px',
    marginLeft: '50px',
  },
  footer: {
    position: 'fixed',
    width: '100%',
    height: '6%',
    bottom: '0',
  },
}));

export default function ImprimirReceituario() {
  const Dados = JSON.parse(sessionStorage.getItem('receituario'));
  const [dentista, setDentista] = useState('');
  const [loading, setLoading] = useState(true);
  const [empresa, setEmpresa] = useState('');
  const classes = useStyles();

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .get('Usuario/find.php?id=' + Dados.idDentista + '&pdo=' + getClinica())
      .then(res => {
        console.log(res.data);
        setDentista(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .get('Empresa/list.php?pdo=' + getClinica())
      .then(res => {
        setEmpresa(res.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  const imprimir = () => {
    window.document.getElementById('button-print-receituario').style.display =
      'none';
    window.print();
    window.document.getElementById('button-print-receituario').style.display =
      'block';
    window.close();
  };

  return (
    <>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div>
          {!Dados.timbrado && <Cabecalho />}
          <div
            className={
              !Dados.timbrado
                ? classes.containerReceituario
                : classes.containerReceituarioTimbrado
            }
            id="impressao-texto-receituario"
          >
            <h1 className={classes.titulo}>RECEITUÁRIO</h1>
            <br />
            <br />
            <h3 className={classes.nomePaciente}>PACIENTE: {Dados.nome}</h3>
            <ReactQuill
              className={classes.textoReceituario}
              value={Dados.texto}
            />
          </div>
          <div className={classes.dataCidade}>
            <h5>{pegarDataCidade(empresa)}</h5>
          </div>
          <div className={classes.assinaturaText}>
            <p>_____________________________________________________________</p>
            <h5 className={classes.dentistaNome}>{dentista.nome}</h5>
            <h5 className={classes.dentistaProfissao}>{dentista.profissao}</h5>
            {dentista.tipo === '1' && dentista.cro && (
              <h5 className={classes.dentistaCro}>CRO: {dentista.cro}</h5>
            )}
            {dentista.tipo === '3' && dentista.crm && (
              <h5 className={classes.dentistaCro}>CRM: {dentista.crm}</h5>
            )}
            {dentista.rqe && (
              <h5 className={classes.dentistaCro}>RQE: {dentista.rqe}</h5>
            )}
          </div>
          <div className={classes.footer}>{!Dados.timbrado && <Rodape />}</div>
        </div>
      )}
      <Fab
        color="primary"
        size="large"
        id="button-print-receituario"
        className={classes.fab}
        onClick={imprimir}
      >
        <PrintIcon />
      </Fab>
    </>
  );
}
