import React, { useState, useEffect } from 'react';
import '../pages/Orcamentos/Odontologico/style.css';

export default function Odontograma({ procedimentos }) {
  const [adultoCima, setAdultoCima] = useState([]);
  const [adultoBaixo, setAdultoBaixo] = useState([]);
  const [criancaCima, setCriancaCima] = useState([]);
  const [criancaBaixo, setCriancaBaixo] = useState([]);
  const [carregou, setCarregou] = useState(false);

  const cor = e => {
    if (e === 'Amálgama') {
      return 1;
    }
    if (e === 'Cáries') {
      return 2;
    }
    if (e === 'Endodoncia') {
      return 3;
    }
    if (e === 'Ausente') {
      return 4;
    }
    if (e === 'Resina') {
      return 5;
    }
    if (e === 'Implante') {
      return 6;
    }
    if (e === 'Selante') {
      return 7;
    }
    if (e === 'Coroa') {
      return 8;
    } else {
      return 11;
    }
  };

  const face = e => {
    if (e === 'V') {
      return 1;
    }
    if (e === 'D') {
      return 2;
    }
    if (e === 'L/P') {
      return 3;
    }
    if (e === 'M') {
      return 4;
    }
    if (e === 'O') {
      return 5;
    }
    if (e === 'I') {
      return 5;
    } else {
      return 0;
    }
  };

  const atualizar = async () => {
    if (procedimentos) {
      for (let i = 0; i < procedimentos.length; i++) {
        paint(procedimentos[i], face(procedimentos[i].face));
      }
    }
  };

  const carregarDentes = () => {
    let AdCima = [],
      AdBaixo = [],
      CrCima = [],
      CrBaixo = [];
    for (let i = 1; i < 17; i++) {
      let dente;
      if (i <= 8) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoCima',
          numDente: 19 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdCima.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoCima',
          numDente: i + 12,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdCima.push(dente);
      }
    }
    for (let i = 17; i < 33; i++) {
      let dente;
      if (i <= 24) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoBaixo',
          numDente: 65 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdBaixo.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoBaixo',
          numDente: i + 6,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdBaixo.push(dente);
      }
    }
    for (let i = 33; i < 43; i++) {
      let dente;
      if (i <= 37) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaCima',
          numDente: 88 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrCima.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaCima',
          numDente: 23 + i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrCima.push(dente);
      }
    }
    for (let i = 43; i < 53; i++) {
      let dente;
      if (i <= 47) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaBaixo',
          numDente: 128 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrBaixo.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaBaixo',
          numDente: i + 23,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrBaixo.push(dente);
      }
    }
    setAdultoCima(AdCima);
    setAdultoBaixo(AdBaixo);
    setCriancaCima(CrCima);
    setCriancaBaixo(CrBaixo);
    setCarregou(true);
  };

  const adultoCimaPaint = (dente, valor, cor) => {
    adultoCima[dente.idDente - 1].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class4 = 'marcadoVermelho';
        } else {
          adultoCima[dente.idDente - 1].class2 = 'marcadoVermelho';
        }
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class2 = 'marcadoVermelho';
        } else {
          adultoCima[dente.idDente - 1].class4 = 'marcadoVermelho';
        }
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class4 = 'marcadoAmarelo';
        } else {
          adultoCima[dente.idDente - 1].class2 = 'marcadoAmarelo';
        }
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class2 = 'marcadoAmarelo';
        } else {
          adultoCima[dente.idDente - 1].class4 = 'marcadoAmarelo';
        }
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      adultoCima[dente.idDente - 1].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      adultoCima[dente.idDente - 1].class6 = 'marcadoTomate';
      adultoCima[dente.idDente - 1].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class4 = 'marcadoMarrom';
        } else {
          adultoCima[dente.idDente - 1].class2 = 'marcadoMarrom';
        }
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class2 = 'marcadoMarrom';
        } else {
          adultoCima[dente.idDente - 1].class4 = 'marcadoMarrom';
        }
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      adultoCima[dente.idDente - 1].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class4 = 'marcadoVerde';
        } else {
          adultoCima[dente.idDente - 1].class2 = 'marcadoVerde';
        }
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        if (dente.numDente < 20) {
          adultoCima[dente.idDente - 1].class2 = 'marcadoVerde';
        } else {
          adultoCima[dente.idDente - 1].class4 = 'marcadoVerde';
        }
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      adultoCima[dente.idDente - 1].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      adultoCima[dente.idDente - 1].class1 = 'dente';
      adultoCima[dente.idDente - 1].class2 = 'dente';
      adultoCima[dente.idDente - 1].class3 = 'dente';
      adultoCima[dente.idDente - 1].class4 = 'dente';
      adultoCima[dente.idDente - 1].class5 = 'dente';
      adultoCima[dente.idDente - 1].class6 = 'ausente';
      adultoCima[dente.idDente - 1].class7 = 'ausente';
      adultoCima[dente.idDente - 1].class8 = 'coroa';
      adultoCima[dente.idDente - 1].class9 = 'endodoncia';
      adultoCima[dente.idDente - 1].class10 = 'implante';
      adultoCima[dente.idDente - 1].alterou = 0;
    } else if (cor === 11) {
      adultoCima[dente.idDente - 1].class9 = 'marcadoVerdeClaro';
    }
    setAdultoCima([...adultoCima]);
  };

  const adultoBaixoPaint = (dente, valor, cor) => {
    adultoBaixo[dente.idDente - 17].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoVermelho';
        } else {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoVermelho';
        }
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoVermelho';
        } else {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoVermelho';
        }
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoAmarelo';
        } else {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoAmarelo';
        }
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoAmarelo';
        } else {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoAmarelo';
        }
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      adultoBaixo[dente.idDente - 17].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      adultoBaixo[dente.idDente - 17].class6 = 'marcadoTomate';
      adultoBaixo[dente.idDente - 17].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoMarrom';
        } else {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoMarrom';
        }
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoMarrom';
        } else {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoMarrom';
        }
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      adultoBaixo[dente.idDente - 17].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoVerde';
        } else {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoVerde';
        }
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        if (dente.numDente > 40) {
          adultoBaixo[dente.idDente - 17].class2 = 'marcadoVerde';
        } else {
          adultoBaixo[dente.idDente - 17].class4 = 'marcadoVerde';
        }
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      adultoBaixo[dente.idDente - 17].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      adultoBaixo[dente.idDente - 17].class1 = 'dente';
      adultoBaixo[dente.idDente - 17].class2 = 'dente';
      adultoBaixo[dente.idDente - 17].class3 = 'dente';
      adultoBaixo[dente.idDente - 17].class4 = 'dente';
      adultoBaixo[dente.idDente - 17].class5 = 'dente';
      adultoBaixo[dente.idDente - 17].class6 = 'ausente';
      adultoBaixo[dente.idDente - 17].class7 = 'ausente';
      adultoBaixo[dente.idDente - 17].class8 = 'coroa';
      adultoBaixo[dente.idDente - 17].class10 = 'implante';
      adultoBaixo[dente.idDente - 17].class9 = 'endodoncia';
      adultoBaixo[dente.idDente - 17].alterou = 0;
    } else if (cor === 11) {
      adultoBaixo[dente.idDente - 17].class9 = 'marcadoVerdeClaro';
    }
    setAdultoBaixo([...adultoBaixo]);
  };

  const criancaCimaPaint = (dente, valor, cor) => {
    criancaCima[dente.idDente - 33].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class4 = 'marcadoVermelho';
        } else {
          criancaCima[dente.idDente - 33].class2 = 'marcadoVermelho';
        }
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class2 = 'marcadoVermelho';
        } else {
          criancaCima[dente.idDente - 33].class4 = 'marcadoVermelho';
        }
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class4 = 'marcadoAmarelo';
        } else {
          criancaCima[dente.idDente - 33].class2 = 'marcadoAmarelo';
        }
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class2 = 'marcadoAmarelo';
        } else {
          criancaCima[dente.idDente - 33].class4 = 'marcadoAmarelo';
        }
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      criancaCima[dente.idDente - 33].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      criancaCima[dente.idDente - 33].class6 = 'marcadoTomate';
      criancaCima[dente.idDente - 33].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class4 = 'marcadoMarrom';
        } else {
          criancaCima[dente.idDente - 33].class2 = 'marcadoMarrom';
        }
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class2 = 'marcadoMarrom';
        } else {
          criancaCima[dente.idDente - 33].class4 = 'marcadoMarrom';
        }
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      criancaCima[dente.idDente - 33].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class4 = 'marcadoVerde';
        } else {
          criancaCima[dente.idDente - 33].class2 = 'marcadoVerde';
        }
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        if (dente.numDente < 60) {
          criancaCima[dente.idDente - 33].class2 = 'marcadoVerde';
        } else {
          criancaCima[dente.idDente - 33].class4 = 'marcadoVerde';
        }
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      criancaCima[dente.idDente - 33].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      criancaCima[dente.idDente - 33].class1 = 'dente';
      criancaCima[dente.idDente - 33].class2 = 'dente';
      criancaCima[dente.idDente - 33].class3 = 'dente';
      criancaCima[dente.idDente - 33].class4 = 'dente';
      criancaCima[dente.idDente - 33].class5 = 'dente';
      criancaCima[dente.idDente - 33].class6 = 'ausente';
      criancaCima[dente.idDente - 33].class7 = 'ausente';
      criancaCima[dente.idDente - 33].class8 = 'coroa';
      criancaCima[dente.idDente - 33].class10 = 'implante';
      criancaCima[dente.idDente - 33].class9 = 'endodoncia';
      criancaCima[dente.idDente - 33].alterou = 0;
    } else if (cor === 11) {
      criancaCima[dente.idDente - 33].class9 = 'marcadoVerdeClaro';
    }
    setCriancaCima([...criancaCima]);
  };

  const criancaBaixoPaint = (dente, valor, cor) => {
    criancaBaixo[dente.idDente - 43].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoVermelho';
        } else {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoVermelho';
        }
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoVermelho';
        } else {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoVermelho';
        }
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoAmarelo';
        } else {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoAmarelo';
        }
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoAmarelo';
        } else {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoAmarelo';
        }
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      criancaBaixo[dente.idDente - 43].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      criancaBaixo[dente.idDente - 43].class6 = 'marcadoTomate';
      criancaBaixo[dente.idDente - 43].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoMarrom';
        } else {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoMarrom';
        }
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoMarrom';
        } else {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoMarrom';
        }
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      criancaBaixo[dente.idDente - 43].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoVerde';
        } else {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoVerde';
        }
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        if (dente.numDente > 80) {
          criancaBaixo[dente.idDente - 43].class2 = 'marcadoVerde';
        } else {
          criancaBaixo[dente.idDente - 43].class4 = 'marcadoVerde';
        }
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      criancaBaixo[dente.idDente - 43].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      criancaBaixo[dente.idDente - 43].class1 = 'dente';
      criancaBaixo[dente.idDente - 43].class2 = 'dente';
      criancaBaixo[dente.idDente - 43].class3 = 'dente';
      criancaBaixo[dente.idDente - 43].class4 = 'dente';
      criancaBaixo[dente.idDente - 43].class5 = 'dente';
      criancaBaixo[dente.idDente - 43].class6 = 'ausente';
      criancaBaixo[dente.idDente - 43].class7 = 'ausente';
      criancaBaixo[dente.idDente - 43].class8 = 'coroa';
      criancaBaixo[dente.idDente - 43].class10 = 'implante';
      criancaBaixo[dente.idDente - 43].class9 = 'endodoncia';
      criancaBaixo[dente.idDente - 43].alterou = 0;
    } else if (cor === 11) {
      criancaBaixo[dente.idDente - 43].class9 = 'marcadoVerdeClaro';
    }
    setCriancaBaixo([...criancaBaixo]);
  };

  const paint = (dente, valor) => {
    if (dente.tipo === 'adultoCima') {
      adultoCimaPaint(dente, valor, cor(dente.estadoAtual));
    } else if (dente.tipo === 'adultoBaixo') {
      adultoBaixoPaint(dente, valor, cor(dente.estadoAtual));
    } else if (dente.tipo === 'criancaCima') {
      criancaCimaPaint(dente, valor, cor(dente.estadoAtual));
    } else if (dente.tipo === 'criancaBaixo') {
      criancaBaixoPaint(dente, valor, cor(dente.estadoAtual));
    } else if (dente.tipo === 'todosA') {
      for (let i = 0; i < adultoCima.length; i++) {
        adultoCima[i].class9 = 'marcadoAzulClaro';
        adultoCima[i].alterou = 1;
        adultoBaixo[i].class9 = 'marcadoAzulClaro';
        adultoBaixo[i].alterou = 1;
      }
      setAdultoCima(adultoCima);
      setAdultoBaixo(adultoBaixo);
    } else if (dente.tipo === 'todosI') {
      for (let i = 0; i < criancaCima.length; i++) {
        criancaCima[i].class9 = 'marcadoAzulClaro';
        criancaCima[i].alterou = 1;
        criancaBaixo[i].class9 = 'marcadoAzulClaro';
        criancaBaixo[i].alterou = 1;
      }
      setCriancaCima(criancaCima);
      setCriancaBaixo(criancaBaixo);
    } else if (dente.tipo === 'superiorA') {
      for (let i = 0; i < adultoCima.length; i++) {
        adultoCima[i].class9 = 'marcadoAzulClaro';
        adultoCima[i].alterou = 1;
      }
      setAdultoCima(adultoCima);
    } else if (dente.tipo === 'inferiorA') {
      for (let i = 0; i < adultoBaixo.length; i++) {
        adultoBaixo[i].class9 = 'marcadoAzulClaro';
        adultoBaixo[i].alterou = 1;
      }
      setAdultoBaixo(adultoBaixo);
    } else if (dente.tipo === 'superiorI') {
      for (let i = 0; i < criancaCima.length; i++) {
        criancaCima[i].class9 = 'marcadoAzulClaro';
        criancaCima[i].alterou = 1;
      }
      setCriancaCima(criancaCima);
    } else if (dente.tipo === 'inferiorI') {
      for (let i = 0; i < criancaBaixo.length; i++) {
        criancaBaixo[i].class9 = 'marcadoAzulClaro';
        criancaBaixo[i].alterou = 1;
      }
      setCriancaBaixo(criancaBaixo);
    }
  };

  useEffect(() => {
    carregarDentes();
  }, [procedimentos]);

  useEffect(() => {
    if (carregou) {
      atualizar();
    }
  }, [carregou]);

  return (
    <>
      <center>
        <div>
          {adultoCima.map(dente => (
            <svg key={dente.id} height="50" width="50" id={dente.id}>
              <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                {dente.numDente}
              </text>
              <polygon
                points="10,15 15,10 50,45 45,50"
                className={dente.class6}
              />
              <polygon
                points="45,10 50,15 15,50 10,45"
                className={dente.class7}
              />
              <circle cx="30" cy="30" r="16" className={dente.class8} />
              <circle cx="30" cy="30" r="20" className={dente.class9} />
              <polygon
                points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                className={dente.class10}
              />
              <polygon
                points="10,10 50,10 40,20 20,20"
                className={dente.class1}
              />
              <polygon
                points="50,10 50,50 40,40 40,20"
                className={dente.class2}
              />
              <polygon
                points="50,50 10,50 20,40 40,40"
                className={dente.class3}
              />
              <polygon
                points="10,50 20,40 20,20 10,10"
                className={dente.class4}
              />
              <polygon
                points="20,20 40,20 40,40 20,40"
                className={dente.class5}
              />
            </svg>
          ))}
        </div>
        <br />
        <div>
          {criancaCima.map(dente => (
            <svg key={dente.id} height="50" width="50" id={dente.id}>
              <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                {dente.numDente}
              </text>
              <polygon
                points="10,15 15,10 50,45 45,50"
                className={dente.class6}
              />
              <polygon
                points="45,10 50,15 15,50 10,45"
                className={dente.class7}
              />
              <circle cx="30" cy="30" r="16" className={dente.class8} />
              <circle cx="30" cy="30" r="20" className={dente.class9} />
              <polygon
                points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                className={dente.class10}
              />
              <polygon
                points="10,10 50,10 40,20 20,20"
                className={dente.class1}
              />
              <polygon
                points="50,10 50,50 40,40 40,20"
                className={dente.class2}
              />
              <polygon
                points="50,50 10,50 20,40 40,40"
                className={dente.class3}
              />
              <polygon
                points="10,50 20,40 20,20 10,10"
                className={dente.class4}
              />
              <polygon
                points="20,20 40,20 40,40 20,40"
                className={dente.class5}
              />
            </svg>
          ))}
        </div>
        <div>
          {criancaBaixo.map(dente => (
            <svg key={dente.id} height="50" width="50" id={dente.id}>
              <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                {dente.numDente}
              </text>
              <polygon
                points="10,15 15,10 50,45 45,50"
                className={dente.class6}
              />
              <polygon
                points="45,10 50,15 15,50 10,45"
                className={dente.class7}
              />
              <circle cx="30" cy="30" r="16" className={dente.class8} />
              <circle cx="30" cy="30" r="20" className={dente.class9} />
              <polygon
                points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                className={dente.class10}
              />
              <polygon
                points="10,10 50,10 40,20 20,20"
                className={dente.class1}
              />
              <polygon
                points="50,10 50,50 40,40 40,20"
                className={dente.class2}
              />
              <polygon
                points="50,50 10,50 20,40 40,40"
                className={dente.class3}
              />
              <polygon
                points="10,50 20,40 20,20 10,10"
                className={dente.class4}
              />
              <polygon
                points="20,20 40,20 40,40 20,40"
                className={dente.class5}
              />
            </svg>
          ))}
        </div>
        <br />
        <div>
          {adultoBaixo.map(dente => (
            <svg key={dente.id} height="50" width="50" id={dente.id}>
              <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                {dente.numDente}
              </text>
              <polygon
                points="10,15 15,10 50,45 45,50"
                className={dente.class6}
              />
              <polygon
                points="45,10 50,15 15,50 10,45"
                className={dente.class7}
              />
              <circle cx="30" cy="30" r="16" className={dente.class8} />
              <circle cx="30" cy="30" r="20" className={dente.class9} />
              <polygon
                points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                className={dente.class10}
              />
              <polygon
                points="10,10 50,10 40,20 20,20"
                className={dente.class1}
              />
              <polygon
                points="50,10 50,50 40,40 40,20"
                className={dente.class2}
              />
              <polygon
                points="50,50 10,50 20,40 40,40"
                className={dente.class3}
              />
              <polygon
                points="10,50 20,40 20,20 10,10"
                className={dente.class4}
              />
              <polygon
                points="20,20 40,20 40,40 20,40"
                className={dente.class5}
              />
            </svg>
          ))}
        </div>
      </center>
    </>
  );
}
