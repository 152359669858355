import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { mask, unMask } from 'remask';
import Notificacao from '../../utils/notificacao';

const useStyles = makeStyles(theme => ({
  titulos: {
    margin: '8px 0px',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
  },
}));

export default function MeusDados() {
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [listaCpf, setListaCpf] = useState([]);
  const [cpf, setCpf] = useState('');
  const [cpfVerif, setCpfVerif] = useState('');
  const [rg, setRg] = useState('');
  const [nome, setNome] = useState('');
  const [dataNasc, setDataNasc] = useState('');
  const [sexo, setSexo] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [nacionalidade, setNacionalidade] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [profissao, setProfissao] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep2, setCep2] = useState('');
  const [logradouro2, setLogradouro2] = useState('');
  const [numero2, setNumero2] = useState('');
  const [bairro2, setBairro2] = useState('');
  const [complemento2, setComplemento2] = useState('');
  const [cidade2, setCidade2] = useState('');
  const [estado2, setEstado2] = useState('');
  const [login, setLogin] = useState('');

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .get('Usuario/find.php?id=' + getToken() + '&pdo=' + getClinica())
      .then(res => {
        setCpf(res.data.cpf);
        setCpfVerif(res.data.cpf);
        setRg(res.data.rg);
        setNome(res.data.nome);
        setDataNasc(res.data.dataNasc);
        setSexo(res.data.sexo);
        setNaturalidade(res.data.naturalidade);
        setNacionalidade(res.data.nacionalidade);
        setEstadoCivil(res.data.estadoCivil);
        setProfissao(res.data.profissao);
        setTelefone(res.data.telefone);
        setCelular(res.data.celular);
        setCep(res.data.endCepR);
        setLogradouro(res.data.endLograR);
        setNumero(res.data.endNumR);
        setBairro(res.data.endBairroR);
        setCidade(res.data.endCidadeR);
        setEstado(res.data.endEstadoR);
        setComplemento(res.data.endCompleR);
        setCep2(res.data.endCepP);
        setLogradouro2(res.data.endLograP);
        setNumero2(res.data.endNumP);
        setLogin(res.data.login);
        setBairro2(res.data.endBairroP);
        setCidade2(res.data.endCidadeP);
        setEstado2(res.data.endEstadoP);
        setComplemento2(res.data.endCompleP);
        setLoading(false);
      });
  };

  const verificarCpf = async cpf => {
    let ret = false;
    await api
      .get('Usuario/verificarCpf.php?pdo=' + getClinica() + '&cpf=' + cpf)
      .then(res => {
        ret = res.data;
      });
    return ret;
  };

  const validarCampos = () => {
    if (
      cpf !== '' &&
      rg !== '' &&
      nome !== '' &&
      dataNasc !== '' &&
      sexo !== '' &&
      profissao !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  const alterarDados = async () => {
    setDesabilitar(true);
    if (validarCampos()) {
      let existeCPF = await verificarCpf(cpf);
      let CPF = '';
      if (cpf !== cpfVerif) {
        if (!existeCPF) {
          CPF = cpf;
        } else {
          abrirAviso('warning', 'CPF já cadastrado.');
          setDesabilitar(false);
          return '';
        }
      } else {
        CPF = cpfVerif;
      }

      api
        .post('Usuario/update.php', {
          pdo: getClinica(),
          cpf: CPF,
          rg: rg,
          nome: nome,
          dataNasc: dataNasc,
          sexo: sexo,
          naturalidade: naturalidade,
          nacionalidade: nacionalidade,
          estadoCivil: estadoCivil,
          profissao: profissao,
          telefone: telefone,
          celular: celular,
          convenio: '',
          nCartao: '',
          login: login,
          endCepR: cep,
          endLograR: logradouro,
          endNumR: numero,
          endBairroR: bairro,
          endCidadeR: cidade,
          endEstadoR: estado,
          endCompleR: complemento,
          endCepP: cep2,
          endLograP: logradouro2,
          endNumP: numero2,
          endBairroP: bairro2,
          endCidadeP: cidade2,
          endEstadoP: estado2,
          endCompleP: complemento2,
          id: getToken(),
        })
        .then(() => {
          abrirAviso('success', 'Dados alterados');
          setDesabilitar(false);
        });
    } else {
      abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
      setDesabilitar(false);
    }
  };

  const consultarCepR = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep)
      .then(res => {
        setCep(res.data.cep);
        setLogradouro(res.data.logradouro);
        setBairro(res.data.bairro);
        setCidade(res.data.cidade);
        setEstado(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const consultarCepP = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep2)
      .then(res => {
        setCep2(res.data.cep);
        setLogradouro2(res.data.logradouro);
        setBairro2(res.data.bairro);
        setCidade2(res.data.cidade);
        setEstado2(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Menu titulo="Meus dados" />
      <Box
        display="flex"
        className={classes.opcoes}
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={alterarDados}
            startIcon={<SaveIcon />}
            disabled={desabilitar}
          >
            {' '}
            Salvar
          </Button>
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="CPF *"
                fullWidth
                size="small"
                value={cpf}
                onChange={e => setCpf(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Rg *"
                fullWidth
                size="small"
                value={rg}
                onChange={e => setRg(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Nome *"
                fullWidth
                size="small"
                value={nome}
                onChange={e => setNome(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Nascimento *"
                type="date"
                fullWidth
                size="small"
                value={dataNasc}
                onChange={e => setDataNasc(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                label="Sexo *"
                fullWidth
                size="small"
                value={sexo}
                onChange={e => setSexo(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="m">Masculino</MenuItem>
                <MenuItem value="f">Feminino.</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Naturalidade"
                fullWidth
                size="small"
                value={naturalidade}
                onChange={e => setNaturalidade(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Nacionalidade"
                fullWidth
                size="small"
                value={nacionalidade}
                onChange={e => setNacionalidade(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                label="Estado civil"
                fullWidth
                size="small"
                value={estadoCivil}
                onChange={e => setEstadoCivil(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="1">Solteiro(a)</MenuItem>
                <MenuItem value="2">Casado(a)</MenuItem>
                <MenuItem value="3">Separado(a)</MenuItem>
                <MenuItem value="4">Divorciado(a)</MenuItem>
                <MenuItem value="5">Viúvo(a)</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Profissão *"
                fullWidth
                size="small"
                value={profissao}
                onChange={e => setProfissao(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Telefone"
                fullWidth
                size="small"
                value={telefone}
                onChange={e =>
                  setTelefone(mask(unMask(e.target.value), ['(99)9999-9999']))
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Celular"
                fullWidth
                size="small"
                value={celular}
                onChange={e =>
                  setCelular(mask(unMask(e.target.value), ['(99)99999-9999']))
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
          <h4 className={classes.titulos}>Endereço residencial</h4>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor="cep">Cep</InputLabel>
                <OutlinedInput
                  id="cep"
                  type="text"
                  value={cep}
                  onChange={e =>
                    setCep(mask(unMask(e.target.value), ['99999999']))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => consultarCepR()} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Logradouro"
                fullWidth
                size="small"
                value={logradouro}
                onChange={e => setLogradouro(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Número"
                fullWidth
                size="small"
                value={numero}
                onChange={e => setNumero(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Bairro"
                fullWidth
                size="small"
                value={bairro}
                onChange={e => setBairro(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Cidade"
                fullWidth
                size="small"
                value={cidade}
                onChange={e => setCidade(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Estado"
                fullWidth
                size="small"
                value={estado}
                onChange={e => setEstado(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complemento"
                fullWidth
                size="small"
                value={complemento}
                onChange={e => setComplemento(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <h4 className={classes.titulos}>Endereço profissional</h4>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor="cep">Cep</InputLabel>
                <OutlinedInput
                  id="cep"
                  type="text"
                  value={cep2}
                  onChange={e =>
                    setCep2(mask(unMask(e.target.value), ['99999999']))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => consultarCepP()} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Logradouro"
                fullWidth
                size="small"
                value={logradouro2}
                onChange={e => setLogradouro2(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Número"
                fullWidth
                size="small"
                value={numero2}
                onChange={e => setNumero2(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Bairro"
                fullWidth
                size="small"
                value={bairro2}
                onChange={e => setBairro2(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Cidade"
                fullWidth
                size="small"
                value={cidade2}
                onChange={e => setCidade2(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Estado"
                fullWidth
                size="small"
                value={estado2}
                onChange={e => setEstado2(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complemento"
                fullWidth
                size="small"
                value={complemento2}
                onChange={e => setComplemento2(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Notificacao
            aviso={aviso}
            fecharAviso={fecharAviso}
            tipoAviso={tipoAviso}
            mensagemAviso={mensagemAviso}
          />
        </div>
      )}
    </div>
  );
}
