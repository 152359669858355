import React, { useEffect } from 'react';
import numero from 'numero-por-extenso';
import { formatData, formatReal } from '../../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import { mask } from 'remask';

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  borda: {
    padding: 5,
    border: 4,
    borderStyle: 'solid',
    borderRadius: 5,
  },
  marcaDagua: {
    opacity: 0.2,
    position: 'absolute',
    width: '95.7%',
    height: 'auto',
    // marginTop: -8,
    maxHeight: 315,
  },
  textoPromissoria: {
    marginTop: -25,
    textAlign: 'justify',
    fontSize: 13,
    lineHeight: 1.5,
  },
  pontilhado: {
    margin: '0px 10px 0px',
    fontSize: 14,
  },
  linhaNome: {
    borderBottom: '1px solid',
    marginLeft: 50,
  },
  linhaCPF: {
    borderBottom: '1px solid',
    marginLeft: 85,
  },
  linhaAss: {
    textAlign: 'center',
    borderTop: ' 1px solid',
    marginTop: 35,
    marginBottom: 0,
  },
  camposAss: {
    marginTop: -25,
  },
  titulo: {
    fontWeight: 'bold',
    fontSize: 20,
    marginLeft: 20,
  },
  vencimentoTitulo: {
    float: 'right',
  },
  numeroPromissoria: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  valor: {
    float: 'right',
    fontWeight: 'bold',
    fontSize: 20,
  },
  tituloAvalista: {
    marginTop: -18,
    marginBottom: 7,
  },
}));

export default function Promissoria() {
  const parametros = JSON.parse(atob(sessionStorage.getItem('dataPromiss')));
  const conta = parametros.conta;
  const empresa = parametros.empresa;
  const classes = useStyles();
  let diaVencimento = new Date(conta.vencimento);
  const imprimir = () => {
    window.document.getElementById('promissoria-print').style.display = 'none';
    window.print();
    window.document.getElementById('promissoria-print').style.display = 'block';
    window.close();
  };

  return (
    <div className={classes.borda}>
      <img src={empresa.logo} className={classes.marcaDagua} />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <b className={classes.titulo}>NOTA PROMISSÓRIA</b>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.vencimentoTitulo}>
            Vencimento{' '}
            <b className={classes.pontilhado}>{diaVencimento.getDate() + 1}</b>{' '}
            de{' '}
            <b className={classes.pontilhado}>
              {meses[diaVencimento.getMonth()]}
            </b>{' '}
            de{' '}
            <b className={classes.pontilhado}>{diaVencimento.getFullYear()}</b>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: -10 }}>
        <Grid item xs={6}>
          <b className={classes.numeroPromissoria}>Nº {conta.id}</b>
        </Grid>
        <Grid item xs={6}>
          <b className={classes.valor}>
            R$ {formatReal(conta.valor).substr(2)}
          </b>
        </Grid>
        <Grid item xs={12} className={classes.textoPromissoria}>
          <p>
            Ao(s){' '}
            <b className={classes.pontilhado}>
              {numero.porExtenso(diaVencimento.getDate() + 1)} dia(s) do mês de{' '}
              {meses[diaVencimento.getMonth()].toLocaleLowerCase()} do ano de{' '}
              {numero.porExtenso(diaVencimento.getFullYear())}
            </b>{' '}
            pagar <b className={classes.pontilhado}>EI</b> por esta única via de{' '}
            <b className={classes.pontilhado}>NOTA PROMISSÓRIA</b> a{' '}
            <b className={classes.pontilhado}>{empresa.razao.toUpperCase()}</b>{' '}
            CPF/CNPJ{' '}
            <b className={classes.pontilhado}>
              {mask(empresa.cnpj, '99.999.999/9999-99')}
            </b>{' '}
            ou à sua ordem, a quantia de{' '}
            <b className={classes.pontilhado}>
              {' '}
              {numero
                .porExtenso(conta.valor, numero.estilo.monetario)
                .toUpperCase()}
            </b>{' '}
            em moeda corrente deste país, pagável em{' '}
            <b
              className={classes.pontilhado}
            >{`${empresa.cidade.toUpperCase()} - ${empresa.estado.toUpperCase()}`}</b>
            . EMITENTE{' '}
            <b className={classes.pontilhado}>{conta.nomeCli.toUpperCase()}</b>{' '}
            DATA DA EMISSÃO{' '}
            <b className={classes.pontilhado}>{formatData(conta.emissao)}</b>{' '}
            CPF/CNPJ{' '}
            <b className={classes.pontilhado}>
              {mask(conta.cpfCli, ['999.999.999-99'])}
            </b>{' '}
            ENDEREÇO{' '}
            {conta.Endereco ? (
              <b
                className={classes.pontilhado}
              >{`${conta.Endereco.toUpperCase()} ${conta.Numero} ${
                conta.Complemento
              }, ${conta.Bairro.toUpperCase()} - ${conta.Cidade.toUpperCase()}/${conta.Estado.toUpperCase()}`}</b>
            ) : (
              <b className={classes.pontilhado}>
                _________________________________________________
              </b>
            )}{' '}
            ASS. DO EMITENTE
            ____________________________________________________ .
          </p>
        </Grid>
      </Grid>
      <h3 className={classes.tituloAvalista}>Avalista(s)</h3>
      <Grid container spacing={2} p={1} className={classes.camposAss}>
        <Grid item xs={6}>
          <p>
            Nome:
            <div className={classes.linhaNome} />
          </p>
          <p>
            CPF/CNPJ:
            <div className={classes.linhaCPF} />
          </p>
          <p className={classes.linhaAss}>Assinatura do Avalista</p>
        </Grid>
        <Grid item xs={6}>
          <p>
            Nome: <div className={classes.linhaNome} />
          </p>
          <p>
            CPF/CNPJ:
            <div className={classes.linhaCPF} />
          </p>
          <p className={classes.linhaAss}>Assinatura do Avalista</p>
        </Grid>
      </Grid>
      <div id="promissoria-print">
        <Fab className={classes.fab} color="primary" onClick={() => imprimir()}>
          <PrintIcon />
        </Fab>
      </div>
    </div>
  );
}
