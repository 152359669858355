import React from 'react';
import { getData } from '../../utils/funcoes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  cor1: {
    backgroundColor: '#6c757d',
  },
  cor2: {
    backgroundColor: '#28a745',
  },
  cor3: {
    backgroundColor: '#ff8c00',
  },
  cor4: {
    backgroundColor: '#daa520',
  },
  cor5: {
    backgroundColor: '#ff0000',
  },
}));

export default function Agendamentos({ agendamentos }) {
  const classes = useStyles();

  const presenca = status => {
    if (['#6c757d', '', '0'].includes(status)) {
      return (
        <Tooltip title="CONFIRMAÇÃO PENDENTE" TransitionComponent={Zoom}>
          <Avatar className={classes.cor1}>PE</Avatar>
        </Tooltip>
      );
    }
    if (status === '#28a745') {
      return (
        <Tooltip title="CONFIRMADA" TransitionComponent={Zoom}>
          <Avatar className={classes.cor2}>CO</Avatar>
        </Tooltip>
      );
    }
    if (status === '#ff8c00') {
      return (
        <Tooltip title="DESMARCADA" TransitionComponent={Zoom}>
          <Avatar className={classes.cor3}>DE</Avatar>
        </Tooltip>
      );
    }
    if (status === '#daa520') {
      return (
        <Tooltip title="PACIENTE DESMARCOU" TransitionComponent={Zoom}>
          <Avatar className={classes.cor4}>PD</Avatar>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="FALTA" TransitionComponent={Zoom}>
          <Avatar className={classes.cor5}>FA</Avatar>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <b>ID</b>
              </TableCell>
              <TableCell>
                <b>Descrição</b>
              </TableCell>
              <TableCell>
                <b>Presença</b>
              </TableCell>
              <TableCell>
                <b>Data</b>
              </TableCell>
              <TableCell>
                <b>Hora Inicial</b>
              </TableCell>
              <TableCell>
                <b>Hora Final</b>
              </TableCell>
              <TableCell>
                <b>Dentista</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agendamentos.map(eventos => (
              <TableRow key={eventos.id}>
                <TableCell>{eventos.id}</TableCell>
                <TableCell>{eventos.title}</TableCell>
                <TableCell>{presenca(eventos.color)}</TableCell>
                <TableCell>{getData(eventos.start, 'data')}</TableCell>
                <TableCell>{getData(eventos.start, 'hora')}</TableCell>
                <TableCell>{getData(eventos.end, 'hora')}</TableCell>
                <TableCell>{eventos.nomeDentista}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
