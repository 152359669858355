import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { getClinica } from '../../../services/auth';

export default function InserirProcedimento({
  idOrcamento,
  listaProcedimentos,
  planoTratamento,
  setPlanoTratamento,
  idProcedimento,
  setIdProcedimento,
  valorProcedimento,
  modal,
  setModal,
  id,
  setId,
  dente,
  setDente,
  tipo,
  setTipo,
  face,
  setFace,
  estadoAtual,
  setEstadoAtual,
  abrirAviso,
  cor,
  pegarCor,
  paint,
  valor,
}) {
  const [state, setState] = useState({
    checkedV: false,
    checkedD: false,
    checkedLP: false,
    checkedM: false,
    checkedO: false,
    checkedI: false,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const incluirProcedimento = () => {
    let faces = '';
    faces += state.checkedV ? '+V' : '';
    faces += state.checkedD ? '+D' : '';
    faces += state.checkedLP ? '+L/P' : '';
    faces += state.checkedM ? '+M' : '';
    faces += state.checkedO ? '+O' : '';
    faces += state.checkedI ? '+I' : '';

    if (idProcedimento) {
      if (idOrcamento) {
        api
          .post('PlanoTratamento/save.php', {
            pdo: getClinica(),
            idTratamento: idOrcamento,
            procedimento: idProcedimento,
            idDente: id,
            numDente: dente,
            face: faces.substr(1),
            tipo: tipo,
            estadoAtual: estadoAtual,
            desconto: 0,
            valor: valorProcedimento(idProcedimento),
            status: 0,
          })
          .then(res => {
            abrirAviso('success', 'Procedimento inserido.');
            setPlanoTratamento([...planoTratamento, res.data]);
            setModal(false);
            limparCampos();
          });
      } else {
        const incluir = {
          id: id,
          dente: dente,
          tipo: tipo,
          face: cor !== 11 ? face : faces,
          estadoAtual: estadoAtual,
          idProcedimento: idProcedimento,
          valor: valorProcedimento(idProcedimento),
        };
        setPlanoTratamento([...planoTratamento, incluir]);
        limparCampos();
        abrirAviso('success', 'Procedimento inserido.');
        setModal(false);
      }
    } else {
      abrirAviso('warning', 'Preencha os campos obrigatórios.');
    }
  };

  const fecharModal = () => {
    // pegarCor(9);
    // paint(dente, valor);
    limparCampos();
    setModal(false);
  };

  const limparCampos = () => {
    setId('');
    setDente('');
    setFace('');
    setEstadoAtual('');
    setIdProcedimento('');
    setTipo('');
  };

  // useEffect(()=>{
  //     console.log(dente, tipo,id)
  // },[modal])

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Plano de tratamento'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {cor === 11 && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Estado atual do dente"
                  value={estadoAtual}
                  onChange={e => setEstadoAtual(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Plano de tratamento proposto"
                value={idProcedimento}
                onChange={e => setIdProcedimento(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                {listaProcedimentos.map(pro => (
                  <MenuItem key={pro.id} value={pro.id}>
                    {pro.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dente"
                value={dente}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              {cor !== 11 ? (
                <TextField
                  fullWidth
                  label="Face"
                  value={face}
                  variant="outlined"
                  disabled
                />
              ) : (
                <>
                  <b>Selecione a(s) face(s): </b>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedV"
                        color="primary"
                      />
                    }
                    label="V"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedD"
                        color="primary"
                      />
                    }
                    label="D"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedLP"
                        color="primary"
                      />
                    }
                    label="L/P"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedM}
                        onChange={handleChange}
                        name="checkedM"
                        color="primary"
                      />
                    }
                    label="M"
                  />
                  {(id >= 6 && id < 12) || (id >= 22 && id < 28) ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checkedI}
                          onChange={handleChange}
                          name="checkedI"
                          color="primary"
                        />
                      }
                      label="I"
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checkedO}
                          onChange={handleChange}
                          name="checkedO"
                          color="primary"
                        />
                      }
                      label="O"
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={incluirProcedimento}
            variant="contained"
            color="primary"
          >
            Inserir
          </Button>
          <Button variant="contained" color="default" onClick={fecharModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
