import React, { useState } from 'react';
import {
  MenuItem,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import api from '../../services/api';

export default function CadastrarEditarConvenio({
  fullScreen,
  modal,
  setModal,
  classes,
  nome,
  setNome,
  idConvenio,
  ativo,
  setAtivo,
  convenios,
  setConvenios,
  permissoes,
  configuracoes,
  getClinica,
  abrirAviso,
}) {
  const [desabilitar, setDesabilitar] = useState(false);

  const salvar = () => {
    if (idConvenio !== '') {
      alterarConvenio();
    } else {
      cadastrarConvenio();
    }
  };

  const cadastrarConvenio = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.convCad !== '0' && permissoes !== false) {
        if (nome !== '') {
          await api
            .post('Convenio/save.php', {
              pdo: getClinica(),
              nome: nome,
            })
            .then(res => {
              setConvenios([...convenios, res.data]);
              abrirAviso('success', 'Convênio cadastrado.');
              setDesabilitar(false);
              setModal(false);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const alterarConvenio = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.convAlt !== '0' && permissoes !== false) {
        if (nome !== '' && ativo !== '') {
          api
            .post('Convenio/update.php', {
              pdo: getClinica(),
              nome: nome,
              ativo: ativo,
              id: idConvenio,
            })
            .then(res => {
              setConvenios(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('success', 'Convênio alterado.');
              setDesabilitar(false);
              setModal(false);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal}
      onClose={() => setModal(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {idConvenio === '' ? 'Cadastrar Convênio' : 'Editar Convênio'}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome do convênio"
                value={nome}
                onChange={e => setNome(e.target.value)}
                variant="outlined"
              />
            </Grid>
            {idConvenio !== '' && (
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Status"
                  value={ativo}
                  onChange={e => setAtivo(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="0">Ativo</MenuItem>
                  <MenuItem value="-1">Inativo</MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={salvar}
          variant="contained"
          color="primary"
          disabled={desabilitar}
        >
          Salvar
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={() => setModal(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
