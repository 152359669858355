import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatData } from '../../utils/funcoes';
import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
  TextareaAutosize,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Notificacao from '../../utils/notificacao';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  button: {
    margin: theme.spacing(1),
    float: 'right',
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#3b8e3e',
    },
  },
  inputObs: {
    width: '500px',
  },
}));

export default function ObservacoesFicha({ idFicha, Paciente }) {
  const [disabilitar, setDesabilitar] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [observacoes, setObservacoes] = useState([]);
  const [observacao, setObservacao] = useState('');
  const [modalObs, setModalObs] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const atualizar = async () => {
    await api
      .get(`FichaObservacao/list.php?idFicha=${idFicha}&pdo=${getClinica()}`)
      .then(res => {
        res.data ? setObservacoes(res.data) : setObservacoes([]);
      })
      .catch(error => console.log(error));
  };

  const cadastrar = async () => {
    setDesabilitar(true);
    if (observacao) {
      await api
        .post('FichaObservacao/save.php', {
          pdo: getClinica(),
          idFichaAnamnese: idFicha,
          descricoes: observacao,
          idPaciente: Paciente.id,
        })
        .then(res => setObservacoes([...observacoes, res.data]))
        .catch(error => console.log(error));
      abrirAviso('success', 'Observação cadastrada.');
      setObservacao(null);
      setModalObs(false);
    } else {
      abrirAviso('warning', 'Informe a observação.');
      setDesabilitar(false);
    }
    setDesabilitar(false);
  };

  const modalCadastro = () => {
    setObservacao(null);
    setModalObs(true);
  };

  useEffect(() => {
    atualizar();
  }, [atualizar, idFicha]);

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={modalCadastro}
          startIcon={<AddCircleIcon />}
        >
          Inserir
        </Button>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Hora</b>
                </TableCell>
                <TableCell>
                  <b>Data</b>
                </TableCell>
                <TableCell>
                  <b>Descrições</b>
                </TableCell>
                {/* <TableCell><b>Ação</b></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {observacoes.map(observacao => {
                return (
                  <TableRow key={observacao.id}>
                    <TableCell>{observacao.hora}</TableCell>
                    <TableCell>{formatData(observacao.data)}</TableCell>
                    <TableCell>{observacao.descricoes}</TableCell>
                    {/* <TableCell>AÇÃO</TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        fullScreen={fullScreen}
        open={modalObs}
        onClose={() => setModalObs(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Inserir Observação'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextareaAutosize
                label="Observação"
                rowsMin={5}
                rowsMax={10}
                className={classes.inputObs}
                size="large"
                fullWidth
                value={observacao || ''}
                onChange={e => setObservacao(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={cadastrar}
            disabled={disabilitar}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalObs(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
