import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from './services/auth';
import Login from './pages/Login';
import Agenda from './pages/Agenda';
import Configuracoes from './pages/Configuracoes';
import MeusDados from './pages/MeusDados';
import Movimentacao from './pages/Financeiro/Movimentacao';
import ContasPagar from './pages/Financeiro/Pagar';
import ContasReceber from './pages/Financeiro/Receber';
import Empresa from './pages/Empresa';
import Pacientes from './pages/Pacientes';
import CadastarEditarPaciente from './pages/Pacientes/CadastrarEditar';
import Aniversariantes from './pages/Relatorios/aniversariantes';
import Convenios from './pages/Convenios';
import Estoque from './pages/Estoque';
import Feriados from './pages/Feriados';
import Procedimentos from './pages/Procedimentos';
import Recibo from './pages/Financeiro/Receber/recibo';
import CadastroOdonto from './pages/Orcamentos/Odontologico';
import Funcionarios from './pages/Funcionarios';
import CadastarEditarFuncionario from './pages/Funcionarios/CadastrarEditar';
import { PermissoesProvider } from './hooks/permissoes';
import { ConfigProvider } from './hooks/config';
import TratamentosOdontologicos from './pages/TratamentosOdontologicos';
import TratamentoOdontologico from './pages/TratamentosOdontologicos/tratamentoOdontologico';
import Atestado from './pages/Atestado';
import Receituario from './pages/Receituario';
import ImprimirAtestato from './pages/Atestado/imprimir';
import FichaPaciente from './pages/Financeiro/FichaPaciente';
import OrcamentosOdontologicos from './pages/Orcamentos/Odontologico/orcamentos';
import ImprimirOrcamento from './pages/Orcamentos/Odontologico/imprimir';
import Ortodontia from './pages/Ortodontia';
import ImprimirReceituario from './pages/Receituario/imprimir';
import TratamentoOrtodontico from './pages/Ortodontia/editar';
import EditarOrcamentoOdontologico from './pages/Orcamentos/Odontologico/editar';
import CadastroEstetico from './pages/Orcamentos/Estetico';
import OrcamentosEsteticos from './pages/Orcamentos/Estetico/orcamentos';
import TratamentosEsteticos from './pages/TratamentosEsteticos';
import TratamentoEstetico from './pages/TratamentosEsteticos/tratamentoEstetico';
import EditarOrcamentoEstetico from './pages/Orcamentos/Estetico/editar';
import Anexos from './pages/Anexos';
import ImprimirMovimentacao from './pages/Financeiro/Movimentacao/imprimirMovimentacao';
import Prontuario from './pages/Prontuario';
import RelatorioProducaoOdontologico from './pages/Relatorios/relatorioProducaoOdonto';
import RelatorioProducaoEstetico from './pages/Relatorios/relatorioProducaoEstetico';
import ImprimirRelatorioProducaoOdonto from './pages/Relatorios/imprimirRelProducaoOdonto';
import ImprimirRelatorioProducaoEstetico from './pages/Relatorios/imprimirRelProducaoEstetico';
import ImprimirRelatorioAniversariantes from './pages/Relatorios/imprimirRelAniversariantes';
import OSProtetico from './pages/OS';
import Promissoria from './pages/Financeiro/Receber/promissoria';
import CentroCusto from './pages/CentroCusto';
import ImprimirProcedimentos from './pages/Procedimentos/ImprimirProcedimentos';
import RelatorioFaturamentoSintetico from './pages/Relatorios/relatorioFatSintetico';
import ImprimirRelatorioFaturamentoSintetico from './pages/Relatorios/imprimirRelFatSintetico';
import { EmpresaProvider } from './hooks/empresa';
import Laboratorio from './pages/Laboratorios';
import ImprimirOrdemServico from './pages/OS/imprimir';
import ConfiguracaoWhatsApp from './pages/WhatsApp';
import RelatorioProducaoUnificado from './pages/Relatorios/relatorioProducaoUnificado';
import ImprimirRelatorioProducaoUnificado from './pages/Relatorios/imprimirRelProducaoUnificado';
import Taxas from './pages/Taxa';
import RelatorioFaturamentoSinteticoTaxa from './pages/Relatorios/relatorioFatSinteticoTaxa';
import ImprimirRelatorioFaturamentoSinteticoTaxa from './pages/Relatorios/imprimirRelFatSinteticoTaxa';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PermissoesProvider>
        <ConfigProvider>
          <EmpresaProvider>
            <PrivateRoute path="/app" component={Agenda} />
            <PrivateRoute path="/pacientes" component={Pacientes} />
            <PrivateRoute
              path="/cad_edit_paciente/:id"
              component={CadastarEditarPaciente}
            />
            <PrivateRoute path="/funcionarios" component={Funcionarios} />
            <PrivateRoute path="/os_protetico" component={OSProtetico} />
            <PrivateRoute
              path="/imprimir_os"
              component={ImprimirOrdemServico}
            />
            <PrivateRoute
              path="/cad_edit_funcionario"
              component={CadastarEditarFuncionario}
            />
            <PrivateRoute
              path="/cad_orcamento_odonto"
              component={CadastroOdonto}
            />
            <PrivateRoute
              path="/cad_orcamento_estetico"
              component={CadastroEstetico}
            />
            <PrivateRoute
              path="/orcamentos_odonto/:id"
              component={OrcamentosOdontologicos}
            />
            <PrivateRoute
              path="/orcamento_odonto/:orc"
              component={EditarOrcamentoOdontologico}
            />
            <PrivateRoute
              path="/orcamentos_esteticos/:id"
              component={OrcamentosEsteticos}
            />
            <PrivateRoute
              path="/orcamento_estetico/:orc"
              component={EditarOrcamentoEstetico}
            />
            <PrivateRoute path="/ortodontia/:id" component={Ortodontia} />
            <PrivateRoute
              path="/tratamento_orto/:trat"
              component={TratamentoOrtodontico}
            />
            <PrivateRoute
              path="/tratamentos_odonto/:id"
              component={TratamentosOdontologicos}
            />
            <PrivateRoute
              path="/tratamento_odonto"
              component={TratamentoOdontologico}
            />
            <PrivateRoute
              path="/tratamentos_esteticos/:id"
              component={TratamentosEsteticos}
            />
            <PrivateRoute
              path="/tratamento_estetico/:trat"
              component={TratamentoEstetico}
            />
            <PrivateRoute path="/procedimentos" component={Procedimentos} />
            <PrivateRoute path="/prontuario/:id" component={Prontuario} />
            <PrivateRoute path="/atestado/:id" component={Atestado} />
            <PrivateRoute path="/receituario/:id" component={Receituario} />
            <PrivateRoute
              path="/ficha_paciente/:id"
              component={FichaPaciente}
            />
            <PrivateRoute path="/anexos/:id" component={Anexos} />
            <PrivateRoute path="/contaspagar" component={ContasPagar} />
            <PrivateRoute path="/contasreceber" component={ContasReceber} />
            <PrivateRoute path="/movimentacao" component={Movimentacao} />
            <PrivateRoute path="/estoque" component={Estoque} />
            <PrivateRoute path="/convenios" component={Convenios} />
            <PrivateRoute
              path="/aniversariantesmes"
              component={Aniversariantes}
            />
            <PrivateRoute path="/configuracoes" component={Configuracoes} />
            <PrivateRoute path="/meusdados" component={MeusDados} />
            <PrivateRoute path="/empresa" component={Empresa} />
            <PrivateRoute path="/feriados" component={Feriados} />
            <PrivateRoute path="/centrocusto" component={CentroCusto} />
            <PrivateRoute path="/taxas" component={Taxas} />
            <PrivateRoute path="/laboratorio" component={Laboratorio} />
            <PrivateRoute
              path="/configuracao_whatsapp"
              component={ConfiguracaoWhatsApp}
            />

            <PrivateRoute
              path="/relatorio_prod_odonto"
              component={RelatorioProducaoOdontologico}
            />
            <PrivateRoute
              path="/relatorio_prod_estetico"
              component={RelatorioProducaoEstetico}
            />
            <PrivateRoute
              path="/relatorio_fat_sintetico"
              component={RelatorioFaturamentoSintetico}
            />
            <PrivateRoute
              path="/relatorio_fat_sintetico_taxa"
              component={RelatorioFaturamentoSinteticoTaxa}
            />
            <PrivateRoute
              path="/relatorio_prod_unificado"
              component={RelatorioProducaoUnificado}
            />
            <PrivateRoute
              path="/imprimir_aniversariantes"
              component={ImprimirRelatorioAniversariantes}
            />
            <PrivateRoute
              path="/imprimir_prod_odonto"
              component={ImprimirRelatorioProducaoOdonto}
            />
            <PrivateRoute
              path="/imprimir_prod_estetico"
              component={ImprimirRelatorioProducaoEstetico}
            />
            <PrivateRoute
              path="/imprimir_prod_unificado"
              component={ImprimirRelatorioProducaoUnificado}
            />
            <PrivateRoute
              path="/imprimir_orc_odonto/:orc"
              component={ImprimirOrcamento}
            />
            <PrivateRoute
              path="/imprimir_movimentacao"
              component={ImprimirMovimentacao}
            />
            <PrivateRoute path="/imprimir_recibo/:cod" component={Recibo} />
            <PrivateRoute
              path="/imprimir_promissoria/"
              component={Promissoria}
            />
            <PrivateRoute
              path="/imprimir_atestado/"
              component={ImprimirAtestato}
            />
            <PrivateRoute
              path="/imprimir_receituario"
              component={ImprimirReceituario}
            />
            <PrivateRoute
              path="/imprimir_procedimentos"
              component={ImprimirProcedimentos}
            />
            <PrivateRoute
              path="/imprimir_fat_sintetico"
              component={ImprimirRelatorioFaturamentoSintetico}
            />
            <PrivateRoute
              path="/imprimir_fat_sintetico_taxa"
              component={ImprimirRelatorioFaturamentoSinteticoTaxa}
            />
          </EmpresaProvider>
        </ConfigProvider>
      </PermissoesProvider>
      <Route path="*" component={() => <h1>Pagina não encotrada.</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
