import React from 'react';
import {
  Grid,
  TextField,
  Paper,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: '8px',
  },
}));

export default function HabitosDeleterios({
  hDDormeBocaAberta,
  setHDDormeBocaAberta,
  hDRonca,
  setHDRonca,
  hDBabaTravesseiro,
  setHDBabaTravesseiro,
  hDMordeObjetos,
  setHDMordeObjetos,
  hDMordeLabios,
  setHDMordeLabios,
  hDRangeDentes,
  setHDRangeDentes,
  hDRoerUnhas,
  setHDRoerUnhas,
  hDSuccaoDedos,
  setHDSuccaoDedos,
  hDQtAnosAbandSuccDedos,
  setHDQtAnosAbandSuccDedos,
  hDChupeta,
  setHDChupeta,
  hDQtAnosAbandChupeta,
  setHDQtAnosAbandChupeta,
  hDMamadeira,
  setHDMamadeira,
  hDQtAnosAbandMamadeira,
  setHDQtAnosAbandMamadeira,
}) {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hDDormeBocaAberta}
              control={<Checkbox color="primary" />}
              onChange={() => setHDDormeBocaAberta(!hDDormeBocaAberta)}
              label="Dorme com a boca aberta"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hDRonca}
              control={<Checkbox color="primary" />}
              onChange={() => setHDRonca(!hDRonca)}
              label="Ronca"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hDBabaTravesseiro}
              control={<Checkbox color="primary" />}
              onChange={() => setHDBabaTravesseiro(!hDBabaTravesseiro)}
              label="Baba no travesseiro"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hDMordeObjetos}
              control={<Checkbox color="primary" />}
              onChange={() => setHDMordeObjetos(!hDMordeObjetos)}
              label="Morde objetos"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hDMordeLabios}
              control={<Checkbox color="primary" />}
              onChange={() => setHDMordeLabios(!hDMordeLabios)}
              label="Morde os lábios"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hDRangeDentes}
              control={<Checkbox color="primary" />}
              onChange={() => setHDRangeDentes(!hDRangeDentes)}
              label="Range os dentes"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hDRoerUnhas}
              control={<Checkbox color="primary" />}
              onChange={() => setHDRoerUnhas(!hDRoerUnhas)}
              label="Roer Unhas"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={hDSuccaoDedos}
              control={<Checkbox color="primary" />}
              onChange={() => setHDSuccaoDedos(!hDSuccaoDedos)}
              label="Sucção de Dedos"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Abandonou com quantos anos?"
              fullWidth
              value={hDQtAnosAbandSuccDedos}
              onChange={e => setHDQtAnosAbandSuccDedos(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={hDChupeta}
              control={<Checkbox color="primary" />}
              onChange={() => setHDChupeta(!hDChupeta)}
              label="Sucção de Chupeta"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Abandonou com quantos anos?"
              fullWidth
              value={hDQtAnosAbandChupeta}
              onChange={e => setHDQtAnosAbandChupeta(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              checked={hDMamadeira}
              control={<Checkbox color="primary" />}
              onChange={() => setHDMamadeira(!hDMamadeira)}
              label="Mamadeira"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Abandonou com quantos anos?"
              fullWidth
              value={hDQtAnosAbandMamadeira}
              onChange={e => setHDQtAnosAbandMamadeira(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
