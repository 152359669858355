import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import ReactQuill from 'react-quill';
import Notificacao from '../../utils/notificacao';
import MenuOpcoes from '../Pacientes/menuOpcoes';
import { getClinica } from '../../services/auth';
import {
  Box,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  MenuItem,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import PrintIcon from '@material-ui/icons/Print';
import { usePermissoes } from '../../hooks/permissoes';
import api from '../../services/api';
import { AddCircle } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import CadastrarEditarReceituario from './CadastrarEditarReceituario';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  espaco: {
    marginLeft: '6px',
  },
  selectDentista: {
    marginTop: '60px',
    margin: theme.spacing(1),
    // marginRight: 40,
  },
  titulos: {
    margin: '8px 0px',
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
  containerAtestado: {
    marginTop: theme.spacing(2),
  },
  atestadoText: {
    padding: theme.spacing(2),
  },
  textoReceituario: {
    paddingLeft: 'auto',
    paddingRight: 'auto',
  },
}));

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['clean'],
  ],
};

const formats = [
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
  'color',
  'background',
];

const padrao =
  '<p class="ql-align-center"><strong class="ql-size-large"><u>VIA ORAL</u></strong></p><p><br></p><p><span class="ql-size-large">Amoxicilina 500 mg ________________________________ 1 caixa</span></p><p><span class="ql-size-large">Tomar 1 (uma) cápsula de 8h em 8hs, durante 7 (sete) dias.</span></p><p><br></p><p><span class="ql-size-large">Amoxicilina 600 mg ________________________________ 1 caixa</span></p><p><span class="ql-size-large">Tomar 1 (um) comprimido de 8h em 8hs, durante 5 (cinco) dias.</span></p><p><br></p><p><span class="ql-size-large">Dipirona Sólida 500 mg _____________________________ 1 caixa</span></p><p><span class="ql-size-large">Tomar 1 (um) comprimido de 6h em 6hs, durante 2 (dois) dias.</span></p>';

export default function Receituario(props) {
  const { permissoes } = usePermissoes();
  const Paciente = JSON.parse(atob(props.match.params.id));
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [idDentista, setIdDentista] = useState(null);
  const [dentistas, setDentistas] = useState([]);
  const [editorHtml, setEditorHtml] = useState('');
  const [timbrado, setTrimbrado] = useState(false);
  const [receituario, setReceituario] = useState(0);
  const [receituarios, setReceituarios] = useState([]);
  const [modal, setModal] = useState(false);

  const classes = useStyles();

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const imprimir = () => {
    if (permissoes.pacRec !== '0' && permissoes !== false) {
      if (idDentista) {
        let codText = JSON.stringify({
          texto: editorHtml,
          timbrado: timbrado,
          nome: Paciente.nome,
          idDentista: idDentista,
        });
        sessionStorage.setItem('receituario', codText);
        window.open(
          '/imprimir_receituario/',
          'Receituario',
          'height=700,width=800',
        );
        sessionStorage.removeItem('receituario');
      } else {
        abrirAviso('warning', 'Selecione o dentista.');
      }
    } else {
      abrirAviso('warning', 'Você não tem permissão para realizar essa ação.');
    }
  };

  const atualizar = async () => {
    await api
      .get('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setDentistas(res.data);
      });
    await api
      .post('Receituario/search.php', {
        pdo: getClinica(),
        consulta: ' AND status=1',
      })
      .then(res => {
        res.data.length > 0 ? setReceituarios(res.data) : setReceituarios([]);
      })
      .catch(error => console.log(error));
    setEditorHtml(padrao);
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    receituario ? setEditorHtml(receituario.texto) : setEditorHtml(padrao);
  }, [receituario]);

  return (
    <div>
      <Menu titulo={`Pacientes ► ${Paciente.nome} ► Receituário`} />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <div className={classes.menuOpcoes}>
          <MenuOpcoes paciente={Paciente} />
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            label="Receituario"
            fullWidth
            value={receituario}
            onChange={e => setReceituario(e.target.value)}
            variant="outlined"
          >
            <MenuItem value={0} selected>
              Padrão
            </MenuItem>
            {receituarios.map(receituario => (
              <MenuItem key={receituario.id} value={receituario} selected>
                {receituario.titulo}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {Boolean(receituario) && (
          <Button
            variant="contained"
            title="Editar Receituário Selecionado"
            color="primary"
            size="small"
            className={classes.espaco}
            onClick={() => {
              setModal(true);
            }}
          >
            <CreateIcon />
          </Button>
        )}
        <Grid item xs={4} className={classes.espaco}>
          <TextField
            select
            size="small"
            label="Profissional (obrigatório)"
            fullWidth
            value={idDentista}
            onChange={e => setIdDentista(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="" selected>
              Selecione
            </MenuItem>
            {dentistas.map(dentista => (
              <MenuItem key={dentista.id} value={dentista.id} selected>
                {dentista.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          className={classes.espaco}
          onClick={imprimir}
          startIcon={<PrintIcon />}
        >
          Imprimir
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.espaco}
          onClick={() => {
            setModal(true);
            setReceituario(0);
          }}
          startIcon={<AddCircle />}
        >
          Cadastrar
        </Button>
      </Box>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        className={classes.textoReceituario}
        onChange={e => setEditorHtml(e)}
        value={editorHtml}
      />
      <Grid item xs={2}>
        <FormControlLabel
          value={timbrado}
          control={<Checkbox color="primary" />}
          onChange={e => setTrimbrado(!timbrado)}
          label="A4 Timbrado"
          labelPlacement="end"
        />
      </Grid>
      {modal && (
        <CadastrarEditarReceituario
          modal={modal}
          fecharModal={() => setModal(false)}
          receituario={receituario}
          receituarios={receituarios}
          setReceituarios={setReceituarios}
          abrirAviso={abrirAviso}
          modules={modules}
          formats={formats}
          padrao={editorHtml}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
