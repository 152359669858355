import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { getClinica } from '../services/auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  imagem: {
    width: '100%',
    height: '160px',
  },
  container: {
    margin: 'auto',
  },
  cabecalho01: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
  },
  dadosEmpresa: {
    textAlign: 'center',
    margin: 'auto',
  },
}));

export default function Cabecalho() {
  const [empresa, setEmpresa] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    api.get('Empresa/list.php?pdo=' + getClinica()).then(res => {
      setEmpresa(res.data[0]);
    });
  }, []);

  return (
    <>
      {empresa && (
        <div className={classes.container}>
          {empresa.logoCabecalho === null && (
            <div className={classes.cabecalho01}>
              {empresa.logo !== null && (
                <div>
                  <img src={empresa.logo} height="100" width="100" />
                </div>
              )}
              <div className={classes.dadosEmpresa}>
                <h3>{empresa.nomeFantasia}</h3>
                <p>
                  <i>
                    {empresa.logradouro}, {empresa.numero} {empresa.complemento}{' '}
                    - {empresa.bairro} - {empresa.cidade} - {empresa.estado}
                  </i>
                </p>
                <p style={{ marginTop: -15 }}>
                  <i>
                    Contato: {empresa.telefone}
                    {empresa.telefone2 !== '' && (
                      <i>/{empresa.telefone2}</i>
                    )}{' '}
                    {empresa.email !== '' && <i> - Email: {empresa.email}</i>}{' '}
                  </i>
                </p>
              </div>
            </div>
          )}
          {empresa.logoCabecalho !== null && (
            <div align="center">
              <img src={empresa.logoCabecalho} className={classes.imagem} />
            </div>
          )}
        </div>
      )}
    </>
  );
}
