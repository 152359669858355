import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatData, getData } from '../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpcoes from '../Pacientes/menuOpcoes';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CadastroOrto from './cadastro';
import Notificacao from '../../utils/notificacao';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Avatar from '@material-ui/core/Avatar';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
  amarelo: {
    backgroundColor: '#FFC107',
  },
  verde: {
    backgroundColor: '#4caf50',
  },
  vermelho: {
    backgroundColor: '#FF0000',
  },
}));

export default function Ortodontia() {
  const { id } = useParams();
  const Paciente = JSON.parse(atob(id));
  const [loading, setLoading] = useState(true);
  const [tratamentos, setTratamentos] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .post('Ortodontia/search.php', {
        pdo: getClinica(),
        consulta: ` AND idCliente=${Paciente.id}`,
      })
      .then(res => {
        res.data ? setTratamentos(res.data) : setTratamentos([]);
      });
    setLoading(false);
  };

  const abrirOrto = tratamento => {
    let orc = btoa(JSON.stringify(tratamento));
    window.location.pathname = `/tratamento_orto/${orc}`;
  };

  const situacaoTratamento = valor => {
    if (valor === '0') {
      return (
        <Tooltip title="EM ANDAMENTO" TransitionComponent={Zoom}>
          <Avatar className={classes.amarelo}>EA</Avatar>
        </Tooltip>
      );
    }
    if (valor === '1') {
      return (
        <Tooltip title="CONCLUÍDO" TransitionComponent={Zoom}>
          <Avatar className={classes.verde}>CO</Avatar>
        </Tooltip>
      );
    }
    if (valor === '2') {
      return (
        <Tooltip title="CANCELADO" TransitionComponent={Zoom}>
          <Avatar className={classes.vermelho}>CA</Avatar>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <Menu titulo={`Pacientes ► ${Paciente.nome} ► Ortodontia`} />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setOpenModal(true)}
          startIcon={<AddCircleIcon />}
        >
          Cadastrar
        </Button>
        <div className={classes.menuOpcoes}>
          <MenuOpcoes paciente={Paciente} />
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Nº Tratamento</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Cadastro</b>
                  </TableCell>
                  <TableCell>
                    <b>Hora Cadastro</b>
                  </TableCell>
                  <TableCell>
                    <b>Dentista</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Inicial</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Final</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opção</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tratamentos
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(tratamento => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={tratamento.id}
                      >
                        <TableCell>{tratamento.id}</TableCell>
                        <TableCell>
                          {getData(tratamento.dataCriacao, 'data')}
                        </TableCell>
                        <TableCell>
                          {getData(tratamento.dataCriacao, 'hora')}
                        </TableCell>
                        <TableCell>{tratamento.Dentista}</TableCell>
                        <TableCell>
                          {formatData(tratamento.dataInicial)}
                        </TableCell>
                        <TableCell>
                          {formatData(tratamento.dataFinal)}
                        </TableCell>
                        <TableCell>
                          {situacaoTratamento(tratamento.status)}
                        </TableCell>
                        <TableCell>
                          <div>
                            <Button
                              startIcon={<OpenInNewIcon />}
                              variant="contained"
                              color="primary"
                              onClick={() => abrirOrto(tratamento)}
                            >
                              Abrir
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={tratamentos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
      <CadastroOrto
        IdPaciente={Paciente.id}
        modal={openModal}
        setOpenModal={setOpenModal}
        setTratamentos={setTratamentos}
        tratamentos={tratamentos}
        abrirAviso={abrirAviso}
        fecharAviso={fecharAviso}
      />
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
