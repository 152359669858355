import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  opcoes: {
    marginBottom: '0px',
    marginTop: '50px',
    width: '100%',
  },
  buttonOpcoes: {
    marginTop: '15px',
    marginRight: '8px',
  },
  buttonAlmagma: {
    backgroundColor: '#dc3545',
    '&:hover': {
      backgroundColor: '#b52c39',
    },
    color: '#FFFF',
    margin: theme.spacing(1),
  },
  buttonCaries: {
    backgroundColor: '#ffff00',
    '&:hover': {
      backgroundColor: '#caca00',
    },
    color: '#000000',
    margin: theme.spacing(1),
  },
  buttonEndodontia: {
    backgroundColor: '#ffa500',
    '&:hover': {
      backgroundColor: '#d38c07',
    },
    color: '#000000',
    margin: theme.spacing(1),
  },
  buttonAusente: {
    backgroundColor: '#ff6347',
    '&:hover': {
      backgroundColor: '#d5523b',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonResina: {
    backgroundColor: '#cc6600',
    '&:hover': {
      backgroundColor: '#a25202',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonImplante: {
    backgroundColor: '#cc66cc',
    '&:hover': {
      backgroundColor: '#9d499d',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonSelante: {
    backgroundColor: '#018001',
    '&:hover': {
      backgroundColor: '#016801',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonCoroa: {
    backgroundColor: '#0000ff',
    '&:hover': {
      backgroundColor: '#0303c7',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonOutros: {
    backgroundColor: '#04CF17',
    '&:hover': {
      backgroundColor: '#049d13',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonTodos: {
    backgroundColor: '#17a2b8',
    '&:hover': {
      backgroundColor: '#127e8f',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonLimpar: {
    margin: theme.spacing(1),
  },
  InputsOrcamento: {
    margin: theme.spacing(1),
    width: '320px',
  },
}));
