import React, { useState, useEffect } from 'react';
import Cabecalho from '../../utils/cabecalho';
import Rodape from '../../utils/rodape';
import api from '../../services/api';
import { pegarDataCidade } from '../../utils/funcoes';
import { getClinica } from '../../services/auth';
import { CircularProgress, makeStyles, Fab } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { mask } from 'remask';

const useStyles = makeStyles(theme => ({
  containerAtestado: {
    marginTop: '100px',
    padding: '5px 25px',
  },
  containerAtestadoTrimbrado: {
    marginTop: '220px',
    padding: '5px 25px',
  },
  titulo: {
    marginTop: '30px',
    textAlign: 'center',
  },
  textoAtestado: {
    textAlign: 'justifiy',
    fontSize: '18px',
    lineHeight: '28px',
  },
  assinaturaText: {
    marginTop: '100px',
    textAlign: 'center',
  },
  dentistaNome: {
    marginTop: '-10px',
    fontSize: '18px',
  },
  dentistaProfissao: {
    marginTop: '-25px',
    fontSize: '18px',
  },
  dentistaCro: {
    marginTop: '-25px',
    fontSize: '18px',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(1.5),
  },
  footer: {
    // marginTop: '5rem',
    position: 'fixed',
    width: '100%',
    height: '6%',
    bottom: '0',
  },
}));

export default function ImprimirAtestato() {
  const Dados = JSON.parse(sessionStorage.getItem('atestado'));
  const [loading, setLoading] = useState(true);
  const [dentista, setDentista] = useState('');
  const [empresa, setEmpresa] = useState('');
  const classes = useStyles();

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .get('Usuario/find.php?id=' + Dados.idDentista + '&pdo=' + getClinica())
      .then(res => {
        setDentista(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .get('Empresa/list.php?pdo=' + getClinica())
      .then(res => {
        setEmpresa(res.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  const imprimir = () => {
    window.document.getElementById('button-print').style.display = 'none';
    window.print();
    window.document.getElementById('button-print').style.display = 'block';
    window.close();
  };

  return (
    <>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <>
          {!Dados.timbrado && <Cabecalho />}
          <div
            className={
              !Dados.timbrado
                ? classes.containerAtestado
                : classes.containerAtestadoTrimbrado
            }
          >
            <h1 className={classes.titulo}>Atestado de Comparecimento</h1>
            <br />
            <br />

            <div id="atestadoImpressao" className={classes.textoAtestado}>
              <p align="justify">{Dados.texto}</p>
              <br />
              <p>
                CID: <b>{Dados.cid}</b>
              </p>
            </div>
            <p>{pegarDataCidade(empresa)}</p>
          </div>
          <div className={classes.assinaturaText} id="dados-dentista">
            <p>_____________________________________________________________</p>
            <h5 className={classes.dentistaNome}>{dentista.nome}</h5>
            <h5 className={classes.dentistaProfissao}>{dentista.profissao}</h5>
            {dentista.tipo === '1' && dentista.cro && (
              <h5 className={classes.dentistaCro}>CRO: {dentista.cro}</h5>
            )}
            {dentista.tipo === '3' && dentista.crm && (
              <h5 className={classes.dentistaCro}>CRM: {dentista.crm}</h5>
            )}
            {dentista.rqe && (
              <h5 className={classes.dentistaCro}>RQE: {dentista.rqe}</h5>
            )}
          </div>
          <Fab
            color="primary"
            size="large"
            id="button-print"
            className={classes.fab}
            onClick={imprimir}
          >
            <PrintIcon />
          </Fab>
          <div className={classes.footer}>{!Dados.timbrado && <Rodape />}</div>
        </>
      )}
    </>
  );
}
