import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { formatData } from '../../utils/funcoes';
import { getClinica } from '../../services/auth';
import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginBottom: '10px',
  },
  button: {
    margin: theme.spacing(1),
    marginTop: '80px',
  },
}));

export default function Aniversariantes() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pacientes, setPacientes] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .get('Relatorios/birthdayMonth.php?pdo=' + getClinica())
      .then(res => {
        setPacientes(res.data);
      })
      .catch(error => console.log(error));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const imprimir = () => {
    const reg = { registros: JSON.stringify(pacientes) };
    sessionStorage.setItem('relNiver', btoa(JSON.stringify(reg)));
    window.open(
      '/imprimir_aniversariantes',
      'Aniversáriantes',
      'height=900,width=800',
    );
    sessionStorage.removeItem('relNiver');
  };

  return (
    <div>
      <Menu titulo="Relatórios ► Aniversariantes" />
      <div>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.location.reload()}
          startIcon={<RefreshIcon />}
        >
          Atualizar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={imprimir}
          startIcon={<PrintIcon />}
        >
          Imprimir
        </Button>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Nome</b>
                </TableCell>
                <TableCell>
                  <b>Nascimento</b>
                </TableCell>
                <TableCell>
                  <b>Sexo</b>
                </TableCell>
                <TableCell>
                  <b>Telefone</b>
                </TableCell>
                <TableCell>
                  <b>Celular</b>
                </TableCell>
                <TableCell>
                  <b>Convênio</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pacientes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(paciente => {
                  return (
                    <TableRow key={paciente.id}>
                      <TableCell>{paciente.nome}</TableCell>
                      <TableCell>{formatData(paciente.dataNasc)}</TableCell>
                      <TableCell>
                        {paciente.sexo === 'f' ? 'Feminino' : 'Masculino'}
                      </TableCell>
                      <TableCell>{paciente.telefone}</TableCell>
                      <TableCell>{paciente.celular}</TableCell>
                      <TableCell>{paciente.convenio}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={pacientes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por pagina"
        />
      </Paper>
    </div>
  );
}
