import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { DialogContentText } from '@material-ui/core';
import { listaFormasPagamento } from '../../utils/funcoes';

export default function CadastrarProcedimento({
  IdOrto,
  IdPaciente,
  idDentista,
  openModal,
  setOpenModal,
  planosTratamento,
  setPlanosTratamentos,
  abrirAviso,
  atualizarLista,
}) {
  const [procedimentos, setProcedimentos] = useState([]);
  const [idProcedimento, setIdProcedimento] = useState(null);
  const [desabilitar, setDesabilitar] = useState(false);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState(null);
  const [formaPag, setFormaPag] = useState(null);
  const [valor, setValor] = useState(null);
  const [qtdMeses, setQtdMeses] = useState(1);
  const [cicloMensal, setCicloMensal] = useState(0);
  const [vencimento, setVecimento] = useState(null);
  const [horaInicial, setHoraInicial] = useState(null);
  const [horaFinal, setHoraFinal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .post('Procedimento/search.php', {
        pdo: getClinica(),
        consulta: ' AND ativo=0 AND tipo=1',
      })
      .then(res => setProcedimentos(res.data));
    await api
      .post('CentroCusto/search.php', {
        pdo: getClinica(),
        consulta: 'AND ativo=1',
      })
      .then(res => {
        res.data ? setCentrosCusto(res.data) : setCentrosCusto([]);
      })
      .catch(error => console.log(error));
  };

  const onChangeProcedimento = e => {
    setIdProcedimento(e);
    setValor(e.valor);
  };

  const validacaoCampos = () => {
    if (idProcedimento && vencimento && horaInicial && horaFinal) {
      return true;
    }
    return false;
  };

  const salvar = async () => {
    if (validacaoCampos()) {
      if (qtdMeses > 0 && cicloMensal >= 0) {
        setModalConfirm(true);
      } else {
        abrirAviso(
          'warning',
          'Quantidade de meses ou ciclo mensal deve ser maior que 0',
        );
      }
    } else {
      abrirAviso('warning', 'Preencha os campos obrigatórios.');
    }
  };

  const confirm = async () => {
    setDesabilitar(true);
    await api
      .post('PlanoOrtodontia/save.php', {
        pdo: getClinica(),
        idOrto: IdOrto,
        idProcedimento: idProcedimento.id,
        centroCusto: centroCusto,
        valor: valor,
        status: '0',
        vencimento: vencimento,
        idDentista: idDentista,
        idPaciente: IdPaciente,
        idUsuario: getToken(),
        qtdMeses: qtdMeses,
        ciclo: cicloMensal,
        horaInicio: horaInicial,
        horaFim: horaFinal,
        formaPag,
      })
      .then(() => {
        setModalConfirm(false);
        setOpenModal(false);
        abrirAviso('success', 'Procedimentos cadastrados.');
        atualizarLista();
      });
    setTimeout(function () {
      window.location.reload();
    }, 2100);
    setDesabilitar(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{'Adicionar Procedimento'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                select
                label="Procedimento (obrigatório)"
                fullWidth
                value={idProcedimento}
                onChange={e => onChangeProcedimento(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="">Selecione...</MenuItem>
                {procedimentos.map(p => (
                  <MenuItem key={p.id} value={p}>
                    {p.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Centro de Custo"
                value={centroCusto}
                onChange={e => setCentroCusto(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                {centrosCusto.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                label="Valor"
                fullWidth
                value={valor}
                onChange={e => {
                  if (e.target.value >= 0) {
                    setValor(e.target.value);
                  }
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="Vencimento (obrigatório)"
                fullWidth
                value={vencimento}
                onChange={e => setVecimento(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                label="Qtd vezes"
                fullWidth
                value={qtdMeses}
                onChange={e => setQtdMeses(e.target.value)}
                variant="outlined"
              />
            </Grid>
            {qtdMeses > 1 && (
              <Grid item xs={6}>
                <TextField
                  type="number"
                  min={1}
                  label="Ciclo mensal (Dias)"
                  fullWidth
                  value={cicloMensal}
                  onChange={e => setCicloMensal(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Forma de Pagamento"
                value={formaPag}
                onChange={e => setFormaPag(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                {listaFormasPagamento.map(pg => (
                  <MenuItem value={pg.value}>{pg.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <h4>{'Horário do(s) procedimento(s)'}</h4>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                type="time"
                label="Hora Inicial (obrigatório)"
                fullWidth
                value={horaInicial}
                onChange={e => setHoraInicial(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="time"
                label="Hora Final (obrigatório)"
                fullWidth
                value={horaFinal}
                onChange={e => setHoraFinal(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={salvar} variant="contained" color="primary">
            Salvar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setOpenModal(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {modalConfirm && (
        <Dialog
          fullScreen={fullScreen}
          open={modalConfirm}
          onClose={() => setModalConfirm(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Confirmar cadastro de procedimentos'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja confirmar os lançamentos informados?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={confirm}
              variant="contained"
              color="primary"
              disabled={desabilitar}
            >
              Sim
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => setModalConfirm(false)}
            >
              Não
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
