import React, { useState, useEffect } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import './style.css';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { usePermissoes } from '../../../hooks/permissoes';
import InserirProcedimento from './inserirProcedimento';
import InserirProcedimentosTodos from './inserirProcedimentoTodos';
import TabelaProcedimentos from './tabelaPlanoTratamento';
import Notificacao from '../../../utils/notificacao';
import { useConfig } from '../../../hooks/config';
import { useStyles } from './styles';
import { Autocomplete } from '@material-ui/lab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function CadastroOdonto() {
  const { permissoes } = usePermissoes();
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [adultoCima, setAdultoCima] = useState([]);
  const [adultoBaixo, setAdultoBaixo] = useState([]);
  const [criancaCima, setCriancaCima] = useState([]);
  const [criancaBaixo, setCriancaBaixo] = useState([]);
  const [procedimentos, setProcedimentos] = useState([]);
  const [cor, setCor] = useState('');
  const [idProcedimento, setIdProcedimento] = useState('');
  const [planoTratamento, setPlanoTratamento] = useState([]);
  const [arcadaDentaria, setArcadaDentaria] = useState('');
  const [tipoArcada, setTipoArcada] = useState('');
  const [id, setId] = useState('');
  const [dente, setDente] = useState('');
  const [tipo, setTipo] = useState('');
  const [face, setFace] = useState('');
  const [valor, setValor] = useState('');
  const [estadoAtual, setEstadoAtual] = useState('');
  const [total, setTotal] = useState(0);
  const [tipoDesc, setTipoDesc] = useState('0');
  const [totalFinal, setTotalFinal] = useState(total);
  const [ratear, setRatear] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [dentistas, setDentistas] = useState([]);
  const [idDentista, setIdDentista] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [idCliente, setIdCliente] = useState(null);
  const { configuracoes } = useConfig();
  const [modalDente, setModalDente] = useState(false);
  const [modalTodos, setModalTodos] = useState(false);

  const [value, setValue] = useState(0);
  const classes = useStyles();

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const selecionarAba = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    soma();
  }, [planoTratamento]);

  const atualizar = async () => {
    carregarDentes();
    await api
      .get('Procedimento/list.php?pdo=' + getClinica() + '&tipo=1')
      .then(res => {
        setProcedimentos(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .get('Usuario/list_select.php?pdo=' + getClinica())
      .then(res => {
        setClientes(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .post('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setDentistas(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  const carregarDentes = () => {
    let AdCima = [],
      AdBaixo = [],
      CrCima = [],
      CrBaixo = [];
    for (let i = 1; i < 17; i++) {
      let dente;
      if (i <= 8) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoCima',
          numDente: 19 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdCima.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoCima',
          numDente: i + 12,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdCima.push(dente);
      }
    }
    for (let i = 17; i < 33; i++) {
      let dente;
      if (i <= 24) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoBaixo',
          numDente: 65 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdBaixo.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoBaixo',
          numDente: i + 6,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdBaixo.push(dente);
      }
    }
    for (let i = 33; i < 43; i++) {
      let dente;
      if (i <= 37) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaCima',
          numDente: 88 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrCima.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaCima',
          numDente: 23 + i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrCima.push(dente);
      }
    }
    for (let i = 43; i < 53; i++) {
      let dente;
      if (i <= 47) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaBaixo',
          numDente: 128 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrBaixo.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaBaixo',
          numDente: i + 23,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrBaixo.push(dente);
      }
    }
    setAdultoCima(AdCima);
    setAdultoBaixo(AdBaixo);
    setCriancaCima(CrCima);
    setCriancaBaixo(CrBaixo);
  };

  const soma = () => {
    let soma = planoTratamento.reduce((sum, x) => sum + parseFloat(x.valor), 0);
    setTotal(soma);
    setTotalFinal(soma);
  };

  const valorProcedimento = id => {
    let valor = 0;
    procedimentos.map(procedimento => {
      if (procedimento.id == id) {
        valor = procedimento.valor;
      }
    });
    return valor;
  };

  const cadastrar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.orcCad !== '0' && permissoes !== false) {
        if (idCliente?.id && idDentista?.id) {
          if (planoTratamento.length > 0) {
            await api
              .post('Tratamento/save.php', {
                pdo: getClinica(),
                idDentista: idDentista.id,
                idCliente: idCliente.id,
                planosTratamento: planoTratamento,
                ratear: ratear,
                refTratamento: null,
              })
              .then(res => {
                abrirAviso('success', `Orçamento Nº${res.data} cadastrado.`);
                setTimeout(function () {
                  window.location.reload();
                }, 3000);
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            abrirAviso('warning', 'Procedimentos não cadastrados.');
            setDesabilitar(false);
          }
        } else {
          abrirAviso('warning', 'Paciente ou Dentista não informado.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const pegarCor = e => {
    setCor(e);
  };

  const paint = (dente, valor) => {
    setValor(valor);
    if (cor !== '') {
      if (dente.tipo === 'adultoCima') {
        adultoCimaPaint(dente, valor);
      } else if (dente.tipo === 'adultoBaixo') {
        adultoBaixoPaint(dente, valor);
      } else if (dente.tipo === 'criancaCima') {
        criancaCimaPaint(dente, valor);
      } else if (dente.tipo === 'criancaBaixo') {
        criancaBaixoPaint(dente, valor);
      }
    } else {
      abrirAviso('info', 'Selecione o estado atual do dente.');
    }
  };

  const adultoCimaPaint = (dente, valor) => {
    adultoCima[dente.id - 1].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoVermelho';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoVermelho';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoVermelho';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Amálgama');
        }
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoAmarelo';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoAmarelo';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoAmarelo';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Cáries');
        }
      }
    } else if (cor === 3) {
      adultoCima[dente.id - 1].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      adultoCima[dente.id - 1].class6 = 'marcadoTomate';
      adultoCima[dente.id - 1].class7 = 'marcadoTomate';

      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoMarrom';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoMarrom';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoMarrom';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Resina');
        }
      }
    } else if (cor === 6) {
      adultoCima[dente.id - 1].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoVerde';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoVerde';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoVerde';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Selante');
        }
      }
    } else if (cor === 8) {
      adultoCima[dente.id - 1].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      adultoCima[dente.id - 1].class1 = 'dente';
      adultoCima[dente.id - 1].class2 = 'dente';
      adultoCima[dente.id - 1].class3 = 'dente';
      adultoCima[dente.id - 1].class4 = 'dente';
      adultoCima[dente.id - 1].class5 = 'dente';
      adultoCima[dente.id - 1].class6 = 'ausente';
      adultoCima[dente.id - 1].class7 = 'ausente';
      adultoCima[dente.id - 1].class8 = 'coroa';
      adultoCima[dente.id - 1].class9 = 'endodoncia';
      adultoCima[dente.id - 1].class10 = 'implante';
      adultoCima[dente.id - 1].alterou = 0;
    } else if (cor === 11) {
      adultoCima[dente.id - 1].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setAdultoCima([...adultoCima]);
  };

  const adultoBaixoPaint = (dente, valor) => {
    adultoBaixo[dente.id - 17].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoVermelho';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoVermelho';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoVermelho';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Amálgama');
        }
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoAmarelo';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoAmarelo';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoAmarelo';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Cáries');
        }
      }
    } else if (cor === 3) {
      adultoBaixo[dente.id - 17].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      adultoBaixo[dente.id - 17].class6 = 'marcadoTomate';
      adultoBaixo[dente.id - 17].class7 = 'marcadoTomate';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoMarrom';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoMarrom';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoMarrom';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Resina');
        }
      }
    } else if (cor === 6) {
      adultoBaixo[dente.id - 17].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoVerde';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoVerde';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoVerde';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Selante');
        }
      }
    } else if (cor === 8) {
      adultoBaixo[dente.id - 17].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      adultoBaixo[dente.id - 17].class1 = 'dente';
      adultoBaixo[dente.id - 17].class2 = 'dente';
      adultoBaixo[dente.id - 17].class3 = 'dente';
      adultoBaixo[dente.id - 17].class4 = 'dente';
      adultoBaixo[dente.id - 17].class5 = 'dente';
      adultoBaixo[dente.id - 17].class6 = 'ausente';
      adultoBaixo[dente.id - 17].class7 = 'ausente';
      adultoBaixo[dente.id - 17].class8 = 'coroa';
      adultoBaixo[dente.id - 17].class10 = 'implante';
      adultoBaixo[dente.id - 17].class9 = 'endodoncia';
      adultoBaixo[dente.id - 17].alterou = 0;
    } else if (cor === 11) {
      adultoBaixo[dente.id - 17].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setAdultoBaixo([...adultoBaixo]);
  };

  const criancaCimaPaint = (dente, valor) => {
    criancaCima[dente.id - 33].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoVermelho';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoVermelho';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Amálgama');
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoAmarelo';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoAmarelo';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Cáries');
      }
    } else if (cor === 3) {
      criancaCima[dente.id - 33].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      criancaCima[dente.id - 33].class6 = 'marcadoTomate';
      criancaCima[dente.id - 33].class7 = 'marcadoTomate';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoMarrom';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoMarrom';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Resina');
      }
    } else if (cor === 6) {
      criancaCima[dente.id - 33].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoVerde';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoVerde';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Selante');
      }
    } else if (cor === 8) {
      criancaCima[dente.id - 33].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      criancaCima[dente.id - 33].class1 = 'dente';
      criancaCima[dente.id - 33].class2 = 'dente';
      criancaCima[dente.id - 33].class3 = 'dente';
      criancaCima[dente.id - 33].class4 = 'dente';
      criancaCima[dente.id - 33].class5 = 'dente';
      criancaCima[dente.id - 33].class6 = 'ausente';
      criancaCima[dente.id - 33].class7 = 'ausente';
      criancaCima[dente.id - 33].class8 = 'coroa';
      criancaCima[dente.id - 33].class10 = 'implante';
      criancaCima[dente.id - 33].class9 = 'endodoncia';
      criancaCima[dente.id - 33].alterou = 0;
    } else if (cor === 11) {
      criancaCima[dente.id - 33].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setCriancaCima([...criancaCima]);
  };

  const criancaBaixoPaint = (dente, valor) => {
    criancaBaixo[dente.id - 43].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoVermelho';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoVermelho';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Amálgama');
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoAmarelo';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoAmarelo';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Cáries');
      }
    } else if (cor === 3) {
      criancaBaixo[dente.id - 43].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      criancaBaixo[dente.id - 43].class6 = 'marcadoTomate';
      criancaBaixo[dente.id - 43].class7 = 'marcadoTomate';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoMarrom';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoMarrom';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Resina');
      }
    } else if (cor === 6) {
      criancaBaixo[dente.id - 43].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoVerde';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoVerde';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Selante');
      }
    } else if (cor === 8) {
      criancaBaixo[dente.id - 43].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      criancaBaixo[dente.id - 43].class1 = 'dente';
      criancaBaixo[dente.id - 43].class2 = 'dente';
      criancaBaixo[dente.id - 43].class3 = 'dente';
      criancaBaixo[dente.id - 43].class4 = 'dente';
      criancaBaixo[dente.id - 43].class5 = 'dente';
      criancaBaixo[dente.id - 43].class6 = 'ausente';
      criancaBaixo[dente.id - 43].class7 = 'ausente';
      criancaBaixo[dente.id - 43].class8 = 'coroa';
      criancaBaixo[dente.id - 43].class10 = 'implante';
      criancaBaixo[dente.id - 43].class9 = 'endodoncia';
      criancaBaixo[dente.id - 43].alterou = 0;
    } else if (cor === 11) {
      criancaBaixo[dente.id - 43].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setCriancaBaixo([...criancaBaixo]);
  };

  return (
    <>
      <Menu titulo="Orçamento ► Odontológico" />
      <div className={classes.opcoes}>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box p={2} width="100%">
            <Autocomplete
              size="small"
              options={clientes}
              getOptionLabel={option => option.nome}
              onChange={(event, newValue) => {
                setIdCliente(newValue);
              }}
              renderInput={params => (
                <TextField
                  className={classes.InputsOrcamento}
                  {...params}
                  label="Paciente"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box p={2} width="100%">
            <Autocomplete
              size="small"
              options={dentistas}
              getOptionLabel={option => option.nome}
              onChange={(event, newValue) => {
                setIdDentista(newValue);
              }}
              renderInput={params => (
                <TextField
                  className={classes.InputsOrcamento}
                  {...params}
                  label="Dentista"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box p={1} flexShrink={0}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonOpcoes}
              onClick={cadastrar}
              startIcon={<AddCircleIcon />}
              disabled={desabilitar}
            >
              Cadastrar
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.buttonOpcoes}
              onClick={() => window.location.reload()}
              startIcon={<CancelIcon />}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={selecionarAba}
              variant="fullWidth"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Odontograma" {...a11yProps(0)} />
              <Tab label="Plano de Tratamento" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <center>
              <div>
                {adultoCima.map(dente => (
                  <svg key={dente.id} height="50" width="50" id={dente.id}>
                    <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                      {dente.numDente}
                    </text>
                    <polygon
                      points="10,15 15,10 50,45 45,50"
                      className={dente.class6}
                      onClick={() => paint(dente, 6)}
                    />
                    <polygon
                      points="45,10 50,15 15,50 10,45"
                      className={dente.class7}
                      onClick={() => paint(dente, 7)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="16"
                      className={dente.class8}
                      onClick={() => paint(dente, 8)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="20"
                      className={dente.class9}
                      onClick={() => paint(dente, 9)}
                    />
                    <polygon
                      points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                      className={dente.class10}
                      onClick={() => paint(dente, 10)}
                    />
                    <polygon
                      points="10,10 50,10 40,20 20,20"
                      className={dente.class1}
                      onClick={() => paint(dente, 1)}
                    />
                    <polygon
                      points="50,10 50,50 40,40 40,20"
                      className={dente.class2}
                      onClick={() => paint(dente, 2)}
                    />
                    <polygon
                      points="50,50 10,50 20,40 40,40"
                      className={dente.class3}
                      onClick={() => paint(dente, 3)}
                    />
                    <polygon
                      points="10,50 20,40 20,20 10,10"
                      className={dente.class4}
                      onClick={() => paint(dente, 4)}
                    />
                    <polygon
                      points="20,20 40,20 40,40 20,40"
                      className={dente.class5}
                      onClick={() => paint(dente, 5)}
                    />
                  </svg>
                ))}
              </div>
              <br />
              <div>
                {criancaCima.map(dente => (
                  <svg key={dente.id} height="50" width="50" id={dente.id}>
                    <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                      {dente.numDente}
                    </text>
                    <polygon
                      points="10,15 15,10 50,45 45,50"
                      className={dente.class6}
                      onClick={() => paint(dente, 6)}
                    />
                    <polygon
                      points="45,10 50,15 15,50 10,45"
                      className={dente.class7}
                      onClick={() => paint(dente, 7)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="16"
                      className={dente.class8}
                      onClick={() => paint(dente, 8)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="20"
                      className={dente.class9}
                      onClick={() => paint(dente, 9)}
                    />
                    <polygon
                      points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                      className={dente.class10}
                      onClick={() => paint(dente, 10)}
                    />
                    <polygon
                      points="10,10 50,10 40,20 20,20"
                      className={dente.class1}
                      onClick={() => paint(dente, 1)}
                    />
                    <polygon
                      points="50,10 50,50 40,40 40,20"
                      className={dente.class2}
                      onClick={() => paint(dente, 2)}
                    />
                    <polygon
                      points="50,50 10,50 20,40 40,40"
                      className={dente.class3}
                      onClick={() => paint(dente, 3)}
                    />
                    <polygon
                      points="10,50 20,40 20,20 10,10"
                      className={dente.class4}
                      onClick={() => paint(dente, 4)}
                    />
                    <polygon
                      points="20,20 40,20 40,40 20,40"
                      className={dente.class5}
                      onClick={() => paint(dente, 5)}
                    />
                  </svg>
                ))}
              </div>
              <div>
                {criancaBaixo.map(dente => (
                  <svg key={dente.id} height="50" width="50" id={dente.id}>
                    <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                      {dente.numDente}
                    </text>
                    <polygon
                      points="10,15 15,10 50,45 45,50"
                      className={dente.class6}
                      onClick={() => paint(dente, 6)}
                    />
                    <polygon
                      points="45,10 50,15 15,50 10,45"
                      className={dente.class7}
                      onClick={() => paint(dente, 7)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="16"
                      className={dente.class8}
                      onClick={() => paint(dente, 8)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="20"
                      className={dente.class9}
                      onClick={() => paint(dente, 9)}
                    />
                    <polygon
                      points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                      className={dente.class10}
                      onClick={() => paint(dente, 10)}
                    />
                    <polygon
                      points="10,10 50,10 40,20 20,20"
                      className={dente.class1}
                      onClick={() => paint(dente, 1)}
                    />
                    <polygon
                      points="50,10 50,50 40,40 40,20"
                      className={dente.class2}
                      onClick={() => paint(dente, 2)}
                    />
                    <polygon
                      points="50,50 10,50 20,40 40,40"
                      className={dente.class3}
                      onClick={() => paint(dente, 3)}
                    />
                    <polygon
                      points="10,50 20,40 20,20 10,10"
                      className={dente.class4}
                      onClick={() => paint(dente, 4)}
                    />
                    <polygon
                      points="20,20 40,20 40,40 20,40"
                      className={dente.class5}
                      onClick={() => paint(dente, 5)}
                    />
                  </svg>
                ))}
              </div>
              <br />
              <div>
                {adultoBaixo.map(dente => (
                  <svg key={dente.id} height="50" width="50" id={dente.id}>
                    <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                      {dente.numDente}
                    </text>
                    <polygon
                      points="10,15 15,10 50,45 45,50"
                      className={dente.class6}
                      onClick={() => paint(dente, 6)}
                    />
                    <polygon
                      points="45,10 50,15 15,50 10,45"
                      className={dente.class7}
                      onClick={() => paint(dente, 7)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="16"
                      className={dente.class8}
                      onClick={() => paint(dente, 8)}
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="20"
                      className={dente.class9}
                      onClick={() => paint(dente, 9)}
                    />
                    <polygon
                      points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                      className={dente.class10}
                      onClick={() => paint(dente, 10)}
                    />
                    <polygon
                      points="10,10 50,10 40,20 20,20"
                      className={dente.class1}
                      onClick={() => paint(dente, 1)}
                    />
                    <polygon
                      points="50,10 50,50 40,40 40,20"
                      className={dente.class2}
                      onClick={() => paint(dente, 2)}
                    />
                    <polygon
                      points="50,50 10,50 20,40 40,40"
                      className={dente.class3}
                      onClick={() => paint(dente, 3)}
                    />
                    <polygon
                      points="10,50 20,40 20,20 10,10"
                      className={dente.class4}
                      onClick={() => paint(dente, 4)}
                    />
                    <polygon
                      points="20,20 40,20 40,40 20,40"
                      className={dente.class5}
                      onClick={() => paint(dente, 5)}
                    />
                  </svg>
                ))}
              </div>
            </center>
            <div style={{ width: '100%' }}>
              <Box display="flex" justifyContent="center">
                <Button
                  onClick={() => pegarCor(1)}
                  variant="contained"
                  className={classes.buttonAlmagma}
                >
                  Amálgama
                </Button>
                <Button
                  onClick={() => pegarCor(2)}
                  variant="contained"
                  className={classes.buttonCaries}
                >
                  Cáries
                </Button>
                <Button
                  onClick={() => pegarCor(3)}
                  variant="contained"
                  className={classes.buttonEndodontia}
                >
                  Endodontia
                </Button>
                <Button
                  onClick={() => pegarCor(4)}
                  variant="contained"
                  className={classes.buttonAusente}
                >
                  Ausente
                </Button>
                <Button
                  onClick={() => pegarCor(5)}
                  variant="contained"
                  className={classes.buttonResina}
                >
                  Resina
                </Button>
                <Button
                  onClick={() => pegarCor(6)}
                  variant="contained"
                  className={classes.buttonImplante}
                >
                  Implante
                </Button>
                <Button
                  onClick={() => pegarCor(7)}
                  variant="contained"
                  className={classes.buttonSelante}
                >
                  Selante
                </Button>
                <Button
                  onClick={() => pegarCor(8)}
                  variant="contained"
                  className={classes.buttonCoroa}
                >
                  Coroa
                </Button>
                <Button
                  onClick={() => pegarCor(11)}
                  variant="contained"
                  className={classes.buttonOutros}
                >
                  Outros
                </Button>
                <Button
                  variant="contained"
                  className={classes.buttonTodos}
                  onClick={() => setModalTodos(true)}
                >
                  Todos
                </Button>
                <Button
                  onClick={() => pegarCor(9)}
                  variant="contained"
                  className={classes.buttonLimpar}
                  color="default"
                >
                  Limpar
                </Button>
              </Box>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabelaProcedimentos
              idOrcamento={null}
              planoTratamento={planoTratamento}
              setPlanoTratamento={setPlanoTratamento}
              listaProcedimentos={procedimentos}
              abrirAviso={abrirAviso}
              tipoDesc={tipoDesc}
              setTipoDesc={setTipoDesc}
              total={total}
              setTotal={setTotal}
              totalFinal={totalFinal}
              setTotalFinal={setTotalFinal}
              desconto={desconto}
              setDesconto={setDesconto}
              ratear={ratear}
              setRatear={setRatear}
            />
          </TabPanel>
        </div>
      )}
      {modalDente && (
        <InserirProcedimento
          idOrcamento={null}
          listaProcedimentos={procedimentos}
          planoTratamento={planoTratamento}
          setPlanoTratamento={setPlanoTratamento}
          idProcedimento={idProcedimento}
          setIdProcedimento={setIdProcedimento}
          valorProcedimento={valorProcedimento}
          modal={modalDente}
          setModal={setModalDente}
          id={id}
          setId={setId}
          dente={dente}
          setDente={setDente}
          tipo={tipo}
          setTipo={setTipo}
          face={face}
          setFace={setFace}
          estadoAtual={estadoAtual}
          setEstadoAtual={setEstadoAtual}
          abrirAviso={abrirAviso}
          cor={cor}
          pegarCor={pegarCor}
          paint={paint}
          valor={valor}
        />
      )}
      {modalTodos && (
        <InserirProcedimentosTodos
          idOrcamento={null}
          listaProcedimentos={procedimentos}
          planoTratamento={planoTratamento}
          setPlanoTratamento={setPlanoTratamento}
          idProcedimento={idProcedimento}
          setIdProcedimento={setIdProcedimento}
          valorProcedimento={valorProcedimento}
          modal={modalTodos}
          setModal={setModalTodos}
          tipoArcada={tipoArcada}
          setTipoArcada={setTipoArcada}
          arcadaDentaria={arcadaDentaria}
          setArcadaDentaria={setArcadaDentaria}
          estadoAtual={estadoAtual}
          setEstadoAtual={setEstadoAtual}
          abrirAviso={abrirAviso}
          adultoCima={adultoCima}
          setAdultoCima={setAdultoCima}
          adultoBaixo={adultoBaixo}
          setAdultoBaixo={setAdultoBaixo}
          criancaCima={criancaCima}
          setCriancaCima={setCriancaCima}
          criancaBaixo={criancaBaixo}
          setCriancaBaixo={setCriancaBaixo}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
