import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { getClinica, getToken } from '../../../services/auth';
import api from '../../../services/api';

export default function CancelarConta({
  modal,
  fecharModal,
  fecharModalConta,
  conta,
  setContas,
  configuracoes,
  permissoes,
  abrirAviso,
}) {
  const [desabilitar, setDesabilitar] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const desativarAtivarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (
        permissoes.finDes !== '0' &&
        permissoes.finAlt !== '0' &&
        permissoes !== false
      ) {
        let acao = 0;
        if (conta.status === '0') {
          acao = -1;
        }
        await api
          .post('Receber/chargeBack.php', {
            pdo: getClinica(),
            status: acao,
            userUltAlteracao: getToken(),
            id: conta.id,
          })
          .then(res => {
            abrirAviso('info', 'Conta alterada.');
            setContas(state =>
              state.map(e => {
                if (e.id !== res.data.id) {
                  return e;
                }
                e = res.data;
                return e;
              }),
            );
            fecharModal();
            fecharModalConta();
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal}
      onClose={fecharModal}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {conta.status === '1' ? 'Estornar conta' : 'Desativar conta'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {conta.status === '1'
            ? 'Tem certeza que deseja estornar conta Nº'
            : 'Tem certeza que deseja desativar conta Nº'}{' '}
          {conta.id} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={desativarAtivarConta}
          disabled={desabilitar}
          variant="contained"
          color="primary"
        >
          Sim
        </Button>
        <Button variant="contained" color="secondary" onClick={fecharModal}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
}
