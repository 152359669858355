import React, { useState } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { formatData } from '../../utils/funcoes';
import DeleteIcon from '@material-ui/icons/Delete';

export default function InfoAnexo({
  modal,
  setModal,
  imagem,
  imagens,
  setImagens,
  abrirAviso,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalDeletar, setModalDeletar] = useState(false);

  const deletarAnexo = async () => {
    await api
      .post('Anexo/remove.php', {
        pdo: getClinica(),
        anexo: imagem,
      })
      .then(res => {
        if (res.status === 200) {
          setImagens([...imagens.filter(x => x.id !== imagem.id)]);
          setModalDeletar(false);
          setModal(false);
          abrirAviso('success', 'Anexo removido.');
        }
      })
      .catch(error => console.log(error));
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{imagem.name}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography gutterBottom>
              <b>Upado por:</b> {imagem.usuario} <br />
              <b>Data:</b> {formatData(imagem.date)} <br />
              <b>Hora:</b> {imagem.time} <br />
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalDeletar(true)}
          >
            <DeleteIcon />
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModal(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={modalDeletar}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Deletar anexo`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja deletar o anexo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={deletarAnexo}>
            Sim
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalDeletar(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
