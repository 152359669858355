import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  TextField,
  Avatar,
  Box,
  Button,
  MenuItem,
} from '@material-ui/core';
import MenuOpcoes from './menuOpcoes';
import RefreshIcon from '@material-ui/icons/Refresh';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  box: {
    marginTop: '68px',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  button: {
    margin: theme.spacing(1),
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
}));

export default function Pacientes() {
  const [loading, setLoading] = useState(true);
  const [buscar, setBuscar] = useState('');
  const [pacientes, setPacientes] = useState([]);
  const [idPac, setIdPac] = useState('');
  const [status, setStatus] = useState('u.ativo=0');
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    listarClientes('AND u.tipo=0 AND u.ativo=0 ORDER BY u.nome ASC');
  };

  const filtrar = () => {
    let consulta = ' AND u.id!=1 AND u.tipo=0';
    if (status !== '' && status !== ' ') {
      consulta += ` AND ${status}`;
    }

    consulta += ' ORDER BY u.nome ASC';
    listarClientes(consulta);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const listarClientes = async consulta => {
    setPacientes([]);
    setPage(0);
    await api
      .post('Usuario/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        res.data.length > 0 ? setPacientes(res.data) : setPacientes([]);
      });
    setLoading(false);
  };

  const cadastarPac = () => {
    let paciente = null;
    let cod = btoa(JSON.stringify(paciente));
    window.location.pathname = '/cad_edit_paciente/' + cod;
  };

  const filtro = pacientes.filter(p => {
    return (
      p.nome.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      p.cpf.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      p.id.toLowerCase().indexOf(buscar.toLowerCase()) !== -1
    );
  });

  return (
    <div>
      <Menu titulo="Pacientes" />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
        className={classes.box}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => cadastarPac()}
          startIcon={<AddCircleIcon />}
        >
          Cadastrar
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.location.reload()}
          startIcon={<RefreshIcon />}
        >
          Atualizar
        </Button>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value=" ">Todos</MenuItem>
            <MenuItem value="u.ativo=0">Ativo</MenuItem>
            <MenuItem value="u.ativo=-1">Inativo</MenuItem>
          </TextField>
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>CPF</b>
                  </TableCell>
                  <TableCell>
                    <b>RG</b>
                  </TableCell>
                  <TableCell>
                    <b>Nome</b>
                  </TableCell>
                  <TableCell>
                    <b>Telefone</b>
                  </TableCell>
                  <TableCell>
                    <b>Celular</b>
                  </TableCell>
                  <TableCell>
                    <b>Ativo</b>
                  </TableCell>
                  <TableCell>
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(paciente => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={paciente.id}
                      >
                        <TableCell>{paciente.id}</TableCell>
                        <TableCell>{paciente.cpf}</TableCell>
                        <TableCell>{paciente.rg}</TableCell>
                        <TableCell>{paciente.nome}</TableCell>
                        <TableCell>{paciente.telefone}</TableCell>
                        <TableCell>{paciente.celular}</TableCell>
                        <TableCell>
                          {paciente.ativo === '0' ? (
                            <Avatar className={classes.ativo} title="Ativo">
                              <CheckCircleIcon />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.inativo} title="Inativo">
                              <CancelIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell>
                          <MenuOpcoes paciente={paciente} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={pacientes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
    </div>
  );
}
