import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import Menu2 from '../Menu';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  box: {
    marginTop: '65px',
  },
  container: {
    maxHeight: 500,
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
    margin: theme.spacing(1),
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
}));

export default function Funcionarios() {
  const [loading, setLoading] = useState(true);
  const [buscar, setBuscar] = useState('');
  const [funcionarios, setFuncionarios] = useState([]);
  const [status, setStatus] = useState('u.ativo=0');
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const abrirDadosFunc = funcionario => {
    let cod = btoa(JSON.stringify(funcionario));
    sessionStorage.setItem('funcionario', cod);
    window.location.pathname = '/cad_edit_funcionario';
  };

  const cadastarFunc = () => {
    sessionStorage.setItem('funcionario', btoa(JSON.stringify(null)));
    window.location.pathname = '/cad_edit_funcionario';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filtrar = () => {
    let consulta = ' AND u.id!=1 AND u.tipo>0';
    if (status !== '' && status !== ' ') {
      consulta += ` AND ${status}`;
    }

    consulta += ' ORDER BY u.nome ASC';
    listarFuncionarios(consulta);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    listarFuncionarios(
      'AND u.id>1 AND u.tipo>0 AND u.ativo=0 ORDER BY u.nome ASC',
    );
  };

  const listarFuncionarios = async consulta => {
    setFuncionarios([]);
    setPage(0);
    await api
      .post('Usuario/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        res.data.length > 0 ? setFuncionarios(res.data) : setFuncionarios([]);
      });
    setLoading(false);
  };

  const filtro = funcionarios.filter(p => {
    return (
      p.nome.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      p.cpf.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      p.id.toLowerCase().indexOf(buscar.toLowerCase()) !== -1
    );
  });

  return (
    <div>
      <Menu2 titulo="Funcionários" />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
        className={classes.box}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => cadastarFunc()}
          startIcon={<AddCircleIcon />}
        >
          Cadastrar
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.location.reload()}
          startIcon={<RefreshIcon />}
        >
          Atualizar
        </Button>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value=" ">Todos</MenuItem>
            <MenuItem value="u.ativo=0">Ativo</MenuItem>
            <MenuItem value="u.ativo=-1">Inativo</MenuItem>
          </TextField>
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>CPF</b>
                  </TableCell>
                  <TableCell>
                    <b>Nome</b>
                  </TableCell>
                  <TableCell>
                    <b>Login</b>
                  </TableCell>
                  <TableCell>
                    <b>Tipo</b>
                  </TableCell>
                  <TableCell>
                    <b>Ativo</b>
                  </TableCell>
                  <TableCell>
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(funcionario => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={funcionario.id}
                      >
                        <TableCell>{funcionario.id}</TableCell>
                        <TableCell>{funcionario.cpf}</TableCell>
                        <TableCell>{funcionario.nome}</TableCell>
                        <TableCell>{funcionario.login}</TableCell>
                        <TableCell>
                          {funcionario.tipo === '1'
                            ? 'Dentista'
                            : funcionario.tipo === '3'
                            ? 'Médico(a)'
                            : 'Outros'}
                        </TableCell>
                        <TableCell>
                          {funcionario.ativo === '0' ? (
                            <Avatar className={classes.ativo} title="Ativo">
                              <CheckCircleIcon />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.inativo} title="Inativo">
                              <CancelIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            <Button
                              startIcon={<AccountCircleIcon />}
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => abrirDadosFunc(funcionario)}
                            >
                              Abrir
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={funcionarios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
    </div>
  );
}
