import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { makeStyles } from '@material-ui/core/styles';
import { mask, unMask } from 'remask';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import { usePermissoes } from '../../hooks/permissoes';
import Notificacao from '../../utils/notificacao';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
  },
  imgLogo: {
    marginLeft: '200px',
    borderRadius: '15px',
  },
  imgCabecalho: {
    marginLeft: '50px',
    borderRadius: '15px',
  },
}));

export default function Empresa() {
  const [loading, setLoading] = useState(true);
  const { permissoes } = usePermissoes();
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [existeEmpresa, setExisteEmpresa] = useState(null);
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [razao, setRazao] = useState('');
  const [fantasia, setFantasia] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [complemento, setComplemento] = useState('');
  const [email, setEmail] = useState('');
  const [logoCabecalho, setLogoCabecalho] = useState(null);
  const [logo, setLogo] = useState(null);
  const [modalExcluir, setModalExcluir] = useState(false);
  const [valorIMG, setValorIMG] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [boleto, setBoleto] = useState(0);
  const [credito, setCredito] = useState(0);
  const [debito, setDebito] = useState(0);
  const [cheque, setCheque] = useState(0);
  const [insumosTerceiros, setInsumosTerceiros] = useState(false);
  const [prontuarioMedico, setProntuarioMedico] = useState(false);

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const classes = useStyles();

  const consultarCep = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep)
      .then(res => {
        setCep(res.data.cep);
        setLogradouro(res.data.logradouro);
        setBairro(res.data.bairro);
        setCidade(res.data.cidade);
        setEstado(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const atualizar = async () => {
    await api
      .get('Empresa/list.php?pdo=' + getClinica())
      .then(res => {
        setExisteEmpresa(res.data.length);
        if (res.data.length > 0) {
          setCpfCnpj(res.data[0].cnpj);
          setRazao(res.data[0].razaoSocial);
          setFantasia(res.data[0].nomeFantasia);
          setTelefone(res.data[0].telefone);
          setTelefone2(res.data[0].telefone2);
          setCep(res.data[0].cep);
          setLogradouro(res.data[0].logradouro);
          setNumero(res.data[0].numero);
          setBairro(res.data[0].bairro);
          setCidade(res.data[0].cidade);
          setEstado(res.data[0].estado);
          setComplemento(res.data[0].complemento);
          setEmail(res.data[0].email);
          setLogo(res.data[0].logo);
          setLogoCabecalho(res.data[0].logoCabecalho);
          setInsumosTerceiros(Boolean(parseInt(res.data[0].insumosTerceiros)));
          setProntuarioMedico(Boolean(parseInt(res.data[0].prontuarioMedico)));
          if (res.data[0].taxas) {
            const Taxas = JSON.parse(res.data[0].taxas);
            setBoleto(Taxas.boleto);
            setCredito(Taxas.credito);
            setDebito(Taxas.debito);
            setCheque(Taxas.cheque);
          }
        }
      })
      .catch(error => console.log(error));
    setLoading(false);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const salvarDados = async () => {
    let funcao;
    setDesabilitar(true);
    if (permissoes.empAlt !== '0' && permissoes !== false) {
      if (existeEmpresa === 0) {
        funcao = 'save.php';
      } else {
        funcao = 'update.php';
      }
      if (validarCampos()) {
        await api
          .post('Empresa/' + funcao, {
            pdo: getClinica(),
            cnpj: cpfCnpj,
            razao: razao,
            fantasia: fantasia,
            cep: cep,
            logradouro: logradouro,
            numero: numero,
            bairro: bairro,
            cidade: cidade,
            estado: estado,
            complemento: complemento,
            telefone: telefone,
            telefone2: telefone2,
            email: email,
            logo: logo,
            logoCabecalho: logoCabecalho,
            taxas: JSON.stringify({
              boleto: boleto,
              credito: credito,
              debito: debito,
              cheque: cheque,
            }),
            insumosTerceiros: Number(insumosTerceiros),
            prontuarioMedico: Number(prontuarioMedico),
          })
          .then(() => {
            abrirAviso('success', 'Dados salvos.');
            atualizar();
            setDesabilitar(false);
          });
      } else {
        abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      setDesabilitar(false);
    }
  };

  const validarCampos = () => {
    if (
      cpfCnpj !== '' &&
      razao !== '' &&
      fantasia !== '' &&
      telefone !== '' &&
      cep !== ''
    ) {
      return true;
    }
    return false;
  };

  const pegarLogo = e => {
    let file = e.target.files[0];
    let reader = new FileReader();
    if (file.size < 100000) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLogo(reader.result);
      };
    } else {
      abrirAviso('warning', 'Arquivo excedeu o tamanho limite.');
    }
  };

  const pegarLogoCabecalho = e => {
    let file = e.target.files[0];
    let reader = new FileReader();
    if (file.size < 200000) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLogoCabecalho(reader.result);
      };
    } else {
      abrirAviso('warning', 'Arquivo excedeu o tamanho limite.');
    }
  };

  const confirmaExclusao = valor => {
    if (valor === 'logo') {
      setModalExcluir(true);
      setValorIMG('1');
    } else {
      setModalExcluir(true);
      setValorIMG('2');
    }
  };

  const excluirImagem = () => {
    if (valorIMG === '1') {
      setLogo(null);
    } else {
      setLogoCabecalho(null);
    }
    setModalExcluir(false);
  };

  return (
    <div>
      <Menu titulo="Dados da empresa" />
      <Box
        display="flex"
        className={classes.opcoes}
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={salvarDados}
            startIcon={<SaveIcon />}
            disabled={desabilitar}
          >
            {' '}
            Salvar
          </Button>
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="CPF/CNPJ (obrigatório)"
                value={cpfCnpj}
                onChange={e =>
                  setCpfCnpj(
                    mask(unMask(e.target.value), [
                      '999.999.999-99',
                      '99.999.999/9999-99',
                    ]),
                  )
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Razão Social (obrigatório)"
                value={razao}
                onChange={e => setRazao(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Nome Fantasia (obrigatório)"
                value={fantasia}
                onChange={e => setFantasia(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Telefone (obrigatório)"
                value={telefone}
                onChange={e =>
                  setTelefone(mask(unMask(e.target.value), ['(99)9999-9999']))
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Celular"
                value={telefone2}
                onChange={e =>
                  setTelefone2(
                    mask(unMask(e.target.value), [
                      '(99)99999-9999',
                      '(99)9999-9999',
                    ]),
                  )
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="E-mail"
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <h3> Endereço</h3>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor="cep">Cep (obrigatório)</InputLabel>
                <OutlinedInput
                  id="cep"
                  type="text"
                  value={cep}
                  onChange={e =>
                    setCep(mask(unMask(e.target.value), ['99999999']))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => consultarCep()} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Logradouro"
                size="small"
                fullWidth
                value={logradouro}
                onChange={e => setLogradouro(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Número"
                size="small"
                fullWidth
                value={numero}
                onChange={e => setNumero(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Bairro"
                size="small"
                fullWidth
                value={bairro}
                onChange={e => setBairro(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Cidade"
                size="small"
                fullWidth
                value={cidade}
                onChange={e => setCidade(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Estado"
                size="small"
                fullWidth
                value={estado}
                onChange={e => setEstado(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complemento"
                size="small"
                fullWidth
                value={complemento}
                onChange={e => setComplemento(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <h3>Logos e Cabeçalhos</h3>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Logo (100 x 100)"
                accept="image/png, image/jpeg"
                size="small"
                type="file"
                fullWidth
                onChange={e => pegarLogo(e)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="IMG de cabeçalho (1300 x 250)"
                size="small"
                type="file"
                fullWidth
                onChange={e => pegarLogoCabecalho(e)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              {logo !== null && (
                <div>
                  <img
                    src={logo}
                    className={classes.imgLogo}
                    alt="Logo"
                    height="100"
                    width="100"
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    title="Remover"
                    className={classes.button}
                    onClick={() => confirmaExclusao('logo')}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              {logoCabecalho !== null && (
                <div>
                  <img
                    src={logoCabecalho}
                    className={classes.imgCabecalho}
                    alt="Cabeçalho"
                    height="100"
                    width="400"
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    title="Remover"
                    className={classes.button}
                    onClick={() => confirmaExclusao('cabecalho')}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          <h3>Taxas de pagamentos</h3>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField
                label="Boleto (R$)"
                size="small"
                type="number"
                fullWidth
                value={boleto}
                onChange={e => {
                  if (e.target.value >= 0) {
                    setBoleto(e.target.value);
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Cartão Crédito (%)"
                size="small"
                type="number"
                fullWidth
                value={credito}
                onChange={e => {
                  if (e.target.value >= 0) {
                    setCredito(e.target.value);
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Cartão Débito (%)"
                size="small"
                type="number"
                fullWidth
                value={debito}
                onChange={e => {
                  if (e.target.value >= 0) {
                    setDebito(e.target.value);
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Cheque (%)"
                size="small"
                type="number"
                fullWidth
                value={cheque}
                onChange={e => {
                  if (e.target.value >= 0) {
                    setCheque(e.target.value);
                  }
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <h3>Parâmetros</h3>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <FormControlLabel
                checked={insumosTerceiros}
                control={<Checkbox color="primary" />}
                onChange={() => setInsumosTerceiros(!insumosTerceiros)}
                label="Insumos de terceiros"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                checked={prontuarioMedico}
                control={<Checkbox color="primary" />}
                onChange={() => setProntuarioMedico(!prontuarioMedico)}
                label="Prontuário Médico"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </div>
      )}

      {/* confirmação de exclusão de imagem */}
      <Dialog
        fullScreen={fullScreen}
        open={modalExcluir}
        onClose={() => setModalExcluir(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Deseja remover essa imagem?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Para remover a imagem clique em Sim e logo em seguida SALVAR.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={excluirImagem} variant="contained" color="primary">
            Sim
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalExcluir(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
