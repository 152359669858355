import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';
import { formatReal } from '../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Notificacao from '../../utils/notificacao';
import { useConfig } from '../../hooks/config';
import CadastrarEditarProcedimento from './CadastrarEditar';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
}));

export default function Procedimentos() {
  const [procedimentos, setProcedimentos] = useState([]);
  const { configuracoes } = useConfig();
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [buscar, setBuscar] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');
  const [tipo, setTipo] = useState('');
  const [ativo, setAtivo] = useState('');
  const [status, setStatus] = useState('AND ativo=0');
  const [tipoBusca, setTipoBusca] = useState('AND tipo!=0');
  const [idProcedimento, setIdProcedimento] = useState('');
  const [permissoes, setPermissoes] = useState('');
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const atualizar = async () => {
    let consulta = ` ${ativo} ${status} ORDER BY descricao ASC`;
    listarProcedimentos(consulta);
    await api
      .get('Permissoes/find.php?id=' + getToken() + '&pdo=' + getClinica())
      .then(res => {
        setPermissoes(res.data);
      })
      .catch(error => console.log(error));
    setLoading(false);
  };

  const listarProcedimentos = consulta => {
    setProcedimentos([]);
    setPage(0);
    api
      .post('Procedimento/search.php', {
        pdo: getClinica(),
        consulta: consulta,
      })
      .then(res => {
        if (res.data.length > 0) {
          setProcedimentos(res.data);
        }
      })
      .catch(error => console.log(error));
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const limparCampos = () => {
    setDescricao('');
    setIdProcedimento('');
    setValor('');
    setTipo('');
    setAtivo('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fecharModal = () => {
    setModal(false);
    limparCampos();
  };

  const abrirModal = () => {
    limparCampos();
    setModal(true);
  };

  const salvar = () => {
    if (idProcedimento !== '') {
      alterarProcedimento();
    } else {
      cadastrarProcedimento();
    }
  };

  const validarCampos = () => {
    if (descricao !== '' && valor !== '' && tipo !== '') {
      return true;
    }
    return false;
  };

  const cadastrarProcedimento = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.procCad !== '0' && permissoes !== false) {
        if (validarCampos()) {
          await api
            .post('Procedimento/save.php', {
              pdo: getClinica(),
              descricao: descricao,
              valor: valor,
              tipo: tipo,
              ativo: 0,
            })
            .then(res => {
              setProcedimentos([...procedimentos, res.data]);
              abrirAviso('success', 'Procedimento cadastrado.');
              setDesabilitar(false);
              fecharModal();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const abrirProcedimento = procedimento => {
    setDescricao(procedimento.descricao);
    setIdProcedimento(procedimento.id);
    setTipo(procedimento.tipo);
    setAtivo(procedimento.ativo);
    setValor(procedimento.valor);
    setModal(true);
  };

  const alterarProcedimento = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.procAlt !== '0' && permissoes !== false) {
        if (validarCampos()) {
          api
            .post('Procedimento/update.php', {
              pdo: getClinica(),
              descricao: descricao,
              valor: valor,
              tipo: tipo,
              ativo: ativo,
              id: idProcedimento,
            })
            .then(res => {
              setProcedimentos(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('success', 'Procedimento alterado.');
              setDesabilitar(false);
              fecharModal();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const imprimir = () => {
    const reg = { registros: JSON.stringify(procedimentos) };
    sessionStorage.setItem('relProcedimentos', btoa(JSON.stringify(reg)));
    window.open(
      '/imprimir_procedimentos',
      'Procedimentos',
      'height=700,width=1100',
    );
    sessionStorage.removeItem('relProcedimentos');
  };

  const filtrar = () => {
    setLoading(true);
    let filtroBusca = '';
    if (status !== '3') {
      filtroBusca += ` ${status}`;
    }

    if (tipoBusca !== '') {
      filtroBusca += ` ${tipoBusca}`;
    }
    filtroBusca += ' ORDER BY descricao';
    listarProcedimentos(filtroBusca);
    setLoading(false);
  };

  const filtro = procedimentos.filter(p => {
    return (
      p.descricao
        .toLowerCase()
        .toUpperCase()
        .indexOf(buscar.toLowerCase().toUpperCase()) !== -1 ||
      p.id
        .toLowerCase()
        .toUpperCase()
        .indexOf(buscar.toLowerCase().toUpperCase()) !== -1
    );
  });

  return (
    <div>
      <Menu titulo="Procedimentos" />
      <Box
        display="flex"
        className={classes.opcoes}
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={abrirModal}
            startIcon={<AddCircleIcon />}
          >
            Novo
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={imprimir}
            startIcon={<PrintIcon />}
          >
            Imprimir
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
          >
            Atualizar
          </Button>
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="3">Todos</MenuItem>
            <MenuItem value="AND ativo=0">Ativo</MenuItem>
            <MenuItem value="AND ativo=-1">Inativo</MenuItem>
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Tipo"
            value={tipoBusca}
            onChange={e => setTipoBusca(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="AND tipo!=0">Todos</MenuItem>
            {configuracoes.moduloOdonto !== '0' && (
              <MenuItem value="AND tipo='1'">Odontológico</MenuItem>
            )}
            {configuracoes.moduloEstetico !== '0' && (
              <MenuItem value="AND tipo='2'">Estético</MenuItem>
            )}
          </TextField>
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Descrição</b>
                  </TableCell>
                  <TableCell>
                    <b>Valor</b>
                  </TableCell>
                  <TableCell>
                    <b>Tipo</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opção</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(procedimento => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={procedimento.id}
                      >
                        <TableCell>{procedimento.id}</TableCell>
                        <TableCell>{procedimento.descricao}</TableCell>
                        <TableCell>{formatReal(procedimento.valor)}</TableCell>
                        <TableCell>
                          {procedimento.tipo === '1'
                            ? 'Odontológico'
                            : 'Estético'}
                        </TableCell>
                        <TableCell>
                          {procedimento.ativo === '0' ? (
                            <Avatar className={classes.ativo} title="Ativo">
                              <CheckCircleIcon />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.inativo} title="Inativo">
                              <CancelIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            startIcon={<OpenInNewIcon />}
                            onClick={() => abrirProcedimento(procedimento)}
                            variant="contained"
                            color="primary"
                          >
                            Abrir
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={procedimentos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
      {modal && (
        <CadastrarEditarProcedimento
          modal={modal}
          fecharModal={fecharModal}
          idProcedimento={idProcedimento}
          descricao={descricao}
          setDescricao={setDescricao}
          valor={valor}
          setValor={setValor}
          tipo={tipo}
          setTipo={setTipo}
          configuracoes={configuracoes}
          ativo={ativo}
          setAtivo={setAtivo}
          fullScreen={fullScreen}
          classes={classes}
          salvar={salvar}
          desabilitar={desabilitar}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
