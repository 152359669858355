import React, { useState, useEffect, useRef } from 'react';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { usePermissoes } from '../../../hooks/permissoes';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '../../Menu';
import Notificacao from '../../../utils/notificacao';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import TabelaProcedimentos from './tabelaProcedimentos';
import InserirProcedimentoEstetico from './inserirProcedimento';
import './style.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import LensIcon from '@material-ui/icons/Lens';
import CreateIcon from '@material-ui/icons/Create';
import { useConfig } from '../../../hooks/config';
import { useEmpresa } from '../../../hooks/empresa';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  opcoes: {
    marginBottom: '0px',
    marginTop: '50px',
    width: '100%',
  },
  buttonOpcoes: {
    marginTop: '15px',
    marginRight: '8px',
  },
  InputsOrcamento: {
    margin: theme.spacing(1),
    width: '320px',
  },
  gridTable: {
    marginTop: '50px',
  },
  buttonInsert: {
    float: 'right',
    marginBottom: '10px',
  },
  buttonMarcadorVermelho: {
    color: '#dc3545',
    '&:hover': {
      color: '#b52c39',
    },
  },
  buttonMarcadorVerde: {
    color: '#32CD32',
    '&:hover': {
      color: '#099c42',
    },
  },
  buttonMarcadorAmarelo: {
    color: '#FFFB00',
    '&:hover': {
      color: '#cecb0d',
    },
  },
  buttonLapisVermelho: {
    color: '#dc3545',
    '&:hover': {
      color: '#b52c39',
    },
  },
  buttonLapisAzul: {
    color: '#1512f1',
    '&:hover': {
      color: '#1411b7',
    },
  },
}));

export default function CadastroEstetico() {
  const { permissoes } = usePermissoes();
  const { empresa } = useEmpresa();
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [procedimentos, setProcedimentos] = useState([]);
  const [planoTratamento, setPlanoTratamento] = useState([]);
  const [opcao, setOpcao] = useState(0);
  const [quantidade, setQuantidade] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [total, setTotal] = useState(0);
  const [tipoDesc, setTipoDesc] = useState('0');
  const [totalFinal, setTotalFinal] = useState(total);
  const [ratear, setRatear] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [especialistas, setEspecialistas] = useState([]);
  const [idEspecialista, setIdEspecialista] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [idProcedimento, setIdProcedimento] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [areaTratada, setAreaTratada] = useState('');
  const { configuracoes } = useConfig();
  const [modalInsert, setModalInsert] = useState(false);
  const canvasRef = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    soma();
  }, [planoTratamento]);

  useEffect(() => {
    desenhar();
  }, [opcao]);

  const atualizar = async () => {
    await api
      .get('Procedimento/list.php?pdo=' + getClinica() + '&tipo=2')
      .then(res => {
        setProcedimentos(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api.get('Produto/list.php?pdo=' + getClinica()).then(res => {
      setProdutos(res.data);
    });
    await api
      .get('Usuario/list_select.php?pdo=' + getClinica())
      .then(res => {
        setClientes(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .post('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setEspecialistas(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  const soma = () => {
    let soma = planoTratamento.reduce((sum, x) => sum + parseFloat(x.valor), 0);
    setTotal(soma);
    setTotalFinal(soma);
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const valorProcedimento = id => {
    let valor = 0;
    procedimentos.map(procedimento => {
      if (procedimento.id === id) {
        valor = procedimento.valor;
      }
    });
    return valor;
  };

  const cadastrar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.orcCad !== '0' && permissoes !== false) {
        if (idCliente?.id && idEspecialista?.id) {
          if (planoTratamento.length > 0) {
            const canvas = canvasRef.current;
            await api
              .post('Estetico/save.php', {
                pdo: getClinica(),
                idDentista: idEspecialista.id,
                idCliente: idCliente.id,
                planosTratamento: planoTratamento,
                ratear: ratear,
                img: canvas.toDataURL(),
                refTratamento: null,
              })
              .then(res => {
                abrirAviso('success', `Orçamento Nº ${res.data} cadastrado.`);
                setTimeout(function () {
                  window.location.reload();
                }, 2500);
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            abrirAviso('warning', 'Procedimentos não cadastrados.');
            setDesabilitar(false);
          }
        } else {
          abrirAviso('warning', 'Paciente ou Profissional não informado.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const desenhar = () => {
    const canvas = canvasRef.current;
    let ctx = null;
    if (canvas !== null) {
      ctx = canvas.getContext('2d');
    }
    if (opcao !== 0) {
      if (opcao === 1) {
        canvas.onmousedown = function (event) {
          const x = event.offsetX;
          const y = event.offsetY;
          ctx.fillStyle = 'red';
          ctx.beginPath();
          ctx.arc(x, y, 5, 0, 2 * Math.PI);
          ctx.fill();
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
        };
      } else if (opcao === 2) {
        canvas.onmousedown = function (event) {
          const x = event.offsetX;
          const y = event.offsetY;
          ctx.fillStyle = '#32CD32';
          ctx.beginPath();
          ctx.arc(x, y, 7, 0, 2 * Math.PI);
          ctx.fill();
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
        };
      } else if (opcao === 3) {
        canvas.onmousedown = function (event) {
          canvas.onmousemove = function (event) {
            const x = event.offsetX;
            const y = event.offsetY;
            ctx.fillStyle = 'blue';
            ctx.beginPath();
            ctx.arc(x, y, 2, 0, 2 * Math.PI);
            ctx.fill();
          };
          canvas.onmouseup = function () {
            canvas.onmousemove = null;
          };
        };
      } else if (opcao === 4) {
        canvas.onmousedown = function (event) {
          const x = event.offsetX;
          const y = event.offsetY;
          ctx.fillStyle = '#FFFB00';
          ctx.beginPath();
          ctx.arc(x, y, 6, 0, 2 * Math.PI);
          ctx.fill();
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
        };
      } else if (opcao === 5) {
        canvas.onmousedown = function (event) {
          canvas.onmousemove = function (event) {
            const x = event.offsetX;
            const y = event.offsetY;
            ctx.fillStyle = 'red';
            ctx.beginPath();
            ctx.arc(x, y, 2, 0, 2 * Math.PI);
            ctx.fill();
          };
          canvas.onmouseup = function () {
            canvas.onmousemove = null;
          };
        };
      }
    }
  };

  const limpar = () => {
    setOpcao(0);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.onmousedown = function (event) {
      canvas.onmousemove = function (event) {
        const x = event.offsetX;
        const y = event.offsetY;
        ctx.clearRect(x - 5, y - 5, 12, 12);
      };
      canvas.onmouseup = function () {
        canvas.onmousemove = null;
      };
    };
  };

  const limparTudo = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <>
      <Menu titulo="Orçamento ► Estético" />
      <div className={classes.opcoes}>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box p={2} width="100%">
            <Autocomplete
              size="small"
              options={clientes}
              getOptionLabel={option => option.nome}
              onChange={(event, newValue) => {
                setIdCliente(newValue);
              }}
              renderInput={params => (
                <TextField
                  className={classes.InputsOrcamento}
                  {...params}
                  label="Paciente"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box p={2} width="100%">
            <Autocomplete
              size="small"
              options={especialistas}
              getOptionLabel={option => option.nome}
              onChange={(event, newValue) => {
                setIdEspecialista(newValue);
              }}
              renderInput={params => (
                <TextField
                  className={classes.InputsOrcamento}
                  {...params}
                  label="Profissional"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box p={1} flexShrink={0}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonOpcoes}
              onClick={cadastrar}
              startIcon={<AddCircleIcon />}
              disabled={desabilitar}
            >
              Cadastrar
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.buttonOpcoes}
              onClick={() => window.location.reload()}
              startIcon={<CancelIcon />}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <canvas
              id="desenho"
              ref={canvasRef}
              onClick={desenhar}
              width={282}
              height={390}
            />
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                onClick={() => setOpcao(1)}
                className={classes.buttonMarcadorVermelho}
                title="Marcador Vermelho"
              >
                <LensIcon />
              </Button>
              <Button
                onClick={() => setOpcao(2)}
                className={classes.buttonMarcadorVerde}
                title="Marcador Verde"
              >
                <LensIcon />
              </Button>
              <Button
                onClick={() => setOpcao(4)}
                className={classes.buttonMarcadorAmarelo}
                title="Marcador Amarelo"
              >
                <LensIcon />
              </Button>
              <Button
                onClick={() => setOpcao(3)}
                className={classes.buttonLapisAzul}
                title="Lápis Azul"
              >
                <CreateIcon />
              </Button>
              <Button
                onClick={() => setOpcao(5)}
                className={classes.buttonLapisVermelho}
                title="Lápis Vermelho"
              >
                <CreateIcon />
              </Button>
              <Button onClick={limpar} title="Limpar">
                <ClearIcon />
              </Button>
              <Button onClick={limparTudo} title="Descartar tudo">
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={9} className={classes.gridTable}>
            <Button
              startIcon={<AddCircleIcon />}
              variant="contained"
              color="primary"
              className={classes.buttonInsert}
              onClick={() => setModalInsert(true)}
            >
              Inserir Procedimento
            </Button>
            <TabelaProcedimentos
              idOrcamento={null}
              listaProcedimentos={procedimentos}
              setProcedimentos={setProcedimentos}
              planoTratamento={planoTratamento}
              setPlanoTratamento={setPlanoTratamento}
              listaProdutos={produtos}
              abrirAviso={abrirAviso}
              tipoDesc={tipoDesc}
              setTipoDesc={setTipoDesc}
              total={total}
              setTotal={setTotal}
              totalFinal={totalFinal}
              setTotalFinal={setTotalFinal}
              desconto={desconto}
              setDesconto={setDesconto}
              ratear={ratear}
              setRatear={setRatear}
              empresa={empresa}
            />
            {modalInsert && (
              <InserirProcedimentoEstetico
                idOrcamento={null}
                listaProcedimentos={procedimentos}
                planoTratamento={planoTratamento}
                setPlanoTratamento={setPlanoTratamento}
                idProcedimento={idProcedimento}
                setIdProcedimento={setIdProcedimento}
                valorProcedimento={valorProcedimento}
                listaProdutos={produtos}
                produto={idProduto}
                setProduto={setIdProduto}
                areaTratada={areaTratada}
                setAreaTratada={setAreaTratada}
                quantidade={quantidade}
                setQuantidade={setQuantidade}
                modal={modalInsert}
                setModal={setModalInsert}
                abrirAviso={abrirAviso}
                empresa={empresa}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
