import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { formatData, formatReal, formaPagamento } from '../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  amarelo: {
    backgroundColor: '#FFC107',
  },
  verde: {
    backgroundColor: '#4caf50',
  },
  vermelho: {
    backgroundColor: '#FF0000',
  },
  contaVencida: {
    backgroundColor: '#f8beb7',
  },
}));

export default function ContasTratamento({ contas }) {
  const [totalReceber, setTotalReceber] = useState(0);
  const [totalRecebido, setTotalRecebido] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    totalAReceber(contas);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ehContaVencida = (situacao, e) => {
    let vencimento = e.split('-');
    let data = new Date(vencimento[0], vencimento[1] - 1, vencimento[2]);
    let hoje = new Date();
    if (data < hoje && situacao === '0') {
      return classes.contaVencida;
    }
  };

  const totalAReceber = contas => {
    let totalRecebido = contas
      .filter(c => c.status === '1')
      .reduce((sum, li) => sum + Number(li.valor), 0);
    let totalReceber = contas
      .filter(c => c.status === '0')
      .reduce((sum, li) => sum + Number(li.valor), 0);

    setTotalReceber(totalReceber);
    setTotalRecebido(totalRecebido);
  };

  const situacaoConta = valor => {
    if (valor === '0') {
      return (
        <Tooltip title="CONTA PENDENTE" TransitionComponent={Zoom}>
          <Avatar className={classes.amarelo}>PE</Avatar>
        </Tooltip>
      );
    }
    if (valor === '1') {
      return (
        <Tooltip title="CONTA PAGA" TransitionComponent={Zoom}>
          <Avatar title="Conta Paga" className={classes.verde}>
            PG
          </Avatar>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="CONTA CANCELADA" TransitionComponent={Zoom}>
          <Avatar title="Conta Cancelada" className={classes.vermelho}>
            CA
          </Avatar>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <Box display="flex" p={1} bgcolor="background.paper">
        <Box p={1} width="100%">
          Total a receber:{' '}
          <b style={{ color: '#FF0000' }}>{formatReal(totalReceber)}</b>
        </Box>
        <Box p={1} width="100%">
          Total recebido:
          <b style={{ color: '#4caf50' }}>{formatReal(totalRecebido)}</b>
        </Box>
      </Box>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>ID</b>
                </TableCell>
                <TableCell>
                  <b>Emissao</b>
                </TableCell>
                <TableCell>
                  <b>Vencimento</b>
                </TableCell>
                <TableCell>
                  <b>Valor</b>
                </TableCell>
                <TableCell>
                  <b>Nº Doc.</b>
                </TableCell>
                <TableCell>
                  <b>Nº Trat.</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>Forma Pgto</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(conta => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={conta.id}
                      className={ehContaVencida(conta.status, conta.vencimento)}
                    >
                      <TableCell>{conta.id}</TableCell>
                      <TableCell>{formatData(conta.emissao)}</TableCell>
                      <TableCell>{formatData(conta.vencimento)}</TableCell>
                      <TableCell>{formatReal(conta.valor)}</TableCell>
                      <TableCell>{conta.nDoc}</TableCell>
                      <TableCell>{conta.idTratamento}</TableCell>
                      <TableCell>{situacaoConta(conta.status)}</TableCell>
                      <TableCell>{formaPagamento(conta.formaPag)}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={contas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por pagina"
        />
      </Paper>
    </div>
  );
}
