import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print';
import HistoryIcon from '@material-ui/icons/History';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '../Menu';
import CadastrarEditarOs from './CadastrarEditar';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import Notificacao from '../../utils/notificacao';
import { formatData, formatReal, statusOrdemServico } from '../../utils/funcoes';
import HistoricoOrdemServico from './historico';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  box: {
    marginTop: '68px',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  button: {
    margin: theme.spacing(1),
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
}));

export default function OSProtetico() {
  const [buscar, setBuscar] = useState('');
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [ordensServico, setOrdensServico] = useState([]);
  const [os, setOS] = useState(null);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [clientes, setClientes] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [laborarios, setLaboratorios] = useState([]);
  const [modalHistorico, setModalHistorico] = useState(false);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const atualizar = async () => {
    await api
      .post('OrdemServico/search.php', {
        pdo: getClinica(),
        consulta: '',
      })
      .then(res => res.data ? setOrdensServico(res.data) : setOrdensServico([])
      )
      .catch(error => console.log(error));
    await api
      .get('Usuario/list_select.php?pdo=' + getClinica())
      .then(res => {
        setClientes(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .post('Laboratorio/search.php', {
        pdo: getClinica(),
        consulta: '',
      })
      .then(res => {
        res.data ? setLaboratorios(res.data) : setLaboratorios([]);
      });
    await api
      .post('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setProfissionais(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  const imprimir = ordemServico => {
    const reg = { registros: JSON.stringify(ordemServico) };
    sessionStorage.setItem('os', btoa(JSON.stringify(reg)));
    window.open('/imprimir_os', 'Ordem de Serviço', 'height=900,width=800');
    sessionStorage.removeItem('os');
  };

  const filtro = ordensServico.filter(p => {
    return (
      p.paciente.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      p.dentista.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      p.id.toLowerCase().indexOf(buscar.toLowerCase()) !== -1
    );
  });

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <>
      <Menu titulo="Ordem de Serviço ► Protético" />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
        className={classes.box}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            setOS(null);
            setModal(true);
          }}
          startIcon={<AddCircleIcon />}
        >
          Cadastrar
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.location.reload()}
          startIcon={<RefreshIcon />}
        >
          Atualizar
        </Button>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Paciente</b>
                  </TableCell>
                  <TableCell>
                    <b>Profissional</b>
                  </TableCell>
                  <TableCell>
                    <b>Valor</b>
                  </TableCell>
                  <TableCell>
                    <b>Entrega</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(ordem => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={ordem.id}
                      >
                        <TableCell>{ordem.id}</TableCell>
                        <TableCell>{ordem.paciente}</TableCell>
                        <TableCell>{ordem.dentista}</TableCell>
                        <TableCell>{formatReal(ordem.valor)}</TableCell>
                        <TableCell>{formatData(ordem.dataEntrega)}</TableCell>
                        <TableCell>
                          {ordem.status > 0 && ordem.status < 6
                            ? statusOrdemServico[ordem.status - 1].label
                            : ''}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            title="Abrir"
                            onClick={() => {
                              setOS(ordem);
                              setModal(true);
                            }}
                          >
                            <OpenInNewIcon />
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            title="Histórico"
                            style={{ marginLeft: 4 }}
                            onClick={() => {
                              setOS(ordem);
                              setModalHistorico(true);
                            }}
                          >
                            <HistoryIcon />
                          </Button>
                          <Button
                            variant="contained"
                            color="inherit"
                            size="small"
                            title="Imprimir"
                            style={{ marginLeft: 4 }}
                            onClick={() => imprimir(ordem)}
                          >
                            <PrintIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={ordensServico.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
      {modal && (
        <CadastrarEditarOs
          modal={modal}
          fecharModal={() => setModal(false)}
          ordemServico={os}
          ordensServico={ordensServico}
          setOrdensServico={setOrdensServico}
          clientes={clientes}
          profissionais={profissionais}
          laborarios={laborarios}
          abrirAviso={abrirAviso}
        />
      )}
      {modalHistorico && (
        <HistoricoOrdemServico
          modal={modalHistorico}
          setModal={setModalHistorico}
          ordemServico={os}
        />
      )}

      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
