import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatData, formatReal } from '../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  // container: {
  //   maxHeight: 500,
  // },
  textPesquisa: {
    marginBottom: '10px',
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  button: {
    margin: theme.spacing(1),
    marginTop: '80px',
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  totalizador: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

export default function RelatorioProducaoUnificado() {
  const [loading, setLoading] = useState(true);
  const [registros, setRegistros] = useState([]);
  const [status, setStatus] = useState('2');
  const [profissionais, setProfissionais] = useState([]);
  const [pacientes, setPacientes] = useState(null);
  const [convenios, setConvenios] = useState([]);
  const [idProf, setIdProf] = useState(null);
  const [idPac, setIdPac] = useState(null);
  const [idConv, setIdConv] = useState(null);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [total, setTotal] = useState(0);
  const [porc, setPorc] = useState(0);

  const classes = useStyles();

  const atualizar = async () => {
    await api
      .get('Usuario/list_select.php?pdo=' + getClinica())
      .then(res => {
        setPacientes(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await pesquisa('AND pt.Status=2', 'AND pe.Status=2');
    await api
      .post('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setProfissionais(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .get('Convenio/list.php?pdo=' + getClinica())
      .then(res => {
        setConvenios(res.data);
      })
      .catch(error => console.log(error));
    setLoading(false);
  };

  const pesquisa = async (consultaOdonto, consultaEstetico) => {
    setLoading(true);
    let reg = [],
      total = 0,
      perc = 0;
    await api
      .post('Relatorios/producaoOdonto.php', {
        pdo: getClinica(),
        consulta: consultaOdonto,
      })
      .then(res => {
        if (res.data) {
          res.data.map(item =>
            reg.push({
              data: item.dataCriacao,
              procedimento: item.descricaoProcedimento,
              paciente: item.paciente,
              dentista: item.dentista,
              convenio: item.convenio,
              tipo: 'Odontológico',
              valor: item.valor,
              porcentagem: item.porcentagem,
            }),
          );
          total += res.data.reduce(
            (acc, atual) => acc + Number(atual.valor),
            0,
          );
          perc += res.data.reduce(
            (acc, atual) => acc + Number(atual.porcentagem),
            0,
          );
        }
      })
      .catch(error => console.log(error));

    await api
      .post('Relatorios/producaoEstetico.php', {
        pdo: getClinica(),
        consulta: consultaEstetico,
      })
      .then(res => {
        if (res.data) {
          res.data.map(item =>
            reg.push({
              data: item.dataCriacao,
              procedimento: item.descricaoProcedimento,
              paciente: item.paciente,
              dentista: item.dentista,
              convenio: item.convenio,
              tipo: 'Estético',
              valor: item.valor,
              porcentagem: item.porcentagem,
            }),
          );
          total += res.data.reduce(
            (acc, atual) => acc + Number(atual.valor),
            0,
          );
          perc += res.data.reduce(
            (acc, atual) => acc + Number(atual.porcentagem),
            0,
          );
        }
      })
      .catch(error => console.log(error));
    reg.sort((a, b) => {
      return Date.parse(a.data) - Date.parse(b.data);
    });
    setRegistros(reg);
    setTotal(total);
    setPorc(perc);
    setLoading(false);
  };

  const filtrar = async () => {
    let filtroOdonto = '',
      filtroEstetico = '';
    if (idPac) {
      filtroOdonto += ` AND t.idCliente=${idPac}`;
      filtroEstetico += ` AND e.idCliente=${idPac}`;
    }

    if (status !== '3') {
      filtroOdonto += ` AND pt.status=${status}`;
      filtroEstetico += ` AND pe.status=${status}`;
    }

    if (idProf) {
      filtroOdonto += ` AND t.idDentista=${idProf}`;
      filtroEstetico += ` AND e.idDentista=${idProf}`;
    }

    if (idConv) {
      filtroOdonto += ` AND u.convenio=${idConv}`;
      filtroEstetico += ` AND u.convenio=${idConv}`;
    }

    if (dataInicio && dataFim) {
      filtroOdonto += ` AND t.dataCriacao BETWEEN '${dataInicio}' AND '${dataFim}'`;
      filtroEstetico += ` AND e.dataCriacao BETWEEN '${dataInicio}' AND '${dataFim}'`;
    }
    await pesquisa(filtroOdonto, filtroEstetico);
  };

  const imprimir = () => {
    const reg = {
      registros: registros,
      total: total,
      porc: porc,
    };
    console.log(reg);
    sessionStorage.setItem('relPU', JSON.stringify(reg));
    window.open(
      '/imprimir_prod_unificado',
      'Relatorio Produção Unificado',
      'height=700,width=1100',
    );
    sessionStorage.removeItem('relPU');
  };

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <div>
      <Menu titulo="Relatórios ► Produção Unificado" />
      <div>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.location.reload()}
          startIcon={<RefreshIcon />}
        >
          Atualizar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={imprimir}
          startIcon={<PrintIcon />}
        >
          Imprimir
        </Button>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Paciente"
            value={idPac}
            onChange={e => setIdPac(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">Selecione...</MenuItem>
            {pacientes.map(paciente => (
              <MenuItem key={paciente.id} value={paciente.id}>
                {paciente.nome}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="3">Todos</MenuItem>
            <MenuItem value="0">Aguardando</MenuItem>
            <MenuItem value="1">Em Andamento</MenuItem>
            <MenuItem value="2">Concluídos</MenuItem>
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Profissional"
            value={idProf}
            onChange={e => setIdProf(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">Selecione...</MenuItem>
            {profissionais.map(prof => (
              <MenuItem key={prof.id} value={prof.id}>
                {prof.nome}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Convênio"
            value={idConv}
            onChange={e => setIdConv(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">Selecione...</MenuItem>
            {convenios.map(convenio => (
              <MenuItem key={convenio.id} value={convenio.id}>
                {convenio.nome}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Inicial"
            value={dataInicio}
            onChange={e => setDataInicio(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Final"
            value={dataFim}
            onChange={e => setDataFim(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>

          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Data</b>
                  </TableCell>
                  <TableCell>
                    <b>Tipo</b>
                  </TableCell>
                  <TableCell>
                    <b>Procedimento</b>
                  </TableCell>
                  <TableCell>
                    <b>Paciente</b>
                  </TableCell>
                  <TableCell>
                    <b>Profissional</b>
                  </TableCell>
                  {/* <TableCell>
                    <b>Convênio</b>
                  </TableCell> */}
                  <TableCell>
                    <b>Valor</b>
                  </TableCell>
                  <TableCell>
                    <b>% Profissional</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {registros.map(registro => {
                  return (
                    <TableRow key={registro.id}>
                      <TableCell>{formatData(registro.data)}</TableCell>
                      <TableCell>{registro.tipo}</TableCell>
                      <TableCell>{registro.procedimento}</TableCell>
                      <TableCell>{registro.paciente}</TableCell>
                      <TableCell>{registro.dentista}</TableCell>
                      {/* <TableCell>{registro.convenio}</TableCell> */}
                      <TableCell>{formatReal(registro.valor)}</TableCell>
                      <TableCell>{formatReal(registro.porcentagem)}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={3} className={classes.totalizador}>
                    Total
                  </TableCell>
                  <TableCell colSpan={2} className={classes.totalizador}>
                    {registros.length} registro(s)
                  </TableCell>
                  <TableCell className={classes.totalizador}>
                    {formatReal(total)}
                  </TableCell>
                  <TableCell className={classes.totalizador}>
                    {formatReal(porc)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </div>
  );
}
