import React from 'react';
import Cabecalho from '../../utils/cabecalho';
import Rodape from '../../utils/rodape';
import { formatData, formatReal } from '../../utils/funcoes';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fab,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(1.5),
  },
  titulo: {
    textAlign: 'center',
  },
  totalizador: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

export default function ImprimirRelatorioFaturamentoSinteticoTaxa() {
  const reg = JSON.parse(atob(sessionStorage.getItem('relFatSinteticoTaxa')));
  const registros = JSON.parse(reg.registros);
  const classes = useStyles();

  const imprimir = () => {
    window.document.getElementById('button-print').style.display = 'none';
    window.print();
    window.document.getElementById('button-print').style.display = 'block';
    window.close();
  };

  return (
    <>
      <Cabecalho />
      <h3 className={classes.titulo}>
        Relatório de Faturamento Síntetico com Taxas
      </h3>
      <h5 className={classes.titulo}>
        Período {formatData(reg.dataI)} à {formatData(reg.dataF)}
      </h5>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>RECEBIMENTOS</b>
              </TableCell>
              <TableCell>
                <b>VALOR BRUTO</b>
              </TableCell>
              <TableCell>
                <b>TAXAS</b>
              </TableCell>
              <TableCell>
                <b>VALOR LÍQUIDO</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>BOLETO</TableCell>
              <TableCell>{formatReal(registros.boletoBruto)}</TableCell>
              <TableCell>{formatReal(registros.boletoTaxa)}</TableCell>
              <TableCell>{formatReal(registros.boletoLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CARTÃO CRÉDITO</TableCell>
              <TableCell>{formatReal(registros.cartaoCreditoBruto)}</TableCell>
              <TableCell>{formatReal(registros.cartaoCreditoTaxa)}</TableCell>
              <TableCell>
                {formatReal(registros.cartaoCreditoLiquido)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CARTÃO DÉBITO</TableCell>
              <TableCell>{formatReal(registros.cartaoDebitoBruto)}</TableCell>
              <TableCell>{formatReal(registros.cartaoDebitoTaxa)}</TableCell>
              <TableCell>{formatReal(registros.cartaoDebitoLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CHEQUE</TableCell>
              <TableCell>{formatReal(registros.chequeBruto)}</TableCell>
              <TableCell>{formatReal(registros.chequeTaxa)}</TableCell>
              <TableCell>{formatReal(registros.chequeLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CONVÊNIO</TableCell>
              <TableCell>{formatReal(registros.convenioBruto)}</TableCell>
              <TableCell>{formatReal(registros.convenioTaxa)}</TableCell>
              <TableCell>{formatReal(registros.convenioLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DINHEIRO</TableCell>
              <TableCell>{formatReal(registros.dinheiroBruto)}</TableCell>
              <TableCell>{formatReal(registros.dinheiroTaxa)}</TableCell>
              <TableCell>{formatReal(registros.dinheiroLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PIX</TableCell>
              <TableCell>{formatReal(registros.pixBruto)}</TableCell>
              <TableCell>{formatReal(registros.pixTaxa)}</TableCell>
              <TableCell>{formatReal(registros.pixLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TRANSFERÊNCIA</TableCell>
              <TableCell>{formatReal(registros.transferenciaBruto)}</TableCell>
              <TableCell>{formatReal(registros.transferenciaTaxa)}</TableCell>
              <TableCell>
                {formatReal(registros.transferenciaLiquido)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>VISA</TableCell>
              <TableCell>{formatReal(registros.visaBruto)}</TableCell>
              <TableCell>{formatReal(registros.visaTaxa)}</TableCell>
              <TableCell>{formatReal(registros.visaLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>MASTERCARD</TableCell>
              <TableCell>{formatReal(registros.masterBruto)}</TableCell>
              <TableCell>{formatReal(registros.masterTaxa)}</TableCell>
              <TableCell>{formatReal(registros.masterLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ELO</TableCell>
              <TableCell>{formatReal(registros.eloBruto)}</TableCell>
              <TableCell>{formatReal(registros.eloTaxa)}</TableCell>
              <TableCell>{formatReal(registros.eloLiquido)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.totalizador}>Total</TableCell>
              <TableCell className={classes.totalizador}>
                {formatReal(reg.totalBruto)}
              </TableCell>
              <TableCell className={classes.totalizador}>
                {formatReal(reg.totalTaxa)}
              </TableCell>
              <TableCell className={classes.totalizador}>
                {formatReal(reg.totalLiquido)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Rodape />
      <Fab
        color="primary"
        size="large"
        id="button-print"
        className={classes.fab}
        onClick={imprimir}
      >
        <PrintIcon />
      </Fab>
    </>
  );
}
