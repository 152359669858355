import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import Odontograma from '../../../utils/odontograma';
import Cabecalho from '../../../utils/cabecalho';
import Rodape from '../../../utils/rodape';
import { formatReal, formatData } from '../../../utils/funcoes';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { getClinica } from '../../../services/auth';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  styleBorder: {
    border: ' solid',
    borderWidth: '1px',
    borderRadius: '4px',
    fontSize: '20px',
    padding: '10px',
  },
  styleTable: {
    border: ' solid',
    borderWidth: '1px',
    borderRadius: '5px',
    width: '100%',
  },
  rowColStyle: {
    border: ' 1px solid black',
  },
  dadosOrcamento: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titulo: {
    textAlign: 'center',
    margin: '5px 5px',
    fontSize: '20px',
  },
  dadosPaciente: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 5px',
  },
  assinatura: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 10px',
  },
  assCol: {
    padding: '0 25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    position: 'fixed',
    bottom: '1',
    width: '100%',
  },
  containerPrint: {
    minHeight: '100%',
    position: 'relative',
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  rowHorizontal: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    border: '1px solid #000',
  },
}));

export default function ImprimirOrcamento() {
  const { orc } = useParams();
  const Orcamento = JSON.parse(atob(orc));
  const [procedimentos, setProcedimentos] = useState([]);
  const [Paciente, setPaciente] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const atualizar = async () => {
    await api
      .post(`PlanoTratamento/list.php?id=${Orcamento.id}&pdo=${getClinica()}`)
      .then(res => {
        setProcedimentos(res.data);
      })
      .catch(error => console.log(error));
    await api
      .get('Usuario/find.php', {
        params: { id: Orcamento.idCliente, pdo: getClinica() },
      })
      .then(res => setPaciente(res.data))
      .catch(error => console.log(error));
    setLoading(false);
  };

  const hoje = e => {
    var data = new Date(),
      dia = data.getDate().toString().padStart(2, '0'),
      mes = (data.getMonth() + 1).toString().padStart(2, '0'),
      ano = data.getFullYear();
    return dia + '/' + mes + '/' + ano;
  };

  const imprimir = () => {
    window.document.getElementById('orcamento-odonto-print').style.display =
      'none';
    window.print();
    window.document.getElementById('orcamento-odonto-print').style.display =
      'block';
    window.close();
  };

  const soma = tipo => {
    let total = 0,
      desconto = 0;
    procedimentos.map(pro => {
      total += parseFloat(pro.valor);
      desconto += parseFloat(pro.desconto);
    });
    if (tipo === 'total') {
      return formatReal(total - desconto);
    } else if (tipo === 'desconto') {
      return formatReal(desconto);
    } else {
      return formatReal(total);
    }
  };

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.containerPrint}>
          <Cabecalho />
          <div style={{ border: '2px solid #000', width: '100%' }}></div>
          <br />
          <div className={classes.styleBorder}>
            <div className={classes.dadosOrcamento}>
              {Orcamento.id && (
                <div>
                  <p>Orçamento nº {Orcamento.id}</p>
                </div>
              )}
              <div>
                <p>Cadastrado em: {formatData(Orcamento.dataCriacao)}</p>
              </div>
              <div>
                <p>Emitido em: {hoje()}</p>
              </div>
            </div>
          </div>
          <br />
          <h4 className={classes.titulo}>Paciente</h4>
          <div className={classes.styleBorder}>
            <div className={classes.dadosPaciente}>
              <div>
                <b>Nome:</b> {Paciente.nome}
              </div>
              <div>
                <b>Telefone:</b> {Paciente.telefone}
              </div>
            </div>
            <div className={classes.dadosPaciente}>
              <div>
                <b>Endereço:</b> {Paciente.endLograR}, {Paciente.endNumR}{' '}
                {Paciente.endCompleR} - {Paciente.endBairroR} -{' '}
                {Paciente.endCepR} - {Paciente.endCidadeR} -{' '}
                {Paciente.endEstadoR}
              </div>
            </div>
            <div className={classes.dadosPaciente}>
              <div>
                <b>Data de Nasc:</b> {formatData(Paciente.dataNasc)}
              </div>
              <div>
                <b>Convênio:</b> {Paciente.nomeConvenio}
              </div>
            </div>
          </div>
          <br />
          <div className={classes.styleBorder}>
            <Odontograma procedimentos={procedimentos} />
          </div>
          <br />
          <h4 className={classes.titulo}>Procedimento(s)</h4>
          {procedimentos.length > 0 ? (
            <table className={classes.styleTable}>
              <thead className={classes.rowColStyle}>
                <th className={classes.rowColStyle}>Num. dente</th>
                <th className={classes.rowColStyle} scope="col">
                  Face(s)
                </th>
                <th className={classes.rowColStyle} scope="col">
                  Estado Atual
                </th>
                <th className={classes.rowColStyle} scope="col">
                  Procedimento
                </th>
                <th className={classes.rowColStyle} scope="col">
                  Valor
                </th>
                <th className={classes.rowColStyle} scope="col">
                  Desconto
                </th>
                <th className={classes.rowColStyle} scope="col">
                  Valor Desc.
                </th>
              </thead>
              <tbody>
                {procedimentos.map((p, index) => (
                  <tr className={classes.rowColStyle} key={index}>
                    <td align="center" className={classes.rowColStyle}>
                      {p.numDente}
                    </td>
                    <td align="center" className={classes.rowColStyle}>
                      {p.face}
                    </td>
                    <td align="center" className={classes.rowColStyle}>
                      {p.estadoAtual}
                    </td>
                    <td align="center" className={classes.rowColStyle}>
                      {p.descricaoProcedimento}
                    </td>
                    <td align="center" className={classes.rowColStyle}>
                      {formatReal(p.valor)}
                    </td>
                    <td align="center" className={classes.rowColStyle}>
                      {formatReal(p.desconto)}
                    </td>
                    <td align="center" className={classes.rowColStyle}>
                      {formatReal(p.valor - p.desconto)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
              <div className={classes.rowHorizontal}></div>
            </>
          )}
          <br />
          <div className={classes.styleBorder}>
            <div className={classes.dadosOrcamento}>
              <div>
                <b>
                  SUBTOTAL:{' '}
                  {procedimentos.length > 0 ? soma('subtotal') : '__________'}
                </b>
              </div>
              <div>
                <b>
                  DESCONTO:{' '}
                  {procedimentos.length > 0 ? soma('desconto') : '__________'}
                </b>
              </div>
              <div>
                <b>
                  TOTAL:{' '}
                  {procedimentos.length > 0 ? soma('total') : '__________'}
                </b>
              </div>
            </div>
          </div>
          <br />
          <div className={classes.styleBorder}>
            <div className={classes.assinatura}>
              <div className={classes.assCol}>
                _______________________________________ <br /> Autorização do
                paciente ou responsável
              </div>
              <div className={classes.assCol}>
                _______________________________________ <br /> CPF ou RG do
                paciente ou responsável
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Rodape />
          </div>
          <div id="orcamento-odonto-print">
            <Fab
              className={classes.fab}
              color="primary"
              onClick={() => imprimir()}
            >
              <PrintIcon />
            </Fab>
          </div>
        </div>
      )}
    </>
  );
}
