import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';
import { getClinica } from '../services/auth';
const EmpresaContext = createContext({});

export const EmpresaProvider = ({ children }) =>{
    const [empresa, setEmpresa] = useState(null);

    useEffect(()=>{
        atualizar();
    },[]);
    
    const atualizar = () => {
        api.get('Empresa/list.php?pdo='+getClinica())
        .then(res =>{
            setEmpresa(res.data[0]);
        }).catch(error => {console.log(error)}) 
    }
    return(
        <EmpresaContext.Provider value={{empresa}}>
            {children}
        </EmpresaContext.Provider>
    );
};

export function useEmpresa(){
    const context = useContext(EmpresaContext);
    if(!context){
        throw new Error('Deve ser utilizado em um EmpresaContext');
    }
    return context;
}

