import React from 'react';
import {
  Grid,
  TextField,
  Paper,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: '8px',
  },
}));

export default function HigieneOral({
  hOFreqEscovacao,
  setHOFreqEscovacao,
  hOCremeDental,
  setHOCremeDental,
  hOFioDental,
  setHOFioDental,
  hOBochecho,
  setHOBochecho,
  hOQualBochecho,
  setHOQualBochecho,
  hOEscovaLingua,
  setHOEscovaLingua,
  hOQuemHigMenor,
  setHOQuemHigMenor,
  hOInicioEscovacao,
  setHOInicioEscovacao,
}) {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="1. Com que frequência escova os dentes?"
              fullWidth
              value={hOFreqEscovacao}
              onChange={e => setHOFreqEscovacao(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="2. Qual creme dental utiliza?"
              fullWidth
              value={hOCremeDental}
              onChange={e => setHOCremeDental(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hOFioDental}
              control={<Checkbox color="primary" />}
              onChange={() => setHOFioDental(!hOFioDental)}
              label="3. Faz a utilização do fio dental?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              checked={hOBochecho}
              control={<Checkbox color="primary" />}
              onChange={() => setHOBochecho(!hOBochecho)}
              label="4. Faz bochecho com solução fluoretada?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              label="Se sim, qual?"
              fullWidth
              value={hOQualBochecho}
              onChange={e => setHOQualBochecho(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControlLabel
              checked={hOEscovaLingua}
              control={<Checkbox color="primary" />}
              onChange={() => setHOEscovaLingua(!hOEscovaLingua)}
              label="5. Escova regularmente a língua?"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              label="6. Quem realiza a higienização bucal do menor?"
              fullWidth
              value={hOQuemHigMenor}
              onChange={e => setHOQuemHigMenor(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="7. Início de escovação com creme dental (idade):"
              fullWidth
              value={hOInicioEscovacao}
              onChange={e => setHOInicioEscovacao(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
