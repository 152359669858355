import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import numero from 'numero-por-extenso';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  DialogContentText,
  useMediaQuery,
  makeStyles,
  useTheme,
  Box,
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { getClinica, getToken } from '../../../services/auth';
import api from '../../../services/api';
import {
  formatData,
  formatReal,
  listaFormasPagamento,
} from '../../../utils/funcoes';
import CancelarConta from './cancelar';
import Pagamento from './pagamento';

const useStyles = makeStyles(theme => ({
  buttonPagar: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#3b8e3e',
    },
    color: '#FFFF',
    margin: theme.spacing(1),
  },
  buttonCancel: {
    margin: theme.spacing(1),
  },
}));

export default function CadastrarEditarConta({
  modal,
  fecharModal,
  clientes,
  centrosCusto,
  conta,
  empresa,
  contas,
  setContas,
  abrirAviso,
  configuracoes,
  permissoes,
}) {
  const [centroCusto, setCentroCusto] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCli, setNomeCli] = useState('');
  const [cpfCli, setCpfCli] = useState('');
  const [responsavel, setResponsavel] = useState(null);
  const [respCpf, setRespCpf] = useState(null);
  const [respRg, setRespRg] = useState(null);
  const [dataNasc, setDataNasc] = useState(null);
  const [valor, setValor] = useState(null);
  const [parcelas, setParcelas] = useState(1);
  const [repetir, setRepetir] = useState(0);
  const [idTratamento, setIdTratamento] = useState('');
  const [emissao, setEmissao] = useState(null);
  const [vencimento, setVencimento] = useState(null);
  const [obs, setObs] = useState('');
  const [formaPag, setFormaPag] = useState(null);
  const [pagoDia, setPagoDia] = useState(null);
  const [desativarCampos, setDesativarCampos] = useState(false);
  const [modalCancelar, setModalCancelar] = useState(false);
  const [desabilitar, setDesabilitar] = useState(false);
  const [modalPagamento, setModalPagamento] = useState(false);
  const [tipoPagamento, setTipoPagamento] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const validacao = () => {
    if (idCliente && valor && emissao && vencimento) {
      return true;
    }
    return false;
  };

  const abrirConta = conta => {
    if (conta.status !== '0') {
      setDesativarCampos(true);
    } else {
      setDesativarCampos(false);
    }
    setIdCliente(conta.idCliente);
    setCentroCusto(conta.centroCusto);
    setValor(conta.valor);
    setNomeCli(conta.nomeCli);
    setCpfCli(conta.cpfCli);
    setEmissao(conta.emissao);
    setVencimento(conta.vencimento);
    setPagoDia(conta.pagoDia);
    setIdTratamento(conta.idTratamento);
    setFormaPag(conta.formaPag);
    setObs(conta.observacao);
    setResponsavel(conta.responsavel);
    setRespCpf(conta.respCpf);
    setRespRg(conta.respRg);
    setDataNasc(conta.dataNasc);
  };

  const cadastrarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finCad !== '0' && permissoes !== false) {
        if (validacao()) {
          await api
            .post('Receber/save.php', {
              pdo: getClinica(),
              idCliente,
              centroCusto,
              valor,
              formaPag,
              emissao,
              vencimento,
              status: 0,
              idTratamento: 0,
              nDoc: '',
              observacao: obs,
              parcelas,
              repetirValores: repetir,
              userCadastro: getToken(),
            })
            .then(res => {
              setContas([...contas, ...res.data]);
              abrirAviso('success', 'Conta cadastrada');
              fecharModal();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const alterarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finAlt !== '0' && permissoes !== false) {
        if (validacao()) {
          await api
            .post('Receber/update.php', {
              pdo: getClinica(),
              idCliente: idCliente,
              centroCusto: centroCusto,
              valor: valor,
              formaPag: formaPag,
              emissao: emissao,
              vencimento: vencimento,
              observacao: obs,
              userUltAlteracao: getToken(),
              id: conta.id,
            })
            .then(res => {
              setContas(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('info', 'Conta alterada');
              fecharModal();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const salvar = () => {
    conta ? alterarConta() : cadastrarConta();
  };

  const emitirRecibo = () => {
    const valorExtenso = numero.porExtenso(valor, numero.estilo.monetario);
    const idTrat = idTratamento.split(' ');
    let tratamento = '';
    if (idTrat[0] === 'Odont.') {
      tratamento = 'ao tratamento odontológico ' + idTrat[1] + ' ' + idTrat[2];
    } else if (idTrat[0] === 'Est.') {
      tratamento = 'ao tratamento estético ' + idTrat[1] + ' ' + idTrat[2];
    } else if (idTrat[0] === '0') {
      tratamento = 'a ' + obs;
    } else {
      tratamento = 'ao tratamento Nº ' + idTrat;
    }
    let codRecibo = JSON.stringify({
      nome: nomeCli,
      cpf: cpfCli,
      responsavel: responsavel,
      respCpf: respCpf,
      respRg: respRg,
      dataNasc: dataNasc,
      tratamento: tratamento,
      valor: formatReal(valor),
      valorExtenso: valorExtenso,
      pagoDia: formatData(pagoDia),
      cnpj: empresa.cnpj,
      razao: empresa.razaoSocial,
    });
    window.open(
      '/imprimir_recibo/' + btoa(codRecibo),
      'Recibo',
      'height=700,width=900',
    );
  };

  useEffect(() => {
    if (conta) {
      abrirConta(conta);
    }
  }, [modal]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={fecharModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {conta ? 'Conta' : 'Cadastrar Conta'}
        </DialogTitle>
        <DialogContent>
          {conta && (
            <DialogContentText id="alert-dialog-slide-description">
              Conta Nº {conta.id}
            </DialogContentText>
          )}
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  disabled={desativarCampos}
                  label="Cliente"
                  value={idCliente}
                  onChange={e => setIdCliente(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {clientes.map(cli => (
                    <MenuItem key={cli.id} value={cli.id}>
                      {cli.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  disabled={desativarCampos}
                  label="Centro de Custo"
                  value={centroCusto}
                  onChange={e => setCentroCusto(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {centrosCusto.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.descricao}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  type="date"
                  label="Emissão"
                  value={emissao}
                  onChange={e => setEmissao(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  type="date"
                  label="Vencimento"
                  value={vencimento}
                  onChange={e => setVencimento(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={conta ? 12 : 6}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  type="number"
                  label="Valor"
                  value={valor}
                  onChange={e => setValor(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              {!conta && (
                <>
                  <Grid item xs={3}>
                    <TextField
                      type="number"
                      label="Parcelas"
                      fullWidth
                      value={parcelas}
                      onChange={e => {
                        if (+e.target.value > 0) setParcelas(e.target.value);
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      select
                      disabled={desativarCampos}
                      label="Repetir Valores"
                      value={repetir}
                      onChange={e => setRepetir(e.target.value)}
                      variant="outlined"
                    >
                      <MenuItem value={0} selected>
                        Não
                      </MenuItem>
                      <MenuItem value={1}>Sim</MenuItem>
                    </TextField>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  disabled={desativarCampos}
                  label="Forma de Pagamento"
                  value={formaPag}
                  onChange={e => setFormaPag(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {listaFormasPagamento.map(pg => (
                    <MenuItem value={pg.value}>{pg.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  maxRows={5}
                  disabled={desativarCampos}
                  label="Observação"
                  value={obs}
                  onChange={e => setObs(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              {conta && conta.status === '1' && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={desativarCampos}
                    type="date"
                    label="Data Pagamento"
                    value={pagoDia}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <div style={{ width: '100%' }}>
            <Box
              display="flex"
              justifyContent="flex-start"
              m={1}
              bgcolor="background.paper"
            >
              <Box>
                {conta && conta.status === '0' && (
                  <Button
                    onClick={() => setModalCancelar(true)}
                    className={classes.buttonCancel}
                    variant="contained"
                    title="Cancelar conta"
                    color="secondary"
                  >
                    <DeleteIcon />
                  </Button>
                )}
                {conta && conta.status === '1' && (
                  <Button
                    onClick={() => setModalCancelar(true)}
                    variant="contained"
                    title="Estornar conta"
                    color="default"
                  >
                    <ArrowUpwardIcon />
                    Estornar
                  </Button>
                )}
                {conta && conta.status !== '0' && conta.status !== '-1' && (
                  <Button
                    onClick={() => emitirRecibo()}
                    variant="contained"
                    className={classes.buttonPagar}
                    title="Estornar conta"
                    color="primary"
                  >
                    <ReceiptIcon />
                    Recibo
                  </Button>
                )}
              </Box>
              {conta && conta.status === '0' && (
                <Box>
                  <Button
                    onClick={() => {
                      setTipoPagamento(0);
                      setModalPagamento(true);
                    }}
                    className={classes.buttonPagar}
                    variant="contained"
                  >
                    <AttachMoneyIcon /> Pagar
                  </Button>
                  <Button
                    onClick={() => {
                      setTipoPagamento(1);
                      setModalPagamento(true);
                    }}
                    className={classes.buttonPagar}
                    variant="contained"
                  >
                    <AttachMoneyIcon /> Pg. Parcial
                  </Button>
                </Box>
              )}
            </Box>
          </div>
          <Button
            onClick={salvar}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button variant="contained" color="default" onClick={fecharModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* pagamento total ou parcial */}
      {modalPagamento && (
        <Pagamento
          tipoPagamento={tipoPagamento}
          modal={modalPagamento}
          fecharModal={() => setModalPagamento(false)}
          fecharModalCadastrarEditar={fecharModal}
          conta={conta}
          setContas={setContas}
          abrirAviso={abrirAviso}
          configuracoes={configuracoes}
          permissoes={permissoes}
        />
      )}

      {/* confirmação de cancelamento/estorno de conta */}
      {modalCancelar && (
        <CancelarConta
          modal={modalCancelar}
          fecharModal={() => setModalCancelar(false)}
          fecharModalConta={fecharModal}
          conta={conta}
          setContas={setContas}
          configuracoes={configuracoes}
          permissoes={permissoes}
          abrirAviso={abrirAviso}
        />
      )}
    </>
  );
}
