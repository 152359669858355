import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { formatReal } from '../../../utils/funcoes';

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'dente',
    headerName: 'Dente',
    width: 150,
  },
  {
    field: 'face',
    headerName: 'Face',
    width: 120,
  },
  {
    field: 'estadoAtual',
    headerName: 'Estado Atual',
    width: 200,
  },
  {
    field: 'procedimento',
    headerName: 'Procedimento',
    width: 280,
  },
  {
    field: 'valor',
    headerName: 'Valor',
    width: 120,
  },
  {
    field: 'statusTratamento',
    headerName: 'Obs Tratamento',
    width: 380,
  }
];

export default function ReutilizarOrcamento({
  modal,
  fecharModal,
  orcamento,
  abrirAviso,
}) {
  const [desabilitar, setDesabilitar] = useState(false);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planoTratamento, setPlanoTratamento] = useState([]);

  const listarProcedimentos = async () => {
    await api
      .post(
        'PlanoTratamento/list.php?id=' + orcamento.id + '&pdo=' + getClinica(),
      )
      .then(res => {
        let linhas = [];
        res.data.map(procedimento => {
          linhas.push({
            id: procedimento.id,
            dente: procedimento.numDente,
            face: procedimento.face,
            estadoAtual: procedimento.estadoAtual,
            procedimento: procedimento.descricaoProcedimento,
            valor: formatReal(procedimento.valor),
            statusTratamento: procedimento.statusTratamento
          });
        });
        setPlanoTratamento(res.data);
        setRows(linhas);
      });
  };

  const confirmar = async () => {
    setDesabilitar(true);
    if (selected.length > 0) {
      let planos = [];
      selected.map(s => {
        planoTratamento.map(plano => {
          if (s === plano.id) {
            planos.push({
              idProcedimento: plano.procedimento,
              id: plano.idDente,
              dente: plano.numDente,
              face: plano.face,
              tipo: plano.tipo,
              estadoAtual: plano.estadoAtual,
              valor: plano.valor,
            });
          }
        });
      });

      await api
        .post('Tratamento/save.php', {
          pdo: getClinica(),
          idDentista: orcamento.idDentista,
          idCliente: orcamento.idCliente,
          planosTratamento: planos,
          ratear: 0,
          refTratamento: orcamento.id,
        })
        .then(res => {
          abrirAviso('success', `Orçamento Nº${res.data} cadastrado.`);
          fecharModal();
          setTimeout(function () {
            window.location.reload();
          }, 1400);
        });
    } else {
      abrirAviso('warning', `Selecione os procedimentos.`);
    }
    setDesabilitar(false);
  };

  useEffect(() => {
    listarProcedimentos();
  }, [modal]);

  return (
    <>
      <Dialog
        fullScreen
        maxWidth={'md'}
        open={modal}
        onClose={fecharModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Reutilizar procedimentos do orçamento Nº ${orcamento.id}`}</DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              autoHeight
              onSelectionModelChange={selected => setSelected(selected)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirmar}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Confirmar
          </Button>
          <Button variant="contained" color="default" onClick={fecharModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
