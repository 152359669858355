import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';
import { useConfig } from '../../hooks/config';
import { formatReal } from '../../utils/funcoes';
import Menu from '../Menu';
import { makeStyles } from '@material-ui/core/styles';
import '../Orcamentos/Odontologico/style.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import HistoryIcon from '@material-ui/icons/History';
import HistoricoEstetico from './historico';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CachedIcon from '@material-ui/icons/Cached';
import Zoom from '@material-ui/core/Zoom';
import Notificacao from '../../utils/notificacao';
import InserirObservacaoEstetico from './inserirObservacao';
import CancelarTratamento from '../../utils/cancelarTratamento';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 500,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  subtotal: {
    margin: '5px 0px',
  },
  desconto: {
    margin: '5px 0px',
  },
  total: {
    margin: '5px 0px',
  },
  aguardando: {
    color: '#FFC107',
  },
  finalizado: {
    color: '#4caf50',
  },
  buttonStatus: {
    margin: theme.spacing(1),
  },
  inputObs: {
    width: '400px',
  },
  imagem: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function TratamentoEstetico(props) {
  const { trat } = useParams();
  const { configuracoes } = useConfig();
  const Tratamento = JSON.parse(atob(trat));
  const [loading, setLoading] = useState(true);
  const [modalObs, setModalObs] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [procedimentos, setProcedimentos] = useState([]);
  const [total, setTotal] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [modalHistorico, setModalHistorico] = useState(false);
  const [status, setStatus] = useState(null);
  const [idPlanoTratamento, setIdPlanoTratamento] = useState(null);
  const [imagem, setImagem] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .get('Estetico/find.php?id=' + Tratamento.id + '&pdo=' + getClinica())
      .then(res => setImagem(res.data.img));
    await api
      .post(
        'PlanoEstetico/list.php?id=' + Tratamento.id + '&pdo=' + getClinica(),
      )
      .then(res => {
        setProcedimentos(res.data);
        let total = res.data.reduce(
          (acc, proc) => acc + parseFloat(proc.valor),
          0,
        );
        let desconto = res.data.reduce(
          (desc, proc) => desc + parseFloat(proc.desconto),
          0,
        );
        setTotal(total);
        setDesconto(desconto);
        setLoading(false);
      });
    atualizarProgress();
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const alterarProgresso = (id, status) => {
    setIdPlanoTratamento(id);
    setStatus(status);
    setModalObs(true);
  };

  const atualizarProgress = () => {
    api.post('Estetico/updateProgress.php', {
      pdo: getClinica(),
      id: Tratamento.id,
    });
  };

  const statusProcedimento = e => {
    if (e === '0') {
      return (
        <Tooltip title="Aguardando" TransitionComponent={Zoom}>
          <PlayCircleFilledIcon className={classes.aguardando} />
        </Tooltip>
      );
    } else if (e === '1') {
      return (
        <Tooltip title="Em andamento" TransitionComponent={Zoom}>
          <CachedIcon color="primary" />
        </Tooltip>
      );
    } else if (e === '2') {
      return (
        <Tooltip title="Finalizado" TransitionComponent={Zoom}>
          <CheckCircleIcon className={classes.finalizado} />
        </Tooltip>
      );
    }
  };

  return (
    <>
      <Menu
        titulo={`Pacientes ► ${Tratamento.Cliente} ► Tratamentos Estéticos ► Nº ${Tratamento.id}`}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.history.back()}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setModalHistorico(true)}
          startIcon={<HistoryIcon />}
        >
          Histórico
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => setModalCancel(true)}
          startIcon={<CancelIcon />}
        >
          Cancelar
        </Button>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Grid container spacing={1}>
          <Grid item xs={12} align="center">
            <img
              id="desenho"
              className={classes.imagem}
              width={282}
              height={390}
              src={imagem}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridTable}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <b>Área Tratada</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Produto</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Quantidade</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Procedimento</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Valor</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Status</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Opções</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {procedimentos.map((pl, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{pl.areaTratada}</TableCell>
                      <TableCell align="center">{pl.nomeProduto}</TableCell>
                      <TableCell align="center">{pl.quantidade}</TableCell>
                      <TableCell align="center">{pl.procedimento}</TableCell>
                      <TableCell align="center">
                        {formatReal(pl.valor)}
                      </TableCell>
                      <TableCell align="center">
                        {statusProcedimento(pl.status)}
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          <Tooltip
                            title="Inicializar pricedimento"
                            TransitionComponent={Zoom}
                          >
                            <Button
                              variant="contained"
                              className={classes.buttonStatus}
                              onClick={() => alterarProgresso(pl.id, 1)}
                              color="primary"
                              size="small"
                            >
                              <CachedIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Finalizar procedimento"
                            TransitionComponent={Zoom}
                          >
                            <Button
                              variant="contained"
                              className={classes.buttonStatus}
                              onClick={() => alterarProgresso(pl.id, 2)}
                              color="primary"
                              size="small"
                            >
                              <CheckCircleIcon />
                            </Button>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <b className={classes.total}>SubTotal</b>
                    </TableCell>
                    <TableCell>
                      <b className={classes.total}>{formatReal(total)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <b className={classes.total}>Desconto</b>
                    </TableCell>
                    <TableCell>
                      <b className={classes.total}>{formatReal(desconto)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <b className={classes.total}>Total</b>
                    </TableCell>
                    <TableCell>
                      <b className={classes.total}>
                        {formatReal(total - desconto)}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {modalHistorico && (
        <HistoricoEstetico
          Tratamento={Tratamento}
          setModal={setModalHistorico}
          modal={modalHistorico}
          procedimentos={procedimentos}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />

      {modalObs && (
        <InserirObservacaoEstetico
          tipo={'Estetico'}
          modalObs={modalObs}
          setModalObs={setModalObs}
          configuracoes={configuracoes}
          getClinica={getClinica}
          Tratamento={Tratamento}
          idPlanoTratamento={idPlanoTratamento}
          status={status}
          classes={classes}
          atualizar={atualizar}
          abrirAviso={abrirAviso}
        />
      )}

      {modalCancel && (
        <CancelarTratamento
          tipo={'Estetico'}
          modalCancel={modalCancel}
          setModalCancel={setModalCancel}
          configuracoes={configuracoes}
          getClinica={getClinica}
          Tratamento={Tratamento}
          abrirAviso={abrirAviso}
        />
      )}
    </>
  );
}
