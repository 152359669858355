export const formatData = e => {
  if (e) {
    let data = e.split('-');
    return data[2] + '/' + data[1] + '/' + data[0];
  } else {
    return '';
  }
};

export const getData = (date, valor) => {
  let data = date.split(' ');
  if (valor === 'data') {
    return formatData(data[0]);
  }
  return data[1];
};

export const formatReal = valor => {
  const v = parseFloat(valor);
  return v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const formaPagamento = e => {
  if (e === 'dinh') {
    return 'DINHEIRO';
  }
  if (e === 'cheq') {
    return 'CHEQUE';
  }
  if (e === 'cartC') {
    return 'CARTÃO DE CRÉDITO';
  }
  if (e === 'cartD') {
    return 'CARTÃO DE DÉBITO';
  }
  if (e === 'bole') {
    return 'BOLETO';
  }
  if (e === 'conv') {
    return 'CONVÊNIO';
  }
  if (e === 'transf') {
    return 'TRANSFERÊNCIA';
  }
  if (e === 'crta') {
    return 'CARTEIRA';
  }
  if (e === 'pix') {
    return 'PIX';
  }
  if (e === 'visa') {
    return 'VISA';
  }
  if (e === 'elo') {
    return 'ELO';
  }
  if (e === 'master') {
    return 'MASTERCARD';
  } else {
    return '';
  }
};

export const listaFormasPagamento = [
  { value: 'dinh', label: 'DINHEIRO' },
  { value: 'cheq', label: 'CHEQUE' },
  { value: 'cartC', label: 'CARTÃO DE CRÉDITO' },
  { value: 'cartD', label: 'CARTÃO DE DÉBITO' },
  { value: 'bole', label: 'BOLETO' },
  { value: 'conv', label: 'CONVÊNIO' },
  { value: 'transf', label: 'TRANSFERÊNCIA' },
  { value: 'crta', label: 'CARTEIRA' },
  { value: 'pix', label: 'PIX' },
  { value: 'visa', label: 'VISA' },
  { value: 'master', label: 'MASTERCARD' },
  { value: 'elo', label: 'ELO' },
];

export const statusOrdemServico = [
  { value: 1, label: 'A ENVIAR PARA LABORATÓRIO' },
  { value: 2, label: 'ENVIADO PARA LABORATÓRIO' },
  { value: 3, label: 'RETORNO DO LABORATÓRIO PARA PROVA' },
  { value: 4, label: 'RETORNO AO LABORATÓRIO PARA AJUSTES' },
  { value: 5, label: 'CONCLUÍDA' },
];

export const pegarDataCidade = empresa => {
  let data = new Date();
  let dia = data.getDate();
  let mes = data.getMonth();
  let ano = data.getFullYear();
  let meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  return (
    empresa.cidade +
    '/' +
    empresa.estado +
    ', ' +
    dia +
    ' de ' +
    meses[mes] +
    ' de ' +
    ano
  );
};

export const calcularIdade = data => {
  let nascimento = new Date(data);
  let ano = new Date();
  let idade = ano.getFullYear() - nascimento.getFullYear();
  return idade;
};

export const removeAcento = text => {
  return text
    .replace(/[ÀÁÂÃÄÅ]/g, 'A')
    .replace(/[aáàãâä]/g, 'a')
    .replace(/[EÉÈÊË]/g, 'E')
    .replace(/[eéèêë]/g, 'e')
    .replace(/[IÍÌÎÏ]/g, 'I')
    .replace(/[iíìîï]/g, 'i')
    .replace(/[OÓÒÕÔÖ]/g, 'O')
    .replace(/[oóòõôö]/g, 'o')
    .replace(/[UÚÙÛÜ]/g, 'U')
    .replace(/[uúùûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/[Ç]/g, 'C');
};
