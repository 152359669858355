import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';
import { getClinica, getToken } from '../services/auth';
const PermissoesContext = createContext({});

export const PermissoesProvider = ({ children }) =>{
    const [permissoes, setPermissoes] = useState({});

    useEffect(()=>{
        atualizar();
    },[]);
    
    const atualizar = () => {
        api.get('Permissoes/find.php',{params:{ id:getToken(), pdo:getClinica()}})
        .then(res =>{
            setPermissoes(res.data);
        }).catch(error => {console.log(error)}) 
    }
    return(
        <PermissoesContext.Provider value={{permissoes}}>
            {children}
        </PermissoesContext.Provider>
    );
};

export function usePermissoes(){
    const context = useContext(PermissoesContext);
    if(!context){
        throw new Error('Deve ser utilizado em um PermissoesContext');
    }
    return context;
}

