import React, { useState } from 'react';
import api from '../services/api';
import { usePermissoes } from '../hooks/permissoes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function CancelarTratamento({
  tipo,
  modalCancel,
  setModalCancel,
  configuracoes,
  getClinica,
  Tratamento,
  abrirAviso,
}) {
  const [desabilitar, setDesabilitar] = useState(false);
  const { permissoes } = usePermissoes();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const cancelar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.orcCad !== '0' && permissoes !== false) {
        await api
          .post(`${tipo}/updateStatus.php`, {
            pdo: getClinica(),
            status: 3,
            id: Tratamento.id,
          })
          .then(() => {
            abrirAviso('warning', 'Tratamento cancelado.');
            setModalCancel(false);
            window.history.back();
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modalCancel}
      onClose={() => setModalCancel(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Cancelar Tratamento'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>
            Tem certeza que deseja cancelar o tratamento Nº {Tratamento.id}?
          </b>
          <p>
            {' '}
            <i>
              OBS: Ao cancelar o tratamento, a conta no financeiro permanecerá
              ativa. Caso deseje cancelar, deverá ser realizado pelo{' '}
              <a href="/contasreceber">FINANCEIRO</a>.
            </i>
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={cancelar}
          disabled={desabilitar}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={() => setModalCancel(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
