import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  TextField,
  useMediaQuery,
  useTheme,
  Avatar,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Notificacao from '../../utils/notificacao';
import CadastrarEditarCentroCusto from './cadastrarEditar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
}));

export default function CentroCusto() {
  const [centroCustos, setCentroCustos] = useState([]);
  const [centro, setCentro] = useState(null);
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [buscar, setBuscar] = useState('');
  const [modal, setModal] = useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const atualizar = () => {
    consultar('');
    setLoading(false);
  };

  const consultar = async consulta => {
    await api
      .post('CentroCusto/search.php', {
        pdo: getClinica(),
        consulta: consulta,
      })
      .then(res => {
        res.data ? setCentroCustos(res.data) : setCentroCustos([]);
      })
      .catch(error => console.log(error));
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filtro = centroCustos.filter(c => {
    return (
      c.descricao
        .toLowerCase()
        .toUpperCase()
        .indexOf(buscar.toLowerCase().toUpperCase()) !== -1 ||
      c.id
        .toLowerCase()
        .toUpperCase()
        .indexOf(buscar.toLowerCase().toUpperCase()) !== -1
    );
  });

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <>
      <Menu titulo="Centro de Custo" />
      <Box
        display="flex"
        className={classes.opcoes}
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setCentro(null);
              setModal(true);
            }}
            startIcon={<AddCircleIcon />}
          >
            Novo
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
          >
            Atualizar
          </Button>
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Descrição</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opção</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(centro => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={centro.id}
                      >
                        <TableCell>{centro.id}</TableCell>
                        <TableCell>{centro.descricao}</TableCell>
                        <TableCell>
                          {centro.ativo === '1' ? (
                            <Avatar className={classes.ativo} title="Ativo">
                              <CheckCircleIcon />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.inativo} title="Inativo">
                              <CancelIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            startIcon={<OpenInNewIcon />}
                            onClick={() => {
                              setCentro(centro);
                              setModal(true);
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Abrir
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={centroCustos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
      {modal && (
        <CadastrarEditarCentroCusto
          fullScreen={fullScreen}
          modal={modal}
          setModal={setModal}
          centro={centro}
          centroCustos={centroCustos}
          setCentroCustos={setCentroCustos}
          abrirAviso={abrirAviso}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
