import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { getClinica, getToken } from '../../../services/auth';
import api from '../../../services/api';
import { listaFormasPagamento } from '../../../utils/funcoes';

export default function Pagamento({
  tipoPagamento,
  modal,
  fecharModal,
  fecharModalCadastrarEditar,
  conta,
  setContas,
  abrirAviso,
  configuracoes,
  permissoes,
}) {
  const [valor, setValor] = useState(null);
  const [formaPag, setFormaPag] = useState(null);
  const [pagoDia, setPagoDia] = useState(null);
  const [desabilitar, setDesabilitar] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const pagarConta = async e => {
    setDesabilitar(true);
    e.preventDefault();
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finAlt !== '0' && permissoes !== false) {
        if (tipoPagamento === 0) {
          if (pagoDia && formaPag) {
            await api
              .post('Receber/pay.php', {
                pdo: getClinica(),
                status: 1,
                dataPagamento: pagoDia,
                formaPagamento: formaPag,
                idUserBaixa: getToken(),
                id: conta.id,
              })
              .then(res => {
                abrirAviso('success', 'Conta paga');
                setContas(state =>
                  state.map(e => {
                    if (e.id !== res.data.id) {
                      return e;
                    }
                    e = res.data;
                    return e;
                  }),
                );
                fecharModal();
                fecharModalCadastrarEditar();
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            abrirAviso('warning', 'Preencha os campos obrigatórios.');
          }
        } else {
          if (pagoDia && formaPag && valor) {
            if (valor <= parseFloat(conta.valor)) {
              await api
                .post('Receber/payPartial.php', {
                  pdo: getClinica(),
                  status: 1,
                  dataPagamento: pagoDia,
                  formaPagamento: formaPag,
                  valorRecebido: valor,
                  idUserBaixa: getToken(),
                  id: conta.id,
                })
                .then(res => {
                  abrirAviso('success', 'Conta paga parcialmente');
                  console.log(res.data);
                  setContas(state =>
                    state.map(e => {
                      if (e.id !== res.data.id) {
                        return e;
                      }
                      e = res.data;
                      return e;
                    }),
                  );
                  fecharModal();
                  fecharModalCadastrarEditar();
                })
                .catch(error => {
                  console.log(error);
                });
            } else {
              abrirAviso(
                'warning',
                'Valor não pode ser maior que o cadastrado.',
              );
            }
          } else {
            abrirAviso('warning', 'Preencha os campos obrigatórios.');
          }
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  useEffect(() => {
    if (conta) {
      setFormaPag(conta.formaPag);
    }
  }, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal}
      onClose={fecharModal}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {tipoPagamento === 1 ? 'Pagamento parcial' : 'Pagamento'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {tipoPagamento === 1
            ? 'Tem certeza que deseja pagar parcialmente a conta Nº'
            : 'Tem certeza que deseja pagar conta Nº'}{' '}
          {conta.id} ?
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="date"
              label="Data do pagamento"
              value={pagoDia}
              onChange={e => setPagoDia(e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          {tipoPagamento === 1 && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Valor Recebido"
                value={valor}
                onChange={e => setValor(e.target.value)}
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label="Forma de Pagamento"
              value={formaPag}
              onChange={e => setFormaPag(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="" selected>
                Selecione...
              </MenuItem>
              {listaFormasPagamento.map(pg => (
                <MenuItem value={pg.value}>{pg.label}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={pagarConta}
          disabled={desabilitar}
          variant="contained"
          color="primary"
        >
          Sim
        </Button>
        <Button
          variant="contained"
          disabled={desabilitar}
          color="secondary"
          onClick={fecharModal}
        >
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
}
