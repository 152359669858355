import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { formaPagamento, formatData, formatReal } from '../../../utils/funcoes';
import Menu from '../../Menu';
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  MenuItem,
  TextField,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import { usePermissoes } from '../../../hooks/permissoes';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  saldo: {
    margin: '10px 0px',
  },
  titulo: {
    textAlign: 'center',
  },
  botaoImprimir: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#3b8e3e',
    },
    color: '#FFFF',
  },
}));

export default function Movimentacao() {
  const { permissoes } = usePermissoes();
  const [loading, setLoading] = useState(true);
  const [movimentacao, setMovimentacao] = useState([]);
  const [receber, setReceber] = useState(0);
  const [pagar, setPagar] = useState(0);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState(null);
  const [convenios, setConvenios] = useState([]);
  const [convenio, setConvenio] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('mov')) {
      sessionStorage.removeItem('mov');
    }
  }, [movimentacao]);

  const naoDesabilitarTotais = () => {
    return permissoes.finDesTotais && !+permissoes.finDesTotais
  }

  const atualizar = async () => {
    let date = new Date();
    let primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let hoje = `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()}`;
    let dateI =
      date.getFullYear() +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + primeiroDia.getDate()).slice(-2);
    let dateF =
      date.getFullYear() +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + ultimoDia.getDate()).slice(-2);

    await api
      .post('CentroCusto/search.php', {
        pdo: getClinica(),
        consulta: 'AND ativo=1',
      })
      .then(res => {
        res.data ? setCentrosCusto(res.data) : setCentrosCusto([]);
      }).catch(error => console.log(error));
      
    await api
      .get('Convenio/list.php?pdo=' + getClinica())
      .then(res => {
        setConvenios(res.data);
      })
      .catch(error => console.log(error));
    listarContas(` AND status=1 AND pagoDia BETWEEN '${naoDesabilitarTotais() ? dateI : hoje}' 
    AND '${naoDesabilitarTotais() ? dateF : hoje}'`);
    setDataInicio(naoDesabilitarTotais() ? dateI: hoje);
    setDataFim(naoDesabilitarTotais() ? dateF: hoje);
  };

  const listarContas = async consulta => {
    const mov = [];
    await api
      .post('Pagar/search.php', { pdo: getClinica(), consulta })
      .then(res => {
        let valorPagar = 0;
        for (let i = 0; i < res.data.length; i++) {
          mov.push({
            data: res.data[i].pagoDia,
            tipo: 'SAIDA',
            nome: res.data[i].descricao,
            funcionario: '',
            valor: res.data[i].valor,
            formaPag: res.data[i].formaPag,
            convenio: null
          });
          valorPagar += parseFloat(res.data[i].valor);
        }
        setPagar(valorPagar);
      })
      .catch(error => {
        console.log(error);
      });

    if (convenio) {
      consulta += ` AND u.convenio=${convenio} AND formaPag = 'conv'`;
    }
    await api
      .post('Receber/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        let valorReceber = 0;
        for (let i = 0; i < res.data.length; i++) {
          mov.push({
            data: res.data[i].pagoDia,
            tipo: 'ENTRADA',
            nome: res.data[i].nomeCli,
            funcionario: res.data[i].funcionario,
            valor: res.data[i].valor,
            formaPag: res.data[i].formaPag,
            convenio: res.data[i].Convenio
          });
          valorReceber += parseFloat(res.data[i].valor);
        }
        setReceber(valorReceber);
        mov.sort((a, b) => {
          return Date.parse(a.data) - Date.parse(b.data);
        });
        setMovimentacao(mov);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const tipoContaIcone = tipo => {
    return tipo === 'SAIDA' ? (
      <ArrowUpwardIcon color="error" />
    ) : (
      <ArrowDownwardIcon color="primary" />
    );
  };

  const filtrar = async () => {
    setLoading(true);
    let consulta = '';
    if (dataInicio !== '' && dataFim !== '') {
      setMovimentacao([]);
      
      consulta += ` AND status=1 AND pagoDia BETWEEN '${dataInicio}' AND '${dataFim}'`;
      if (centroCusto) {
        consulta += ` AND centroCusto=${centroCusto}`;
      }
      listarContas(consulta);
    } else {
      await atualizar();
    }
    setLoading(false);
  };

  const imprimir = () => {
    const mov = {
      movimentacao: JSON.stringify(movimentacao),
      total: receber - pagar,
      recebido: receber,
      pago: pagar,
    };
    sessionStorage.setItem('mov', btoa(JSON.stringify(mov)));
    window.open(
      '/imprimir_movimentacao',
      'Movimentação',
      'height=700,width=900',
    );
    sessionStorage.removeItem('mov');
  };

  return (
    <div>
      <div id="opcoesMenu">
        <Menu titulo="Relatórios ► Movimentação Financeira" />
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div>
          <div id="opcoesFiltro">
            <Grid container spacing={1} style={{ marginTop: '80px' }}>
              <Grid item xs={2}>
                <TextField
                  label="Data inicio"
                  fullWidth
                  size="small"
                  value={dataInicio}
                  type="date"
                  onChange={e => setDataInicio(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled={!naoDesabilitarTotais()}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Data fim"
                  fullWidth
                  size="small"
                  value={dataFim}
                  type="date"
                  onChange={e => setDataFim(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled={!naoDesabilitarTotais()}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label="Centro de Custo"
                  value={centroCusto}
                  onChange={e => setCentroCusto(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {centrosCusto.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.descricao}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label="Convênios"
                  value={convenio}
                  onChange={e => setConvenio(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {convenios.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" color="primary" onClick={filtrar}>
                  <SearchIcon />
                </Button>
                <Button
                  variant="contained"
                  className={classes.botaoImprimir}
                  color="primary"
                  onClick={imprimir}
                >
                  <PrintIcon />
                </Button>
              </Grid>
            </Grid>
          </div>
          <h3 className={classes.titulo}>Movimentação Financeira</h3>
          <h5 className={classes.saldo}>Recebido: {formatReal(receber)}</h5>
          <h5 className={classes.saldo}>Pago: {formatReal(pagar)}</h5>
          <h4 className={classes.saldo}>
            Saldo: <b>{formatReal(receber - pagar)}</b>
          </h4>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="movimentacao"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Data</TableCell>
                  <TableCell className={classes.tableCell}>Tipo</TableCell>
                  <TableCell className={classes.tableCell}>
                    Lançamento
                  </TableCell>
                  <TableCell className={classes.tableCell}>Usuário</TableCell>
                  <TableCell className={classes.tableCell}>Valor</TableCell>
                  <TableCell className={classes.tableCell}>Forma Pagamento</TableCell>
                  <TableCell className={classes.tableCell}>Convênio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movimentacao.map((lancamento, index) => (
                  <TableRow key={index}>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {formatData(lancamento.data)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tipoContaIcone(lancamento.tipo)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {lancamento.nome}
                    </TableCell>
                    <TableCell>{lancamento.funcionario?.split(' ')[0]}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {formatReal(lancamento.valor)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {formaPagamento(lancamento.formaPag)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {lancamento.convenio}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
