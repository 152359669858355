import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatData } from '../../utils/funcoes';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
}));

export default function HistoricoOdontologico({
  Tratamento,
  setModal,
  modal,
  procedimentos,
}) {
  const [historico, setHistorico] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    atualizar();
  }, [modal]);

  const atualizar = () => {
    api
      .get(
        'PlanoTratamento/findLog.php?idTratamento=' +
          Tratamento.id +
          '&pdo=' +
          getClinica(),
      )
      .then(res => {
        setHistorico(res.data);
      })
      .catch(error => console.log(error));
  };

  const statusProcedimento = e => {
    if (e === '0') {
      return 'Aguardando';
    } else if (e === '1') {
      return 'Em andamento';
    } else if (e === '2') {
      return 'Finalizado';
    }
  };

  const getProcedimento = e => {
    for (let i = 0; i < procedimentos.length; i++) {
      if (procedimentos[i].id === e) {
        return (
          'Dente Nº: ' +
          procedimentos[i].numDente +
          ' - Face: ' +
          procedimentos[i].face +
          ' - Procedimento: ' +
          procedimentos[i].descricaoProcedimento
        );
      }
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Histórico de tratamento'}
        </DialogTitle>
        <DialogContent>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Data</b>
                  </TableCell>
                  <TableCell>
                    <b>Hora</b>
                  </TableCell>
                  <TableCell>
                    <b>Tratamento</b>
                  </TableCell>
                  <TableCell>
                    <b>Procedimento</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Usuário</b>
                  </TableCell>
                  <TableCell>
                    <b>Observação</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historico.map(log => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={log.id}>
                      <TableCell>{formatData(log.data)}</TableCell>
                      <TableCell>{log.hora}</TableCell>
                      <TableCell>{log.idTratamento}</TableCell>
                      <TableCell>
                        {getProcedimento(log.idProcedimento)}
                      </TableCell>
                      <TableCell>
                        Alterado para <b>{statusProcedimento(log.status)}</b>
                      </TableCell>
                      <TableCell>{log.usuario}</TableCell>
                      <TableCell>{log.observacao}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModal(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
