import React, { useState } from 'react';
import api from '../../services/api';
import { usePermissoes } from '../../hooks/permissoes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { getToken } from '../../services/auth';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function InserirObservacaoEstetico({
  modalObs,
  setModalObs,
  configuracoes,
  getClinica,
  Tratamento,
  idPlanoTratamento,
  status,
  classes,
  atualizar,
  abrirAviso,
}) {
  const { permissoes } = usePermissoes();
  const [observacao, setObservacao] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const confirmar = () => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.orcAlt !== '0' && permissoes !== false) {
        api
          .post('PlanoEstetico/update.php', {
            pdo: getClinica(),
            status: status,
            idEstetico: Tratamento.id,
            idPlanoEstetico: idPlanoTratamento,
            user: getToken(),
            observacao: observacao,
          })
          .then(() => {
            atualizar();
            abrirAviso('success', 'Atualizado');
            setModalObs(false);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modalObs}
      onClose={() => setModalObs(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Inserir Observação'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Observação"
              className={classes.inputObs}
              size="large"
              fullWidth
              value={observacao}
              onChange={e => setObservacao(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={confirmar}>
          Confirmar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setModalObs(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
